// import Handsontable from 'handsontable'
import Handsontable from 'handsontable-bv'
import vuexStore from './pgridVuexStore.js'
import PGridUtils from "./pgridUtils.js"
import PGridCell from "./pgridCell.js"
import PGridMatrices from './pgridMatrices.js'
import { translateFromKey } from './pgridLanguage.js'
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash'
import { toLabel, columnLabelToIndex, rowLabelToIndex } from '../formula-parser/helper/cell.js';

import { FillInRenderOptions } from './pgridLR_Common.js';


import Vue from "vue/dist/vue.js";

const DBL_CLICK_DEBUG_PANE_MS = 500;

// import ApexCharts from 'apexcharts'

function PGridCellRendererYesNo(hotEl, td, row, column, prop, htValue, cellProperties) {

    PGridCellRenderer.apply(this, arguments);

    if (!PGridUtils.IsColumnHidden(column)) {
        Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);
    }
    // Handsontable.dom.addEvent(container2, 'mousedown', function (event) {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       event.stopPropagation();
    //     }
    //   });

    cellProperties.strict = true;
    cellProperties.filter = false;

    try {

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `PGridCellRendererYesNo()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return;// td;
        }

        let pcell = PGridUtils.getPGridCell(pgridData, row, column);

        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    // f.class.split(",").forEach(cl => {
                    //     if (cl == "normaltext") {
                    //         isValStr = true;
                    //     }
                    //     if (td.className.indexOf(cl) == -1) {
                    //         td.classList.add(cl.trim());
                    //     }
                    // });

                    let fcs = f.class.split(",");
                    for (let i = 0; i < fcs.length; i++) {
                        let cl = fcs[i];
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    }
                }
            }


            /* This part modifies pgridDataDyn directly */
            //Hackish solution if RenderOptions is not set (requred to get yesno type working with EPivot)
            {
                let validType = null;
                let lang = PGridVueStore.state.pgridSettings.language;
                validType = lodash_get(pcell, "Meta.Validator.Type", null);
                if (validType == null) {
                    validType = lodash_get(pcell, "Meta.Save.Validator.Type", null); //This format is soon obsolete
                }
                if (validType) {
                    let updated = FillInRenderOptions(pcell, validType, lang);

                    if (updated) {
                        // This is not required as it is updated by reference --> pgridDataDyn[readmetaY][readmetaX] = fCell;
                    }
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }



    return;// td;
}



function PGridCellRendererLookup(hotEl, td, row, column, prop, htValue, cellProperties) {

    PGridCellRenderer.apply(this, arguments);
    if (!PGridUtils.IsColumnHidden(column)) {
        Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);
    }
    // Handsontable.dom.addEvent(container2, 'mousedown', function (event) {
    //     if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //       event.stopPropagation();
    //     }
    //   });

    cellProperties.strict = true;
    cellProperties.filter = false;

    cellProperties.visibleRows = 20;

    try {

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `PGridCellRendererYesNo()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return;// td;
        }



        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    // f.class.split(",").forEach(cl => {
                    //     if (cl == "normaltext") {
                    //         isValStr = true;
                    //     }
                    //     if (td.className.indexOf(cl) == -1) {
                    //         td.classList.add(cl.trim());
                    //     }
                    // });

                    let fcs = f.class.split(",");
                    for (let i = 0; i < fcs.length; i++) {
                        let cl = fcs[i];
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    }
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }



    return;// td;
}




function PGridCellRendererFilter(hotEl, td, row, column, prop, htValue, cellProperties) {


    if(row == 3 && column == 5){
        let foo="bar flakdsj flLFKJDSLKFJDSLKkjflkadsjflökasdj";
    }

    if (!PGridUtils.IsColumnHidden(column)) {
        PGridCellRendererLookup.apply(this, arguments);
    }

    if ('filter_Label' in cellProperties) {
        td.lastChild.data = cellProperties.filter_Label;


        if (!('colFilterRows' in window)) {
            window.colFilterRows = {};
        }

        if ("filter_UniqueValues_OnRows" in cellProperties) {

            let filtCell = `${row}:${column}`;

            cellProperties['filter_UniqueValues_selected'] = [htValue];

            if (htValue in cellProperties.filter_UniqueValues_OnRows) {
                window.colFilterRows[filtCell] = true;
            } else {
                delete window.colFilterRows[filtCell]
            }

        }
        else {
            console.error("Should have found htValue here");
        }

    }
    return;// td;
}




function PGridCellRendererJson(hotEl, td, row, column, prop, htValue, cellProperties) {

    try {

        // cellProperties.checkedTemplate = translateFromKey('cellTypeYesNo_Yes', vuexStore.state.pgridSettings.language); //"Ja"
        // cellProperties.uncheckedTemplate = translateFromKey('cellTypeYesNo_No', vuexStore.state.pgridSettings.language); //"Nej";

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `PGridCellRendererJson()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return td;
        }


        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    f.class.split(",").forEach(cl => {
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    });
                }
            }

        }

    } catch (err) {
        let errMsg = `> PGridCellRendererJson() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }

    if (!PGridUtils.IsColumnHidden(column)) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
    } return;// td;
}


function PGridCellRendererCheckboxRenderer(hotInstance, td, row, column, prop, htValue, cellProperties) {

    try {

        // cellProperties.checkedTemplate = translateFromKey('cellTypeYesNo_Yes', vuexStore.state.pgridSettings.language); //"Ja"
        // cellProperties.uncheckedTemplate = translateFromKey('cellTypeYesNo_No', vuexStore.state.pgridSettings.language); //"Nej";

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `PGridCellRendererCheckboxRenderer()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return td;
        }


        let pcell = PGridUtils.getPGridCell(pgridData, row, column);


        if (pcell != null) {

            if ("Error" in pcell) {

                if (td.className.indexOf("pg-cell-parse-error") == -1) {
                    td.classList.add("pg-cell-parse-error");
                }

                htValue = pcell.Error;
            }


            if ("Format" in pcell) {

                var f = PGridUtils.Parse_FormatField(pcell.Format)
                if (f.class != null) {
                    f.class.split(",").forEach(cl => {
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    });
                }
            }
        }

    } catch (err) {
        let errMsg = `> PGridCellRendererCheckboxRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }

    if (!PGridUtils.IsColumnHidden(column)) {
        Handsontable.renderers.CheckboxRenderer.apply(this, arguments); //FUB Should'nt this be in the head of the function?
    }
    return td;
}




class PGridCellEditorYesNo extends Handsontable.editors.AutocompleteEditor {

    prepare(row, col, prop, td, originalValue, cellProperties) {
        // Invoke the original method...
        // cellProperties.source = ["Ja", "Nej"]


        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });
        let pcell = pgridDataBuffer[row][col];

        let validType = lodash_get(pcell, "Meta.Validator.Type", null)

        // if (validType == 'truefalse') {
        //     cellProperties.source = ['cellTypeTrueFalse_True', 'cellTypeTrueFalse_False'].map(x => translateFromKey(x, vuexStore.state.pgridSettings.language));
        // } else {
        //     cellProperties.source = ['cellTypeYesNo_Yes', 'cellTypeYesNo_No'].map(x => translateFromKey(x, vuexStore.state.pgridSettings.language));
        // }

        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    finishEditing(restoreOriginalValue) {
        if (!this.cellProperties) {
            this.cellProperties = {};
        }
        this.cellProperties.isInfinishEditing = true;

        // let newValueArr = super.getValue().split(',')
        // let newValue = null;

        // if (newValueArr.length > 0) {
        //     newValue = newValueArr.pop();
        // }
        let newValueArr = super.getValue().split(',')
        let newValue = null;


        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });
        let chngCell = pgridDataBuffer[this.row][this.col];
        let changeWarn = lodash_get(chngCell, "Meta.Validator.ChangeWarn", null);

        if (changeWarn) {
            let userOKChange = true; //; confirm(changeWarn);

            let currCellVal = PGridCell.getHotValue2(chngCell);

            let currCellValProbablyUnchanged = false;

            //When clicking on a dropdown cell

            if (this.state == "STATE_EDITING") {

                if (newValueArr.length == 0) {
                } else if (newValueArr.length == 1) {
                    newValue = newValueArr[0];
                } else {
                    window.PGridClientDebugMode > 1 && console.warn(`Detected multivalm which is curious, but also normal. Handle as unchanged`);
                    newValue = null;
                    currCellValProbablyUnchanged = true;
                }

                if (currCellValProbablyUnchanged == false && String(currCellVal) != String(newValue)) {

                    userOKChange = confirm(changeWarn);

                    if (userOKChange == false) {
                        restoreOriginalValue = true;
                    }
                }
            }
        }

        super.finishEditing(restoreOriginalValue);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


function EditIfFormula(row, col, originalValue) {

    if (isNaN(row) || row === -1) {
        return;
    }

    let ret = originalValue
    let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });
    let chngCell = pgridDataBuffer[row][col];

    if (chngCell && "Formula" in chngCell) {
        ret = chngCell.Formula;
    }

    return ret;
}

function delayDisable_isCellEditing() {
    setTimeout(function () {
        PGridVueStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: false, source: `delayDisable_isCellEditing` });
        PGridVueStore.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: false, source: `delayDisable_isCellEditing` });
    }, 1000); //1s delay if user go directly to SAVE button without exiting cell
}

class PGridCellEditorHandsontableEditor extends Handsontable.editors.TextEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);
        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


class PGridCellEditorLookup extends Handsontable.editors.AutocompleteEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }


    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}

class PGridCellEditorFilter extends Handsontable.editors.AutocompleteEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);

    }

    // afterOnCellMouseDown(_, coords) {
    //     const sourceValue = this.getSourceData(coords.row, coords.col);

    //     // if the value is undefined then it means we don't want to set the value
    //     if (sourceValue !== void 0) {
    //       parent.setValue(sourceValue);
    //     }
    //     parent.instance.destroyEditor(true);
    //   }

    getValue() {

        if ('cellProperties' in this) {
            if (!('filter_Label' in this.cellProperties)) {
                // Or should this be set here -> "setValue(newValue) {""
                this.cellProperties.filter_Label = this.originalValue;
                this.cellProperties.filter_Label2 = this.originalValue;

                //Clear away filter label on first view of filter
                this.TEXTAREA.value = '';
            }

            return this.TEXTAREA.value;
        }
        return;
    }



    setValue(newValue) {

        let procesesVal = newValue;

        if (Array.isArray(procesesVal)) {
            if (procesesVal.length == 0) {
                procesesVal = '';
            }
            else {
                procesesVal = procesesVal[0];
                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: true, source: `pgridHT.js PGridCellEditorFilter()` });
            }
        }

        let meta = this.instance.getCellMeta(this.row, this.col);

        if (/*newValue !== this.originalValue
            &&*/
            !('filter_select_value_or_last_value' in meta)) {

            // if (!Array.isArray(newValue)) {
            meta.filter_select_value_or_last_value = this.originalValue;

            //Also set  meta.filter_Label ?
            meta.label = { value: this.originalValue };
            // }
        }

        if (newValue == '') {
            procesesVal = meta.filter_select_value_or_last_value;

        }

        this.TEXTAREA.value = newValue;


    }


    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}



class PGridCellEditorCheckbox extends Handsontable.editors.CheckboxEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        // Invoke the original method...
        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}


class PGridCellEditorPercent extends Handsontable.editors.TextEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    getValue() {
        let ret = null;
        if (PGridUtils.IsNumeric(this.TEXTAREA.value)) {

            const precision = 1000000;
            ret = Math.round(precision * Number(this.TEXTAREA.value)) / precision / 100  //https://stackoverflow.com/questions/55964876/javascript-number-divison
            //  String(Number(this.TEXTAREA.value) / 100);

        } else {
            //This will probably generate an cell validation error;
            ret = this.TEXTAREA.value;
        }
        return ret;
    }

    setValue(newValue) {
        let ret = null;
        if (PGridUtils.IsNumeric(newValue)) {
            let newValNum = Number(newValue);

            const precision = 1000000;
            ret = Math.round(precision * newValNum * 100) / precision   //https://stackoverflow.com/questions/55964876/javascript-number-divison
            // ret = newValNum * 100;
        } else if (newValue == "") {
            ret = 0;
        } else {
            //This will probably generate an cell validation error;
            ret = newValue;
        }
        this.TEXTAREA.value = String(ret);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}
// Same as (class) PGridCellEditorPercent but does not convert 100 to 1.0 and vise versa
class PGridCellEditorPerc100 extends Handsontable.editors.TextEditor {
    prepare(row, col, prop, td, originalValue, cellProperties) {

        originalValue = EditIfFormula(row, col, originalValue);

        super.prepare(row, col, prop, td, originalValue, cellProperties);
    }

    getValue() {
        let ret = null;
        if (PGridUtils.IsNumeric(this.TEXTAREA.value)) {

            ret = Number(this.TEXTAREA.value);

        } else {
            //This will probably generate an cell validation error;
            ret = this.TEXTAREA.value;
        }
        return ret;
    }

    setValue(newValue) {
        let ret = null;
        if (PGridUtils.IsNumeric(newValue)) {
            let newValNum = Number(newValue);

            ret = newValNum;
        } else if (newValue == "") {
            ret = 0;
        } else {
            //This will probably generate an cell validation error;
            ret = newValue;
        }
        this.TEXTAREA.value = String(ret);
    }

    close() {
        delayDisable_isCellEditing();
        super.close();
    }
}

// PlannicaGrids custom cell renderer, which renders a varity of cell types


function PGridCellRenderer(hotInstance, td, row, col, prop, htValue, cellProperties) {



    if (row == 3 && col == 5) {
        let foobar = "flkjdl54kfjalskjfölaksjfs";
    }



    Handsontable.renderers.BaseRenderer.apply(this, arguments);

    try {

        let cellIsBeyondVisible = false;

        if (
            PGridMatrices.IsOutsideLowestRight(row, col)
        ) {
            cellIsBeyondVisible = true;
            td.classList.add("pg-hide-this-lr");
        }

        let pgridData = null;
        try {
            pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `PGridRendererStyle()` });
        }
        catch {
            //This happens when calling Action_PgridRESET
            return td;
        }


        let pcell = PGridUtils.getPGridCell(pgridData, row, col);


        let isValStr = false;
        let forceRenderWhenNoValue = false;

        // let htValueNumIfPoss_IsNum = null;
        //CSS Style

        if (pcell != null) {

            if ("Error" in pcell) {

                let indcateError = true;


                //Is this needed, should be handles in Insert_Calculated_Cell()?
                if (pcell.Error.indexOf("#") == 0 && pcell.Error.indexOf("!") != -1) {
                    // This was needed as this trick does not work '=IF(W45=0,0,W11/(W11+W45))'
                    let numberFormatZeroIfError = lodash_get(pcell, "Meta.NumberFormat.ZeroIfError", null);
                    if (numberFormatZeroIfError) {
                        htValue = "0"; //used by htValueNumIfPoss
                        indcateError = false;
                        pcell.Val = 0;
                        delete pcell.ValStr;
                    }
                }

                if (indcateError) {
                    if (td.className.indexOf("pg-cell-parse-error") == -1) {
                        td.classList.add("pg-cell-parse-error");
                    }
                    // isValStr = true;
                    htValue = pcell.Error;
                }
            }


            if ("Format" in pcell && cellIsBeyondVisible == false) {

                let i = PGridUtils.Parse_FormatField(pcell.Format)
                if (i.class != null) {
                    i.class.split(",").forEach(cl => {
                        if (cl == "normaltext") {
                            isValStr = true;
                        }
                        if (td.className.indexOf(cl) == -1) {
                            td.classList.add(cl.trim());
                        }
                    });
                }
            }

            if ("Meta" in pcell) {

                if ("Style" in pcell.Meta && cellIsBeyondVisible == false) {
                    td.style.cssText += pcell.Meta.Style;
                }

                if ("CustomContent" in pcell.Meta && cellIsBeyondVisible == false) {
                    /*
                    Example that positions content with 90% height at the bottom left of the cell:

                    "Meta": {
                            "SpanRows": 1,
                            "Save": {
                                "ReadOnly": true
                            },
                            "CustomContent": {
                                "height": "90%",
                                "positionVertical": "bottom",
                                "positionHorizontal": "right",
                                "Content": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"#ff000f\" viewBox=\"-1 -1 88.2 35\">  <path d=\"M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z\"/>  <rect width=\"10\" height=\"16\" x=\"36\" y=\"17\"/>  <path d=\"M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z\"/>  <rect width=\"10\" height=\"16\" x=\"36\"/>  <path d=\"M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z\"/>  <rect width=\"10\" height=\"16\" x=\"63\" y=\"17\"/>  <path d=\"M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z\"/>  <rect width=\"10\" height=\"16\" x=\"63\"/>  <polygon points=\"5.7,17 0,33 8.3,33 10.7,26 16,26 16,17\"/>  <polygon points=\"16,0 11.7,0 6,16 16,16\"/>  <polygon points=\"17,26 22.3,26 24.7,33 33,33 27.3,17 17,17\"/>  <polygon points=\"27,16 21.3,0 17,0 17,16\"/></svg>"
                            }
                        }

                    or

                    "Meta": {
                            "SpanRows": 1,
                            "Save": {
                                "ReadOnly": true
                            },
                            "StyleContentContainer": "position: relative; height: 100%; width: 100%;",
                            "StyleContent": "position: absolute; height: 90%; bottom: 0; left: 0;",
                            "Content": "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"#ff000f\" viewBox=\"-1 -1 88.2 35\">  <path d=\"M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z\"/>  <rect width=\"10\" height=\"16\" x=\"36\" y=\"17\"/>  <path d=\"M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z\"/>  <rect width=\"10\" height=\"16\" x=\"36\"/>  <path d=\"M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z\"/>  <rect width=\"10\" height=\"16\" x=\"63\" y=\"17\"/>  <path d=\"M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z\"/>  <rect width=\"10\" height=\"16\" x=\"63\"/>  <polygon points=\"5.7,17 0,33 8.3,33 10.7,26 16,26 16,17\"/>  <polygon points=\"16,0 11.7,0 6,16 16,16\"/>  <polygon points=\"17,26 22.3,26 24.7,33 33,33 27.3,17 17,17\"/>  <polygon points=\"27,16 21.3,0 17,0 17,16\"/></svg>"
                        }
                    */

                    let defaultStyleContentContainer = "position: relative; height: 100%; width: 100%;";
                    let defaultStyleContent = "position: absolute; height: 100%;/* width: 100%;*/ top: 0px; left: 0px;";
                    let defaultStyle = "padding-left: 0; padding-right: 0; width: 100%;"; //No padding for CustomContent

                    let saveImportantClasses = null;
                    saveImportantClasses = Array.from(td.classList).filter(className => className.indexOf("pg-is-") == 0);

                    Handsontable.dom.empty(td);
                    saveImportantClasses.forEach(c => { td.classList.add(c); });

                    const divContainer = document.createElement("div");
                    divContainer.innerHTML = pcell.Meta.CustomContent.Content;


                    if ("height" in pcell.Meta.CustomContent) {
                        defaultStyleContent = defaultStyleContent.replace(" height: 100%;", ` height: ${pcell.Meta.CustomContent.height};`)
                    }
                    if ("width" in pcell.Meta.CustomContent) {
                        throw new Error(`pcell.Meta.CustomContent.width is not supported. Workaround: Set column width in LRPGridSettings`);
                    }

                    if ("positionVertical" in pcell.Meta.CustomContent) {
                        if (pcell.Meta.CustomContent.positionVertical == "bottom") {
                            defaultStyleContent = defaultStyleContent.replace(" top: 0px;", ' bottom: 0px;');
                        }
                        else if (pcell.Meta.CustomContent.positionVertical == "top") {
                            defaultStyleContent = defaultStyleContent.replace(" top: 0px;", ' top: 0px;');
                        }
                    }
                    if ("positionHorizontal" in pcell.Meta.CustomContent) {
                        if (pcell.Meta.CustomContent.positionHorizontal == "right") {
                            defaultStyleContent = defaultStyleContent.replace("left: 0px;", ' right: 0px;');
                        }
                        else if (pcell.Meta.CustomContent.positionHorizontal == "left") {
                            defaultStyleContent = defaultStyleContent.replace("left: 0px;", ' left: 0px;');
                        }
                    }


                    if ("StyleContainer" in pcell.Meta.CustomContent) {
                        divContainer.style.cssText += pcell.Meta.CustomContent.StyleContainer;
                    }
                    else {
                        divContainer.style.cssText += defaultStyleContentContainer;
                    }

                    if ("StyleContent" in pcell.Meta.CustomContent) {
                        divContainer.firstElementChild.style.cssText += pcell.Meta.CustomContent.StyleContent;
                    }
                    else {
                        divContainer.firstElementChild.style.cssText += defaultStyleContent;
                    }

                    if ("Style" in pcell.Meta) {
                        td.style.cssText += pcell.Meta.Style;
                    } else {
                        td.style.cssText += defaultStyle;
                    }

                    td.appendChild(divContainer);
                    return td;
                }



            }

            if (htValue == null) {
                if ("RefType" in pcell) {
                    if (lodash_get(pcell, "Meta.Validator.Type", null) == "apexcharts")
                        forceRenderWhenNoValue = true;
                    if (lodash_get(pcell, "Meta.Format.Type", null) == "apexcharts")
                        forceRenderWhenNoValue = true;
                }
            }
        }


        if (htValue != null || forceRenderWhenNoValue) {

            let isnum = PGridUtils.MakeNumericIfPossible(htValue);


            if (isnum.htValueNumIfPoss_IsNum === false /*isValStr*/) {

                let formatAlternativeText = pcell && pcell.Meta && pcell.Meta.Format && pcell.Meta.Format.AlternativeCaption; //lodash_get(pcell, "Meta.Format.AlternativeCaption", null);

                if (formatAlternativeText != null) {
                    Handsontable.dom.empty(td);
                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(formatAlternativeText)));
                    td.appendChild(innerText);
                }
                else {
                    if ("LimitStrLenBy" in cellProperties) {

                        if (cellProperties.LimitStrLenBy > 0) {

                            if (cellIsBeyondVisible == false) {
                                td.classList.add("pg-overflow-ellipsis");
                                /* Mode 'wordWrap = false' almost evrywere */
                                cellProperties.wordWrap = false;
                                if (true || window.PGridVueStore.state.secondRenderRequest == 1) {
                                    window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "secondRenderRequest", val: 2, source: `PGridCellRenderer()` });
                                }
                                delete cellProperties.forceWordWrap;
                            }
                        }
                        else {
                            if (cellIsBeyondVisible == false) {
                                td.classList.remove("pg-overflow-ellipsis");
                                /* Mode 'wordWrap = false' almost evrywere */
                                delete cellProperties.wordWrap;
                                cellProperties.forceWordWrap = true;
                                if (true || window.PGridVueStore.state.secondRenderRequest == 1) {
                                    window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "secondRenderRequest", val: 2, source: `PGridCellRenderer()` });
                                }

                            }

                        }
                    }
                    /*
                    else {
                        if (cellIsBeyondVisible == false) {
                            // Mode 'wordWrap = false' almost evrywere
                            // cellProperties.wordWrap = false;
                        }
                    }
    */
                    Handsontable.dom.empty(td)

                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                    td.appendChild(innerText);
                }
            } else {

                let formatRedOnNegative = lodash_get(pcell, "Meta.Format.RedOnNegative", false);
                let formatRedOnNonZero = lodash_get(pcell, "Meta.Format.RedOnNonZero", false);

                let validateType = lodash_get(pcell, "Meta.Validator.Type", null);

                if (validateType == null) {
                    // FUB For not, both Meta.Save.Validator.Type and Meta.Validator.Type is working, this should be only way to set validation type
                    validateType = lodash_get(pcell, "Meta.Save.Validator.Type", null);
                }

                // let [htValueNumIfPoss_IsNum, htValueNumIfPoss] = PGridUtils.MakeNumericIfPossible(htValue);
                // // let htValueNumIfPoss_IsNum = PGridUtils.IsNumeric(htValueNumIfPoss)

                let formatAlternativeText = pcell && pcell.Meta && pcell.Meta.Format && pcell.Meta.Format.AlternativeCaption; //lodash_get(pcell, "Meta.Format.AlternativeCaption", null);

                if (formatAlternativeText != null) {
                    Handsontable.dom.empty(td);
                    let innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(formatAlternativeText)));
                    td.appendChild(innerText);
                }
                else {

                    if (isnum.htValueNumIfPoss_IsNum) {
                        if (formatRedOnNegative && isnum.htValueNumIfPoss < 0 || formatRedOnNonZero && isnum.htValueNumIfPoss !== 0) {
                            td.classList.add("pg-red");
                        }
                    }

                    if (validateType == "percent" || validateType == "perc100") {

                        if (isnum.htValueNumIfPoss_IsNum) {

                            let percetVal = Number(isnum.htValueNumIfPoss);

                            let percetVal100 = validateType == "percent" ? percetVal * 100 : percetVal;

                            td.classList.add("pg-cell-percentage");

                            //FUB same as below
                            let numberFormatHideIfZero = false;
                            let numberFormatLocale = window.PGridVueStore.state.pgridSettings.locale;
                            let numberFormatOptions = {
                                style: 'decimal',
                                minimumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[0],
                                maximumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[1]
                            };
                            let numberFormatHighlightRules = null;

                            if (pcell && "Meta" in pcell && "NumberFormat" in pcell.Meta) {
                                numberFormatHideIfZero = lodash_get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero);
                                numberFormatLocale = lodash_get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale);
                                numberFormatOptions = lodash_get(pcell, "Meta.NumberFormat.Options", numberFormatOptions);
                                numberFormatHighlightRules = lodash_get(pcell, "Meta.NumberFormat.HighlightRules", null);
                            }

                            if (numberFormatHighlightRules && Array.isArray(numberFormatHighlightRules)) {
                                let numberFormatHighlightRules_length = numberFormatHighlightRules.length;
                                for (let r = 0; r < numberFormatHighlightRules_length; r++) {
                                    let rule = numberFormatHighlightRules[r];
                                    /* gt = grater than, le = less then or equal 
                                    rule = { gt : 100,              action: { addClass: "pgHighlightRed" } };
                                    rule = { ge : 90, le : 100      action: { addClass: "pgHighlightGreen" } };
                                    rule = { gt : 50, le : 90,      action: { addClass: "pgHighlightWhite" } };
                                    rule = { le : 20,               action: { addClass: "pgHighlightYellow" } };
                                    */
                                    let rulePass_gt = true;
                                    let rulePass_ge = true;
                                    let rulePass_lt = true;
                                    let rulePass_le = true;

                                    if ("gt" in rule) {
                                        rulePass_gt = false;
                                        if (percetVal100 > rule.gt) {
                                            rulePass_gt = true;
                                        }
                                    }
                                    if ("ge" in rule) {
                                        rulePass_ge = false;
                                        if (percetVal100 >= rule.ge) {
                                            rulePass_ge = true;
                                        }
                                    }
                                    if ("lt" in rule) {
                                        rulePass_lt = false;
                                        if (percetVal100 < rule.lt) {
                                            rulePass_lt = true;
                                        }
                                    }
                                    if ("le" in rule) {
                                        rulePass_le = false;
                                        if (percetVal100 <= rule.le) {
                                            rulePass_le = true;
                                        }
                                    }
                                    if (
                                        rulePass_gt &&
                                        rulePass_ge &&
                                        rulePass_lt &&
                                        rulePass_le
                                    ) {
                                        if (rule.action) {
                                            if (rule.action.addClass) {
                                                td.classList.add(rule.action.addClass);
                                            }
                                        }
                                    }
                                }
                            }

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");
                            Handsontable.dom.empty(td);

                            if (numberFormatHideIfZero && isnum.htValueNumIfPoss === 0) {
                                //Don't render anything when zero
                            } else {
                                let innerText = document.createTextNode(`${percetVal100.toLocaleString(numberFormatLocale, numberFormatOptions)}%`);
                                td.appendChild(innerText);
                            }
                        }
                    }
                    else if (validateType == "apexcharts") {

                        try {
                            let anchorId = `apex_chart_${row}_${col}`;

                            Handsontable.dom.empty(td);
                            let apexchart_anchor = document.createElement('div');
                            apexchart_anchor.setAttribute('id', anchorId)
                            td.appendChild(apexchart_anchor);

                            // var options = {
                            //     chart: {
                            //         type: 'line',
                            //         height: '100%'
                            //     },
                            //     series: [{
                            //         name: 'sales',
                            //         data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
                            //     }],
                            //     xaxis: {
                            //         categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                            //     }
                            // }

                            setTimeout(() => {
                                let anchor = document.querySelector(`#${anchorId}`);
                                if (anchor.childNodes.length == 0) {
                                    // var chart = new ApexCharts(anchor, pcell.Meta.ApexCharts.options);
                                    // chart.render();
                                    console.error(`ApexCharts "apexcharts": "^3.27.1", removed in this release`)
                                }
                            }, 500);

                        } catch (err) {
                            console.warn(`PGridCellRenderer() got exception (y:${row} x:${col}): ${err.message}`);
                        }
                    }
                    else if (validateType != null && validateType.indexOf("decimal") == 0) {

                        if (isnum.htValueNumIfPoss_IsNum) {

                            let numberFormatHideIfZero = false;
                            let numberFormatLocale = window.PGridVueStore.state.pgridSettings.locale;
                            let numberFormatOptions = {
                                style: 'decimal',
                                minimumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[0],
                                maximumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[1]
                            };

                            // "Meta": {
                            //     "NumberFormat": {
                            //         "Options": {
                            //             "style": "decimal",
                            //             "minimumFractionDigits": 0,
                            //             "maximumFractionDigits": 0
                            //         }
                            //     }
                            // }

                            if (pcell && "Meta" in pcell && "NumberFormat" in pcell.Meta) {
                                numberFormatHideIfZero = lodash_get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero);
                                numberFormatLocale = lodash_get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale);
                                numberFormatOptions = lodash_get(pcell, "Meta.NumberFormat.Options", numberFormatOptions);
                            }

                            //Remove child text
                            Handsontable.dom.empty(td);

                            if (numberFormatHideIfZero && isnum.htValueNumIfPoss === 0) {
                                //Don't render anything when zero
                            } else {
                                let innerText = document.createTextNode(`${isnum.htValueNumIfPoss.toLocaleString(numberFormatLocale, numberFormatOptions)}`);
                                td.appendChild(innerText);
                            }

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");
                        } else {
                            Handsontable.dom.empty(td);
                            let innerText = null;
                            innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                            td.appendChild(innerText);
                        }
                    }
                    else if (validateType != null && validateType === "text") {
                        Handsontable.dom.empty(td);
                        let innerText = null;
                        innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                        td.appendChild(innerText);
                    }
                    else if (validateType == "integer") {

                        if (isnum.htValueNumIfPoss_IsNum) {

                            let integerVal = String(isnum.htValueNumIfPoss);
                            // let percetVal100 = percetVal * 100;

                            let numberFormatHideIfZero = false;
                            // let numberFormatLocale = window.PGridVueStore.state.pgridSettings.locale;
                            // let numberFormatOptions = {
                            //     style: 'decimal',
                            //     minimumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[0],
                            //     maximumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[1]
                            // };



                            if (pcell && "Meta" in pcell && "NumberFormat" in pcell.Meta) {
                                numberFormatHideIfZero = lodash_get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero);
                                // numberFormatLocale = lodash_get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale);
                                // numberFormatOptions = lodash_get(pcell, "Meta.NumberFormat.Options", numberFormatOptions);
                            }

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");
                            Handsontable.dom.empty(td);

                            if (numberFormatHideIfZero && isnum.htValueNumIfPoss === 0) {
                                //Don't render anything when zero
                            }
                            else {
                                let innerText = document.createTextNode(`${integerVal}`);
                                td.appendChild(innerText);
                            }
                        }
                    }
                    //The rest cases
                    else {

                        if (isnum.htValueNumIfPoss_IsNum) {

                            let numberFormatHideIfZero = false;
                            let numberFormatLocale = window.PGridVueStore.state.pgridSettings.locale;
                            let numberFormatOptions = {
                                style: 'decimal',
                                minimumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[0],
                                maximumFractionDigits: window.PGridVueStore.state.pgridSettings.defaultFractionDigits[1]
                            };

                            // "Meta": {
                            //     "NumberFormat": {
                            //         "Options": {
                            //             "style": "decimal",
                            //             "minimumFractionDigits": 0,
                            //             "maximumFractionDigits": 0
                            //         }
                            //     }
                            // }
                            if (pcell && "Meta" in pcell && "NumberFormat" in pcell.Meta) {
                                numberFormatHideIfZero = lodash_get(pcell, "Meta.NumberFormat.HideIfZero", numberFormatHideIfZero);
                                numberFormatLocale = lodash_get(pcell, "Meta.NumberFormat.Locale", numberFormatLocale);
                                numberFormatOptions = lodash_get(pcell, "Meta.NumberFormat.Options", numberFormatOptions);
                            }

                            //Remove child text
                            Handsontable.dom.empty(td);

                            td.classList.add("pgNumeric");
                            td.classList.add("pgRight");

                            if (numberFormatHideIfZero && isnum.htValueNumIfPoss === 0) {
                                //Don't render anything when zero
                            } else {
                                let innerText = document.createTextNode(`${isnum.htValueNumIfPoss.toLocaleString(numberFormatLocale, numberFormatOptions)}`);
                                td.appendChild(innerText);
                            }
                        }
                        else {
                            Handsontable.dom.empty(td);
                            let innerText = null;
                            innerText = document.createTextNode(PGridUtils.safeifyHtmlData(String(htValue)));
                            td.appendChild(innerText);
                        }
                    }

                }
            }

            if ("DoubleClickLink" in cellProperties && cellProperties.DoubleClickLink) {
                if (cellIsBeyondVisible == false) {
                    td.classList.add("pgridNoDblClickEdit");
                    td.classList.add("pgridDoubleClickLink");
                }
            }

            if ("SingleClickLink" in cellProperties && cellProperties.SingleClickLink) {
                if (cellIsBeyondVisible == false) {
                    td.classList.add("pgridNoDblClickEdit");
                    td.classList.add("pgridSingleClickLink");
                }
            }

            let references = lodash_get(pcell, "Meta.References", null);
            if (references) {
                if (vuexStore.state.pgridSettings.PGridTableShowHiddenMode) {
                    if (cellIsBeyondVisible == false) {
                        td.setAttribute('title', references.join("\n"));
                    }
                }
            }
        }
        else {
            //Required to avoid unvanted late rendered cells with left aligned "0"
            Handsontable.dom.empty(td);
        }


    } catch (err) {
        let errMsg = `> PGridCellRenderer() got exception: ${err.message || err}`;
        console.error(errMsg);
        td.innerHTML = PGridUtils.safeifyHtmlData(`#ERROR! (3) ${errMsg}`);
    }

    if (PGridUtils.IsColumnHidden(col)) {
        Handsontable.dom.empty(td);
    }

    return td;

}

let PGridValidators = {
    number: function (value, callback) {
        let valRet = false;

        if (value !== null && value !== undefined && value !== "") {

            if (PGridUtils.IsNumeric(value)) {
                valRet = true;
            } else if (value == undefined) {

                if (validatorAllowEmpty) {
                    valRet = true;
                }

            }
        } else {
            valRet = true;
        }

        if (valRet == false) {
            window.PGridClientDebugMode >= 3 && console.debug(`PGridValidators(): number: '${value}' is not a valid number`);
        }

        callback(valRet);

    },
    percent: function (value, callback) {
        let valRet = false;

        if (value !== null && value !== undefined && value !== "") {

            if (PGridUtils.IsNumeric(value)) {
                valRet = true;
            } else if (value == undefined) {

                if (validatorAllowEmpty) {
                    valRet = true;
                }

            }
        } else {
            valRet = true;
        }
        callback(valRet);
    },
    perc100: function (value, callback) {
        let valRet = false;

        if (value !== null && value !== undefined && value !== "") {

            if (PGridUtils.IsNumeric(value)) {
                valRet = true;
            } else if (value == undefined) {

                if (validatorAllowEmpty) {
                    valRet = true;
                }

            }
        } else {
            valRet = true;
        }
        callback(valRet);
    },
    integer: String.raw`^-?\d+$`,
    // yesno: String.raw`^(${translateFromKey('cellTypeCheckbox_Checked', lang)})$`,
    // truefalse: String.raw`^(True|False)$`,
    // checkbox: String.raw`^(Ja|Nej|Yes|No|\d+)$`,
    yesno:
        function (value, callback) {
            let valRet = false;
            if (value == translateFromKey("cellTypeYesNo_Yes"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            else if (value == translateFromKey("cellTypeYesNo_No"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            callback(valRet);
        }
    ,
    truefalse:
        function (value, callback) {
            let valRet = false;
            if (value == translateFromKey("cellTypeTrueFalse_True"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            else if (value == translateFromKey("cellTypeTrueFalse_False"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            callback(valRet);
        }
    ,
    checkbox:
        function (value, callback) {
            let valRet = false;
            if (value == translateFromKey("cellTypeCheckbox_Checked"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            else if (value == translateFromKey("cellTypeCheckbox_UnChecked"), window.PGridVueStore.state.pgridSettings.language) {
                valRet = true;
            }
            callback(valRet);
        }
    ,
    lookup: String.raw`^.*$`,
    json: String.raw`^\{.*\}$`,
    text: String.raw`^.*$`,
    colfilter: String.raw`^.*$`,
    date: String.raw`^\d{4}\-\d{1,2}\-\d{1,2}$`
}


export let HOTEventHandlers = {



    afterSelection: async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {

        window.PGridClientDebugMode >= 2 && console.debug("afterSelection() start");

        if (isNaN(row) || row === -1) {
            return; //(caused by the window.PGridPerformanceMode optimization?)
        }


        let context = vuexStore;

        // vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: false, source: `afterChange` });


        let lastSelected = null;

        if (vuexStore.state.hotRef && vuexStore.state.lastSelected && vuexStore.state.lastSelected != "") {
            let { y, x } = JSON.parse(vuexStore.state.lastSelected);
            lastSelected = { y, x };

            if ((row === row2 && row === lastSelected.y)
                &&
                (column === column2 && column === lastSelected.x)) {
                // window.PGridClientDebugMode >= 2 && console.debug("afterSelection quit exit as last selected is same");
                // return;
            }
        }

        await PGridUtils.handleOrDelayAfterChange(vuexStore, null, `afterSelection()`);


        window.PGridClientDebugMode >= 4 && console.debug(`afterSelection: ${JSON.stringify({
            row,
            column,
            inputBar_HasFocus: vuexStore.state.pgridSettings.inputBar_HasFocus,
            lastSelected: vuexStore.state.lastSelected,
            inputBar_HasFocusCellJumpBack: vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack,
            inputBar_HasFocus: vuexStore.state.pgridSettings.inputBar_HasFocus,
            DisableEvents: vuexStore.state.pgridSettings.DisableEvents
        }, null, 2)}`);


        vuexStore.commit("Mutation_UpdateRoot", {
            prop: "lastSelected",
            val: JSON.stringify({ y: row, x: column }),
            source: "afterSelection()"
        });


        if (vuexStore.state.pgridSettings.DisableEvents || vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes > 1) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterSelection: events disabled`);
            return;
        }

        //Always enable on first cell selection
        if (vuexStore.state.pgridSettings.inputBar_Enabled == false) {
            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_Enabled', val: true, source: `afterSelection()` });
        }

        if (vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack) {

            let selectedCellLabel = toLabel({ index: row }, { index: column });

            PGridUtils.AddInputBarClasses(vuexStore, row, column, "pg-inputbar-clickedcell");

            let newSelectedCellLabel = vuexStore.state.pgridSettings.inputBar_SelectCellVal;

            let cursorPos = vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_CursorPos


            if (cursorPos != -1) {
                newSelectedCellLabel = newSelectedCellLabel.substring(0, cursorPos) + selectedCellLabel + newSelectedCellLabel.substring(cursorPos);
                cursorPos = cursorPos + selectedCellLabel.length;
            } else {
                newSelectedCellLabel = newSelectedCellLabel + selectedCellLabel;
                cursorPos = cursorPos + selectedCellLabel.length;
            }


            window.PGridClientDebugMode >= 3 && console.debug("afterSelection handleCursorChange cursorPos " + cursorPos);
            vuexStore.commit("Mutation_UpdatePGridSettings", {
                prop: "inputBar_HasFocusCellJumpBack_CursorPos",
                val: cursorPos,
                source: `afterSelection()`
            });

            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_SelectCellVal', val: newSelectedCellLabel, source: `afterSelection()` });


            //Return focus
            // if (vuexStore.state.hotRef && vuexStore.state.lastSelected && vuexStore.state.lastSelected != "") {
            //     let { y, x } = JSON.parse(vuexStore.state.lastSelected);
            if (lastSelected) {

                window.PGridClientDebugMode >= 3 && console.debug("Return focus")
                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_HasFocusCellJumpBack', val: true, source: `afterSelection()` });

                // PGridUtils.AddInputBarClasses(vuexStore, lastSelected.y, lastSelected.x, "pg-inputbar-editcell");

                if (vuexStore.state.pgridSettings.inputBar_inputRef) {
                    setTimeout(() => {
                        false && console.debug("Return focus inputBar_inputRef.deselectCell")

                        // vuexStore.state.hotRef.deselectCell();
                        Deselect({ source: `afterSelection()` })
                        setTimeout(() => {
                            false && console.debug("Return focus inputBar_inputRef.focus()")
                            vuexStore.state.pgridSettings.inputBar_inputRef.focus();
                        });

                        let cursorPos = vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_CursorPos;

                        console.log("Return focus inputBar_inputRef cursorPos: " + cursorPos);
                        vuexStore.state.pgridSettings.inputBar_inputRef.selectionEnd = cursorPos;
                    }, vuexStore.state.pgridSettings.inputBar_HasFocusCellJumpBack_delayedMiliseconds);
                }
            }

        } else {

            if (vuexStore.state.pgridSettings.inputBar_HasFocus == false) {

                window.PGridClientDebugMode >= 4 && console.debug("afterSelection B.1");

                let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });

                let pcell = pgridDataBuffer[row][column];
                let selectCellVal = PGridCell.InputBar_ConvertPGridCellForEdit(vuexStore, { pcell, source: `afterSelection()` });


                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'inputBar_SelectCellVal', val: selectCellVal, source: `afterSelection()` });

                // vuexStore.commit("Mutation_UpdateRoot", {
                //     prop: "lastSelected",
                //     val: JSON.stringify({ y: row, x: column }),
                //     source: "afterSelection()"
                // });


                if (!(vuexStore.state.pgridSettings.inputBar_BarMode == "small" || vuexStore.state.pgridSettings.inputBar_BarMode == "none")) {
                    PGridUtils.RemoveInputBarClasses(vuexStore);
                    PGridUtils.HeighlightRefCells(vuexStore, row, column);
                }


            } else {

                window.PGridClientDebugMode >= 4 && console.debug("afterSelection B.2");

            }
        }


        /*
            After an selection is changes, this code detects any registred,
            in pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) Overlay items
            rule (with If_Cell_Is_Selected) should be taken care of by lr.Phase8_Insert_Overlay(...["overlay_selectionchange"]
        */


        let cellLRRefs_Keys = null;
        if (context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
            cellLRRefs_Keys = Object.keys(context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays);
        }

        let old_lastSelectedWasA_selectionchange = context.state.lastSelectedWasA_selectionchange

        if (cellLRRefs_Keys) {


            let forceRender = false;

            window.PGridClientDebugMode >= 3 && console.debug(`afterSelection(): Has cellLRRefs_Keys`)
            let pgridDataBuffer = null;

            let y = row;
            let x = column;

            let foundIdx = cellLRRefs_Keys.indexOf(`overlay_selectionchange:${y}:${x}`);
            if (foundIdx != -1) {

                // This gets triggered when a cell If_Cell_Is_Selected is selected`
                window.PGridClientDebugMode >= 3 && console.debug(`This gets triggered when a cell If_Cell_Is_Selected is selected: overlay_selectionchange:${y}:${x}`);

                context.commit("Mutation_UpdateRoot", {
                    prop: "lastSelectedWasA_selectionchange",
                    val: { y: y, x: x },
                    source: "afterSelection()"
                });


                // if (y > lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", Infinity)) {
                //     hasOverlayButWasTriggeredFromMasterTable = true;
                // }
                forceRender = true;

                let lrAffected_List = context.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays[cellLRRefs_Keys[foundIdx]];

                for (let l = 0; l < lrAffected_List.length; l++) {
                    let lrAffected = lrAffected_List[l];

                    let [lrName, yLR, xLR] = lrAffected.split(":");

                    if (pgridDataBuffer == null) {
                        pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context, source: `afterselection` });
                    }
                    const lr = PGridMatrices.Get_LRFromTableCoordinates(yLR, xLR, pgridDataBuffer);


                    let pgridDataBufferBefore = null;
                    if (window.PGridClientDebugMode >= 3) {
                        pgridDataBufferBefore = JSON.parse(JSON.stringify(pgridDataBuffer));
                    }

                    let { pgridDataInserted, hotTableInserted } = PGridMatrices.InsertOverlay(
                        lr
                        , pgridDataBuffer
                        , context.state.hotRef.getData()
                        , context
                        , "selectionchangeEvent"
                    );

                    pgridDataBuffer = pgridDataInserted;

                    if (window.PGridClientDebugMode >= 2) {
                        PGridUtils.DebugDetectTableChanges(`HOTEventHandlers.afterSelection()`, pgridDataBuffer, pgridDataBufferBefore);
                    }

                }

                context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `afterselection` });


                if (lodash_has(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop")) {
                    if (old_lastSelectedWasA_selectionchange && old_lastSelectedWasA_selectionchange.y <= lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)
                        || y <= lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)
                    ) {
                        context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                        // context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                    }
                }

            }
            else if (context.state.lastSelectedWasA_selectionchange || old_lastSelectedWasA_selectionchange) { //Handle change selection out from a precious selected cell

                window.PGridClientDebugMode >= 3 && console.debug(`This gets triggered when a cell If_Cell_Is_Selected is un-selected: (not on this cell) overlay_selectionchange:${y}:${x}`);



                // if (y > lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", Infinity)) {
                //     hasOverlayButWasTriggeredFromMasterTable = true;
                // }
                forceRender = true;
                // if (!lodash_has(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop")) {
                //     forceRender = true;
                // }

                if (pgridDataBuffer == null) {
                    pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context, source: `afterselection` });
                }

                // let foundLRs = PGridMatrices.Get_LRsFromTableCached(pgridDataBuffer); // Get all linked ranges
                let foundLRs = PGridMatrices.Get_LRsFromTable(pgridDataBuffer, { source: `afterSelection()` });

                let foundLRs_Keys = Object.keys(foundLRs);
                for (let i = 0; i < foundLRs_Keys.length; i++) {
                    let lr = foundLRs[foundLRs_Keys[i]];


                    let { pgridDataInserted, hotTableInserted } = PGridMatrices.InsertOverlay(
                        lr
                        , pgridDataBuffer
                        , context.state.hotRef.getData()
                        , context
                        , "selectionchangeEvent"
                    );


                    pgridDataBuffer = pgridDataInserted;
                }
                context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `afterselection` });

                if (lodash_has(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop")) {
                    if (old_lastSelectedWasA_selectionchange && old_lastSelectedWasA_selectionchange.y <= lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)
                        || context.state.lastSelectedWasA_selectionchange <= lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)
                    ) {
                        context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                        // context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                    }
                }

                context.commit("Mutation_UpdateRoot", {
                    prop: "lastSelectedWasA_selectionchange",
                    val: null,
                    source: "afterSelection()"
                });

            }

            if (pgridDataBuffer) {

                // if (hasOverlayButWasTriggeredFromMasterTable === false) {
                //     if (lodash_has(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop")) {
                //         if (y <= lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)) {
                //             // context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                //             context.dispatch("Action_Redraw", { source: `afterselection extra render()`, force: true });
                //         }
                //     }
                // }

                window.PGridClientDebugMode >= 3 && console.debug("afterSelection() redraw");
                // context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `afterselection` });
                context.dispatch("Action_Redraw", { source: `afterselection`, force: forceRender });
            }
        }
    },

    beforeChange: async (changes) => {

        // if (!window.PGridTimers) {
        //     window.PGridTimers = {};
        // }
        // window.PGridTimers["StartTime_setDataAtCell"] = null;


        PGridUtils.PGridTimerStart('beforeChange:setDataAtCell');
        window.PGridClientDebugMode >= 2 && console.log(`beforeChange: ${JSON.stringify(changes)}`);

        if (vuexStore.state.pgridSettings.DisableEvents) {
            false && console.debug(`beforeChange: events disabled`);
            return;
        }

        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });

        for (let i = 0; i < changes.length; i++) {
            let [changeY, changeX, oldVal, newVal] = changes[i];

            if (oldVal != newVal
                || (oldVal === 0 /*|| oldVal === ""*/) && newVal === "" /* This catches "deleted" 0 or empty values. Maybe only oldVal !== newVal could be used insted? */
                || String(newVal).length > 0 && String(newVal)[0] == '=') {

                let chngCell = pgridDataBuffer[changeY][changeX];

                let changeWarn = lodash_get(chngCell, "Meta.Validator.ChangeWarn", null);
                let validatorType = lodash_get(chngCell, "Meta.Validator.Type", null);

                /*
                                if ((validatorType != "yesno" || validatorType != "truefalse") && changeWarn) {
                                    let userOKChange = confirm("beforeChange: " + changeWarn);

                                    if (userOKChange == false) {
                                        changes = false;

                                        // newVal = oldVal
                                        changes[i][3] = oldVal;
                                        // return;
                                    }
                                }
                */
                //Handels the case when a number cell is "deleted" that had a numer. As delete is not possible, it sets the value to 0 insted
                if ((validatorType === "number" || validatorType === "integer") && oldVal !== undefined && typeof newVal === "string" && newVal.trim() === "") {
                    changes[i][3] = 0;
                }

                if (String(newVal).length > 0 && String(newVal)[0] == '=') {
                    delete chngCell.Val;
                    delete chngCell.ValStr;
                }

            }
        }

        PGridUtils.PGridTimerStop('beforeChange');

    },

    afterBeginEditing: async (row, column) => {
        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isCellEditing', op: "set", val: true, source: `afterBeginEditing` });
        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'isApplyGridChange', op: "set", val: true, source: `afterBeginEditing` });

        let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });
        let pcell = PGridUtils.getPGridCell(pgridDataBuffer, row, column);

        if (PGridUtils.ConsiderDirtyGridFlag(pcell)) {
            await PGridUtils.DirtyGridFlag(vuexStore, `afterBeginEditing()`);
        }
    },

    afterChange: async (changes) => {

        let noOfChanges = -1;
        if (Array.isArray(changes)) {
            noOfChanges = changes.length;

            if (noOfChanges > 0) {
                if (vuexStore.state.lastSelected != null) { //Only call if allready fully loaded

                    for (let i = 0; i < noOfChanges; i++) {
                        const [y, x, from, to] = changes[i];
                        if (typeof from === typeof to && from === to) {
                            //Do noting if no change is detected at all
                        } else {
                            let pgridDataBuffer = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `beforeChange` });
                            let pcell = PGridUtils.getPGridCell(pgridDataBuffer, y, x);
                            if (PGridUtils.ConsiderDirtyGridFlag(pcell)) {
                                await PGridUtils.DirtyGridFlag(vuexStore, `afterChange()`); //Required for enable savbe button using checkboxes
                            }
                        }

                    }
                }
            }
        }

        window.PGridClientDebugMode >= 2 && console.debug(`afterChange: changes length: ${noOfChanges}`);


        PGridUtils.PGridTimerStart('afterChange:setDataAtCell');


        if (vuexStore.state.pgridSettings.DisableEvents) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: events disabled`);
            return;
        }

        if (vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes > 0) {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: setDataAtCell events disabled (DisableEventsSetDataAtCellNTimes: ${vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes})`);
            vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "subtract", val: 1, source: `afterChange` });

            if (vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes == 0) {

                PGridUtils.PGridTimerStop('afterChange:setDataAtCell');
                PGridUtils.PGridTimerStop('beforeChange:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_calc_allOrChanges:setDataAtCell');
                PGridUtils.PGridTimerStop('Action_HOT_ApplyGridChange:setDataAtCell');
                PGridUtils.PGridTimerPrint();
                PGridUtils.PGridTimerReset();
            }

            return;
        }

        try {
            window.PGridClientDebugMode >= 3 && console.debug(`afterChange: setDataAtCell events WILL call handleOrDelayAfterChange (DisableEventsSetDataAtCellNTimes: ${vuexStore.state.pgridSettings.DisableEventsSetDataAtCellNTimes}) number of changes: ${noOfChanges}`);

            await PGridUtils.handleOrDelayAfterChange(vuexStore, changes, `afterChange()`);
        } catch (err) {
            let errMsg = `> hot afterChange() got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }
    },

    cells: function (row, col, prop) {

        if (isNaN(row) || row === -1) {
            return; //(caused by the window.PGridPerformanceMode optimization?)
        }

        if(row == 3 && col == 5){
            let foo="bar flakdsj flkadsj flkj adslökfj löasdkjflkadsjflökasdj";
        }

        let renderer = null;
        let editor = PGridCellEditorHandsontableEditor;
        let inlineStyle = null;

        let cellProperties = {};

        try {

            if (PGridUtils.Is_DynamicMode(vuexStore.state)) {



                let pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `pgridHT cells() event` });
                if (pgridData != null && row < pgridData.length) {

                    let selCell = null;
                    selCell = pgridData[row][col]
                    if (selCell) {

                        let writable = false;
                        let readOnly = undefined;
                        let limitStrLenBy = null;
                        let newLineOnEnter = null;
                        let doubleClickLink = null;
                        let singleClickLink = null;
                        let validatorType = null;
                        let validatorRegEx = null;
                        let validatorAllowEmpty = true;
                        let formatAddClasses = null;
                        let formatRemoveClasses = null;
                        let rendererOptions = null;
                        let rendererOptionsValues = null;

                        if (false) {
                            if (lodash_has(selCell, "Meta")) {
                                writable = lodash_get(selCell, "Meta.Save.Writable", false);
                                readOnly = lodash_get(selCell, "Meta.Save.ReadOnly", false);
                                limitStrLenBy = lodash_get(selCell, "Meta.CellRender.LimitStrLenBy", null);
                                newLineOnEnter = lodash_get(selCell, "Meta.CellEditor.NewLineOnEnter", null);
                                doubleClickLink = lodash_get(selCell, "Meta.DoubleClickLink", null);
                                validatorType = lodash_get(selCell, "Meta.Validator.Type", null);
                                validatorRegEx = lodash_get(selCell, "Meta.Validator.RegEx", null);
                                validatorAllowEmpty = lodash_get(selCell, "Meta.Validator.AllowEmpty", true);
                                formatAddClasses = lodash_get(selCell, "Meta.Format.AddClasses", null);
                                formatRemoveClasses = lodash_get(selCell, "Meta.Format.RemoveClasses", null);
                            }
                        } else {
                            if ("Meta" in selCell) {

                                if ("Save" in selCell.Meta) {

                                    if ("Writable" in selCell.Meta.Save) {
                                        writable = selCell.Meta.Save.Writable;
                                    }
                                    else {
                                        readOnly = true;


                                    }

                                    if (readOnly != true) {
                                        if ("ReadOnly" in selCell.Meta.Save) {

                                            if (vuexStore.state.pgridSettings.isCustomerAdmin && vuexStore.state.pgridSettings.PGridTableEditMode) {
                                                //Remove read only
                                                readOnly = false;
                                            } else {
                                                readOnly = selCell.Meta.Save.ReadOnly;
                                            }
                                        }
                                    }


                                }

                                if ("CellRender" in selCell.Meta) {

                                    if ("LimitStrLenBy" in selCell.Meta.CellRender) {
                                        limitStrLenBy = selCell.Meta.CellRender.LimitStrLenBy;
                                    }
                                    // limitStrLenBy = lodash_get(selCell, "Meta.CellRender.LimitStrLenBy", null);
                                }


                                if ("CellEditor" in selCell.Meta) {
                                    if ("NewLineOnEnter" in selCell.Meta.CellEditor) {
                                        newLineOnEnter = selCell.Meta.CellEditor.ReadOnly;
                                    }
                                    // newLineOnEnter = lodash_get(selCell, "Meta.CellEditor.NewLineOnEnter", null);
                                }

                                if ("DoubleClickLink" in selCell.Meta) {
                                    doubleClickLink = selCell.Meta.DoubleClickLink;
                                }
                                if ("SingleClickLink" in selCell.Meta) {
                                    singleClickLink = selCell.Meta.SingleClickLink;
                                }
                                // doubleClickLink = lodash_get(selCell, "Meta.DoubleClickLink", null);

                                if ("Validator" in selCell.Meta) {

                                    if ("Type" in selCell.Meta.Validator) {
                                        validatorType = selCell.Meta.Validator.Type;
                                    }
                                    if ("RendererOptions" in selCell.Meta.Validator) {
                                        rendererOptions = selCell.Meta.Validator.RendererOptions.map(x => x.Caption);
                                        rendererOptionsValues = selCell.Meta.Validator.RendererOptions.map(x => x.Value);
                                    }
                                    // validatorType = lodash_get(selCell, "Meta.Validator.Type", null);

                                    if ("RegEx" in selCell.Meta.Validator) {
                                        validatorRegEx = selCell.Meta.Validator.RegEx;
                                    }
                                    // validatorRegEx = lodash_get(selCell, "Meta.Validator.RegEx", null);

                                    if ("AllowEmpty" in selCell.Meta.Validator) {
                                        validatorAllowEmpty = selCell.Meta.Validator.AllowEmpty;
                                    }
                                    // validatorAllowEmpty = lodash_get(selCell, "Meta.Validator.AllowEmpty", true);
                                }



                                if ("Format" in selCell.Meta) {
                                    if ("AddClasses" in selCell.Meta.Format) {
                                        formatAddClasses = selCell.Meta.Format.AddClasses;
                                    }
                                    // formatAddClasses = lodash_get(selCell, "Meta.Format.AddClasses", null);

                                    if ("RemoveClasses" in selCell.Meta.Format) {
                                        formatRemoveClasses = selCell.Meta.Format.RemoveClasses;
                                    }
                                }

                                if ("Style" in selCell.Meta) {
                                    inlineStyle = selCell.Meta.Style;
                                }
                            }
                        }

                        if (limitStrLenBy != null) {
                            cellProperties.LimitStrLenBy = limitStrLenBy;
                        }

                        if (doubleClickLink) {
                            cellProperties.DoubleClickLink = true;
                        }

                        if (singleClickLink) {
                            cellProperties.SingleClickLink = true;
                        }

                        if (newLineOnEnter) {
                            cellProperties.NewLineOnEnter = true;
                        }

                        if (inlineStyle) {
                            cellProperties.InlineStyle = inlineStyle
                        }


                        if (readOnly === true) {
                            cellProperties.readOnly = true;

                            selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-readonly");
                            selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, "pg-writable");
                        }
                        else if (writable === true) {
                            if (readOnly === false) {
                                cellProperties.readOnly = false;
                            }
                            selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-writable");
                            selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, "pg-readonly");
                        }

                        if (formatRemoveClasses) {
                            for (let i = 0; i < formatRemoveClasses.length; i++) {
                                let fDelCla = formatRemoveClasses[i];
                                selCell.Format = PGridCell.FormatRemoveClassOf(selCell.Format, fDelCla);
                            }
                        }


                        if (formatAddClasses) {
                            for (let i = 0; i < formatAddClasses.length; i++) {
                                let fAddCla = formatAddClasses[i];
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, fAddCla);
                            }
                        }

                        let doValidation = false;
                        let matchValidation = null;

                        if (validatorType != null) {
                            doValidation = true;

                            if (validatorType == "number") {
                                cellProperties.pgRenderer = 'number'

                                matchValidation = PGridValidators.number;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                // editor = PGridCellEditorBase;
                            }
                            else if (validatorType.indexOf("decimal") == 0) {
                                cellProperties.pgRenderer = 'decimal'
                                matchValidation = PGridValidators.number;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                // editor = PGridCellEditorBase;
                            }
                            else if (validatorType == "integer") {
                                cellProperties.pgRenderer = 'integer'
                                matchValidation = PGridValidators.integer;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                // editor = PGridCellEditorBase;
                            }
                            else if (validatorType == "percent") {
                                cellProperties.pgRenderer = 'percent'

                                //matchValidation = String.raw`^-?\d+([\,\.]\d{1,17})?$`;  //The maximum number of decimals is 17, but floating point arithmetic is not always 100% accurate:

                                //function as a validator
                                matchValidation = PGridValidators.percent;

                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                editor = PGridCellEditorPercent;
                            }
                            else if (validatorType == "perc100") {
                                cellProperties.pgRenderer = 'perc100'

                                //matchValidation = String.raw`^-?\d+([\,\.]\d{1,17})?$`;  //The maximum number of decimals is 17, but floating point arithmetic is not always 100% accurate:

                                //function as a validator
                                matchValidation = PGridValidators.perc100;

                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                editor = PGridCellEditorPerc100;
                            }
                            else if (validatorType == "yesno") {
                                cellProperties.pgRenderer = 'yesno'
                                matchValidation = PGridValidators.yesno;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-center");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pgtype-yesno");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");
                                renderer = PGridCellRendererYesNo;

                                if (rendererOptions) {
                                    cellProperties.source = rendererOptions;
                                }

                                editor = PGridCellEditorYesNo; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "truefalse") {
                                cellProperties.pgRenderer = 'truefalse'
                                matchValidation = PGridValidators.truefalse;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-center");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pgtype-truefalse");
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");
                                renderer = PGridCellRendererYesNo;

                                if (rendererOptions) {
                                    cellProperties.source = rendererOptions;
                                }

                                editor = PGridCellEditorYesNo; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "checkbox") {
                                cellProperties.pgRenderer = 'checkbox'
                                matchValidation = PGridValidators.checkbox;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-center");
                                renderer = PGridCellRendererCheckboxRenderer;

                                if (rendererOptions) {
                                    cellProperties.checkedTemplate = rendererOptions[0];
                                    cellProperties.uncheckedTemplate = rendererOptions[1];
                                }

                                editor = PGridCellEditorCheckbox; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType.indexOf("lookup:") != -1) {
                                cellProperties.pgRenderer = 'lookup'

                                if (readOnly ? true : !writable) {
                                    renderer = PGridCellRenderer; //When read only, use this instead
                                }
                                else {

                                    selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");

                                    matchValidation = PGridValidators.lookup;
                                    renderer = PGridCellRendererLookup;
                                    editor = PGridCellEditorLookup; //Handsontable.renderers.getRenderer("pgridrenderer");

                                    {
                                        let hierarchyId = validatorType.split(":")[1];
                                        if (hierarchyId == null) {
                                            throw new Error(`cells() could not extract hierarchyId from ${validatorType}`);
                                        }
                                        let refTypeAndCoord = PGridUtils.extractRefTypeFromCoord(row, col, vuexStore, `cells()`)
                                        let lookupDSName = lodash_get(refTypeAndCoord, `RefType.Lookups[${hierarchyId}].PivotHierarchy`, null);

                                        if (lookupDSName == null) {
                                            throw new Error(`cells() could not extract lookup datasource name`);
                                        }
                                        // cellProperties.lookupDS = lookupDSName;

                                        // if (!cellProperties.lookupDS) {
                                        //     throw new Error("PGridCellEditorLookup(): Missing: cellProperties.lookupDS")
                                        // }


                                        let lookupSource = PGridUtils.extractCellLookupValuesFromDS(vuexStore, lookupDSName);

                                        if (!(lookupDSName in vuexStore.state.pgridSettings.cellLookups)) {

                                            vuexStore.commit("Mutation_UpdatePGridSettings", {
                                                prop: "cellLookups",
                                                op: "addkey",
                                                key: lookupDSName,
                                                val: lookupSource,
                                                source: `cells()`
                                            });

                                        }

                                        cellProperties.source = vuexStore.state.pgridSettings.cellLookups[lookupDSName];
                                        // cellProperties.source = ["Adam", "Bertil", "Cesar"];

                                    }
                                }
                            } else if (validatorType.indexOf("colfilter") != -1) {
                                cellProperties.pgRenderer = 'colfilter'
                                cellProperties.pgRendererIsFilter = 'colfilter'
                                // cellProperties.filteringCaseSensitive
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-singleclickedit");

                                matchValidation = PGridValidators.colfilter;
                                renderer = PGridCellRendererFilter; //PGridCellRendererLookup;
                                editor = PGridCellEditorFilter; //Handsontable.renderers.getRenderer("pgridrenderer");

                                {

                                    cellProperties.strict = false;
                                    cellProperties.visibleRows = 40;
                                    cellProperties.source = function (query, callback) {



                                        if (this.filter_select_value_or_last_value && query == this.filter_select_value_or_last_value) {
                                            query = '';
                                        }

                                        if (true || window.PGridDebugSTOP) {
                                            let foo = "stop here";
                                            console.log("query: '" + query + "'");
                                        }


                                        // https://stackoverflow.com/questions/1885557/simplest-code-for-array-intersection-in-javascript
                                        // Return elements of array a that are also in b in linear time:
                                        function intersect(a, b) {
                                            return a.filter(Set.prototype.has, new Set(b));
                                        }


                                        let findTableLength = function (y, x, pgridData) {

                                            let found = false;
                                            let serachOffset = 0;
                                            while (found == false) {

                                                if (pgridData.length < y + 1 + serachOffset) {
                                                    console.warn(`Could not find end of table for filter lookup`);
                                                    return 0
                                                }

                                                if (pgridData[y + 1 + serachOffset][x].Format.indexOf("pg-is-lr-border-bottom") != -1) {
                                                    found = true
                                                } else {
                                                    serachOffset++;
                                                }
                                            }

                                            return serachOffset;
                                        }


                                        if (!("filter_value" in this)) {
                                            this.filter_value = null;
                                        }

                                        let foundLength = findTableLength(this.row, this.col, PGridVueStore.state.pgridDataDynContent);

                                        let uniqueValues_OnRows = {};

                                        let htData = this.instance.getData();
                                        for (let i = 0; i < foundLength; i++) {

                                            let iter_row = this.row + 1 + i;
                                            let iter_val = String(htData[iter_row][this.col]);

                                            if (iter_val in uniqueValues_OnRows) {
                                                uniqueValues_OnRows[iter_val].push(iter_row);
                                            } else {
                                                uniqueValues_OnRows[iter_val] = [iter_row];
                                            }
                                        }


                                        let filter_UniqueValues_OnRows = uniqueValues_OnRows;
                                        let filter_UniqueValues_foundLength = foundLength;


                                        let collectOtherFilterRows = function (instance, colFilterRows, foundLength) {

                                            let includeRows_bool = [];

                                            if (colFilterRows) {

                                                includeRows_bool = Array(foundLength).fill(false);
                                                for (let fCell in colFilterRows) {

                                                    if (colFilterRows[fCell]) {


                                                        let fCellMeta = instance.getCellMeta(fCell.split(':')[0], fCell.split(':')[1]);

                                                        let fselectionMulti = fCellMeta['filter_UniqueValues_selected'];

                                                        if (fselectionMulti == null) {
                                                            console.debug('fselectionMulti == null. current state not "clean"');
                                                        } else {

                                                            for (let i = 0; i < fselectionMulti.length; i++) {
                                                                let fselectionSingle = fselectionMulti[i];

                                                                let fSingleValidRows = fCellMeta['filter_UniqueValues_OnRows'][fselectionSingle];

                                                                if (fSingleValidRows) {
                                                                    let fSingleValidRows_len = fSingleValidRows.length;
                                                                    for (let j = 0; j < fSingleValidRows_len; j++) {
                                                                        includeRows_bool[fSingleValidRows[j]] = true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            let includeRows = [];

                                            if (includeRows_bool.length > 0)
                                                for (let i = 0; i < foundLength; i++) {
                                                    if (includeRows_bool[i]) {
                                                        includeRows.push(i);
                                                    }
                                                }

                                            return includeRows;
                                        }

                                        /*  filter next row wen other filters are used . lookup using window.colFilterRows -> filter_UniqueValues_OnRows[...filter_UniqueValues_selected] */


                                        let choices = Object.keys(filter_UniqueValues_OnRows);

                                        let choice_filtered_by_other_filters = [];

                                        let okRowsByOtherfilters = collectOtherFilterRows(this.instance, window.colFilterRows, foundLength);
                                        if (okRowsByOtherfilters.length > 0) {
                                            let choices_len = choices.length;
                                            for (let i = 0; i < choices_len; i++) {
                                                let myChoice = choices[i];
                                                let myChoice_rows = filter_UniqueValues_OnRows[myChoice];

                                                let inter = intersect(myChoice_rows, okRowsByOtherfilters);

                                                if (inter == null) {
                                                    let foo = "fjldskfjds";
                                                }

                                                if (inter.length > 0) {
                                                    choice_filtered_by_other_filters.push(myChoice);
                                                }
                                            }
                                        } else {
                                            choice_filtered_by_other_filters = choices;
                                        }

                                        let choice_filtered_by_query = null;

                                        if (query != '') {
                                            if (cellProperties.filteringCaseSensitive) {
                                                choice_filtered_by_query = choice_filtered_by_other_filters.filter(x => x.includes(query));
                                            } else {
                                                choice_filtered_by_query = choice_filtered_by_other_filters.filter(x => x.toLowerCase().includes(query.toLowerCase()));
                                            }
                                        }
                                        else {
                                            choice_filtered_by_query = choice_filtered_by_other_filters;
                                        }
                                        if (choice_filtered_by_query == null || choice_filtered_by_query.length == 0) {
                                            choice_filtered_by_query = [];
                                        }


                                        this.instance.setCellMeta(this.row, this.col, "filter_UniqueValues_OnRows", uniqueValues_OnRows);
                                        this.instance.setCellMeta(this.row, this.col, "filter_UniqueValues_foundLength", filter_UniqueValues_foundLength);

                                        // return choices;

                                        callback.call(this, choice_filtered_by_query);

                                    }; //vuexStore.state.pgridSettings.cellLookups[lookupDSName];
                                    // cellProperties.source = ["Adam", "Bertil", "Cesar"];

                                }

                            }
                            else if (validatorType == "text") {
                                cellProperties.pgRenderer = 'text'
                                matchValidation = PGridValidators.text;
                                doValidation = false;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "json") {
                                cellProperties.pgRenderer = 'json'
                                matchValidation = PGridValidators.json;
                                doValidation = true;
                                selCell.Format = PGridCell.FormatAddClassOf(selCell.Format, "pg-overflow-ellipsis");
                                renderer = PGridCellRendererJson; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "date") {
                                cellProperties.pgRenderer = 'date'
                                matchValidation = PGridValidators.date;
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                            }
                            else if (validatorType == "apexcharts") {
                                cellProperties.pgRenderer = 'apexcharts'
                                renderer = PGridCellRenderer; //Handsontable.renderers.getRenderer("pgridrenderer");
                                doValidation = false; //override as this needs no validation
                            }
                        }

                        //Cell custom regex validator overrides
                        if (validatorRegEx != null) {
                            doValidation = true;
                            matchValidation = validatorRegEx;
                        }

                        //Dont validate non writable cells
                        if (writable == false) {
                            doValidation = false;
                        }

                        if (doValidation) {

                            if ((typeof matchValidation) === 'string') {
                                if (validatorAllowEmpty) {
                                    matchValidation = String.raw`^undefined$|^null$|^$|` + matchValidation;
                                }
                                cellProperties.validator = new RegExp(matchValidation);
                            } else if ((typeof matchValidation) == 'function') {
                                cellProperties.validator = matchValidation;
                            }
                        }


                    }
                }
            }

        } catch (err) {
            let errMsg = `pgridHT.js cells( ${row}, ${col} ) got exception: ${err.message || err}\n\nStack:${err.stack}`;
            console.warn(errMsg);
        }

        if (editor) {
            cellProperties.editor = editor;
        }


        if (!("editor" in cellProperties)) {
            // cellProperties.editor = Handsontable.editors.getEditor("text"); //This helped keyboard inputs in dropdowns to not crash after edit
        }

        if (renderer) {
            cellProperties.renderer = renderer;
        }
        else if (!("renderer" in cellProperties)) {
            cellProperties.renderer = PGridCellRenderer;
        }

        return cellProperties
    },

    afterOnCellMouseDown: async function (event, coords, td) {
        window.PGridClientDebugMode >= 3 && console.debug(`afterOnCellMouseDown() event`, event)


        let detectedDblClick = false;
        let selectedHotCell_single = null;

        let cellAction = null; // only 'link' for now
        let cellAction_linkUrl = null;
        let cellAction_linkNewTab = true;
        let cellAction_linkFocus = true;


        var now = new Date().getTime();
        // check if dbl-clicked within 1/5th of a second. change 200 (milliseconds) to other value if you want
        if (!(td.lastClick && now - td.lastClick < DBL_CLICK_DEBUG_PANE_MS)) {
            td.lastClick = now;
            //  return; // no double-click detected
        } else {
            detectedDblClick = true;
        }



        // if (detectedDblClick) {

        window.PGridClientDebugMode >= 2 && console.debug(`afterOnCellMouseDown() detected double click`);

        let selectedHotCells = vuexStore.state.hotRef.getSelected();

        if (selectedHotCells.length === 1) { //As it may also be a multi select

            if (selectedHotCells[0][0] === selectedHotCells[0][2]
                &&
                selectedHotCells[0][1] === selectedHotCells[0][3]
                &&
                coords.row === selectedHotCells[0][0]
                &&
                coords.col === selectedHotCells[0][1]
            ) {
                selectedHotCell_single = [selectedHotCells[0][0], selectedHotCells[0][1]];
            }

        }


        // }
        // else{
        //     //await PGridUtils.handleOrDelayAfterChange_Clear(vuexStore, null, `afterOnCellMouseDown()`); //Old relic?
        // }


        if (selectedHotCell_single) {


            let pgridData = pgridData = PGridUtils.Get_CurrentPGridData({ context: vuexStore, source: `afterOnCellMouseDown() event` });
            if (pgridData != null) {

                let pcell_selected = pgridData[selectedHotCell_single[0]][selectedHotCell_single[1]];

                if (detectedDblClick) {

                    let pcellMetaDblClick = lodash_get(pcell_selected, "Meta.DoubleClickLink", null);


                    if (pcellMetaDblClick != null) {

                        cellAction = 'link';

                        // // new URL() https://dmitripavlutin.com/parse-url-javascript/
                        let gotoUrl = new URL(pcellMetaDblClick, window.location.href);

                        if (gotoUrl.search == null) {
                            console.warn(`[onCellDblClick] link: ${pcellMetaDblClick} gotoUrl.search == null`);
                        } else {

                            cellAction_linkUrl = gotoUrl.href;

                        }
                    }
                }

                let pcellMetaSingClick = lodash_get(pcell_selected, "Meta.SingleClickLink", null);

                if (pcellMetaSingClick != null) {

                    cellAction = 'link';

                    // // new URL() https://dmitripavlutin.com/parse-url-javascript/
                    let gotoUrl = new URL(pcellMetaSingClick, window.location.href);

                    if (gotoUrl.search == null) {
                        console.warn(`[onCellSingClick] link: ${pcellMetaSingClick} gotoUrl.search == null`);
                    } else {
                        cellAction_linkUrl = gotoUrl.href;
                    }
                }

            }
        }



        //////  Take action

        if (cellAction == 'link') {

            window.PGridClientDebugMode >= 2 && console.debug(`afterOnCellMouseDown() cellAction == 'link'`);

            PGridUtils.SaveScrollAndCellPositionForCurrentPage(vuexStore, selectedHotCell_single);


            let cellAction_linkUrl_URL = new URL(cellAction_linkUrl);

            let isSameSite = PGridUtils.IsSameUrlHost(window.location.href, cellAction_linkUrl_URL.href);

            if (isSameSite) {

                PGridUtils.HistoryPushStateIfNotSame(cellAction_linkUrl, `afterOnCellMouseDown()`);

                if (false) { //As it gave double confirmations
                    if (PGridUtils.ConfirmNavigation(vuexStore, `afterOnCelMouseDown: gotoUrl: ${gotoUrl}`, "Vill du ändå följa länken?")) {
                        if (false) {
                            PGridUtils.updateTabLinks(cellAction_linkUrl, `onCellDblClick()`);
                            //Reaload tablinks

                            await PGridUtils.sleep(100);
                            window.PGridClientDebugMode >= 3 && console.debug("Now call for loading of the new tab")
                            await PGridUtils.ReOrLoadInitialCustomerTabKey();
                        }
                        // window.location.href = gotoUrl.href ;

                        PGridUtils.HandlePlannicaGridUrlUpdates(gotoUrl.href);
                        // await PGridUtils.clickTabOrLinkEvent(gotoUrl.href);
                    }
                }

                PGridUtils.HandlePlannicaGridUrlUpdates(cellAction_linkUrl_URL.href);

                setTimeout(() => {
                    Deselect({ source: `afterSelection() same site` });
                }, 400);

            } else {

                let openLinkInNewTab = function (urlToOpen) {
                    // open in new tab
                    // window.location.href = gotoUrl.href;
                    try {
                        var win = window.open(urlToOpen, '_blank');
                        if (win) {

                            win.focus();

                            setTimeout(() => {
                                Deselect({ source: `afterSelection() new tab` });
                            }, 200);
                        }
                    } catch (err) {
                        console.warn(`[onCellDblClick] link: ${urlToOpen} winwos.open got exception: ${err.message}`);
                    }
                }


                //Async open link
                setTimeout(() => {
                    openLinkInNewTab(cellAction_linkUrl_URL.href);
                }, 100);



            }

        }
        else {

            //No link, check if PGridTableEditMode is enable, then open edit panel
            if (vuexStore.state.pgridSettings.PGridTableEditMode) {
                //Open side debug panel
                if (detectedDblClick && vuexStore.state.pgridSettings.showPanelOnSelect && !vuexStore.state.pgridSettings.showPanel) {
                    await vuexStore.dispatch("Action_UpdateDebugPanel", { newShowPanel: true, source: `afterOnCellMouseDown()` });
                }
            }

        }




        window.PGridClientDebugMode >= 3 && console.debug(`afterOnCellMouseDown() event end`)
    }
}


function log_events(event, data) {
    console.log("FLUX: event: " + event);

}

export async function Init({ source }) {

    const exStart = new Date();

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Init() source: ${source}`);
    document.body.classList.add('app-body');
    let appBody = document.getElementsByClassName('app-body')[0];
    PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
    if (PGridUtils.mobileCheck()) {
        appBody.classList.add("scrollmode-boundary");
    } else {
        appBody.classList.add("scrollmode-content");
    }


    // Action_PreProcessCellStyles(vuexStore, "pgridHT.Init()");
    // await vuexStore.dispatch("Action_PreProcessCellStyles", { source: `${source} -> pgridHT.Init()` });

    let state = vuexStore.state;

    window.PGridClientDebugMode >= 2 && console.debug('pgridDataDynContent:pgridDataHT:Init #3', state.hotSettings.data.length);

    let pgridOptions = {
        // 'wordWrap': false,
        'data': state.hotSettings.data,
        //  'autoColumnSize': { 'syncLimit': 300 },
        //  'autoRowSize': { 'syncLimit': 300 },
        // 'autoColumnSize': { 'syncLimit': '50%' },  //Does not work for merge cells?
        // 'autoRowSize': { 'syncLimit': '30%' },  //Does not work for merge cells?
        // 'autoRowSize': { 'syncLimit': 0 },
        //   'autoRowSize': { 'syncLimit': 0 },
        'autoRowSize': state.hotSettings.autoSize[0],
        'autoColumnSize': state.hotSettings.autoSize[1],
        // 'autoRowSize': false,
        // 'autoColumnSize': false,
        // 'colWidths': [50, 106, 87, 101, 87, 141, 92, 122, 116, 145, 120, 200, 110, 65, 65, 65, 65, 65, 171, 50, 50],
        'outsideClickDeselects': false,
        'viewportRowRenderingOffset': lodash_get(state.hotSettings, "viewportRenderingOffset[0]", 'auto') == 'auto' ? 'auto' : 65536, //FUB If this is low, some table changes as not detected //40 was giving some visual and hotRef.getCell(y, x, true) --> undefined in some cases  //'auto', //120,
        'viewportColumnRenderingOffset': lodash_get(state.hotSettings, "viewportRenderingOffset[1]", 65536) == 65536 ? 65536 : 'auto', //state.hotSettings.viewportRenderingOffset[1], //FUB, see below  //130 * 1000,
        // 'viewportColumnRenderingOffset': 65536, //FUB, see below  //130 * 1000,
        // 'viewportRowRenderingOffset': 65536, //FUB If this is         'autoWrapRow': false,
        'autoWrapRow': false,
        'autoWrapCol': false,
        'mergeCells': state.pgridSettings.PGridTableShowHiddenMode ? null : state.hotSettings.spanCells,
        // 'hideColumns': [{ col: 2 }, { col: 5 }],
        'hideColumns': state.pgridSettings.PGridTableShowHiddenMode ? null : state.pgridSettings.LRPGridSettingsCollection,
        'rowHeaderWidth': state.hotSettings.rowHeaderWidth || 5 + parseInt(String(state.hotSettings.data.length).length * 8.2) + 5, //Caluclates a apporoximation 5px margin + 9px for every digit.
        'colFilterRows': true,
        'afterSelection': HOTEventHandlers.afterSelection,
        'beforeChange': HOTEventHandlers.beforeChange,
        'afterBeginEditing': HOTEventHandlers.afterBeginEditing,
        'afterChange': HOTEventHandlers.afterChange,
        'renderer': HOTEventHandlers.renderer,
        'cells': HOTEventHandlers.cells,
        'afterOnCellMouseDown': HOTEventHandlers.afterOnCellMouseDown,
        'comments': true,
        'contextMenu': state.pgridSettings.contextMenu, //['row_below' /*, 'remove_row'*/],
        // 'contextMenu': ['row_below' /*, 'remove_row'*/],

        'rowHeaders': !state.pgridSettings.headers_Hidden,
        'colHeaders': !state.pgridSettings.headers_Hidden,
        'scrollbarHeight': state.pgridSettings.scrollbarSizeHorizontal,
        'scrollbarWidth': state.pgridSettings.scrollbarSizeVertical,
        'disableVisualSelection': state.hotSettings.disableVisualSelection,
        'numClearsToZero': state.pgridSettings.numClearsToZero,

        // 'afterDeselect': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: afterDeselect() start");
        // },
        // // 'afterDeselect': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        // //     window.PGridClientDebugMode >= 2 && console.debug("afterDeselect() start");
        // // },
        // 'beforeOnCellMouseOut': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: beforeOnCellMouseOut() start");
        // },
        // 'beforeInitWalkontable': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: beforeInitWalkontable() start");
        // },
        // 'afterOnCellMouseUp': async (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        //     window.PGridClientDebugMode >= 2 && console.debug("FLUX: afterOnCellMouseUp() start");
        // },
        // ,'renderAllRows': false,
    }

    window.PGridScrollFactorY = state.hotSettings.pgScrollFactorY;
    window.PGridScrollFactorX = state.hotSettings.pgScrollFactorX;
    window.PGridScrollScrollWaitMS = state.hotSettings.pgScrollScrollWaitMS;


    state.hotSettings.LRHotSettings.forEach(o => {
        window.PGridClientDebugMode >= 2 && console.debug(`pgridHT.Init() Adding HOT settings from LR's: ${JSON.stringify(o)}`);
        pgridOptions = lodash_merge(pgridOptions, o);
    });


    window.PGridClientDebugMode >= 3 && console.debug("pgridHT.Init() Creating Handsontable instance. source: " + source);
    var container = document.getElementById('pgrid-hot');

    vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', op: "add", val: 1, source: `pgridHT.Init()` });
    //TEST vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 99999, source: `pgridHT.Init()` });


    // PGridUtils.makeNonreactive2(container);
    // PGridUtils.makeNonreactive2(pgridOptions);
    makeNonreactive2(container);
    makeNonreactive2(pgridOptions);

    var hotRefWrapper = new Handsontable(container,
        pgridOptions
    );

    // PGridUtils.makeNonreactive2(hotRefWrapper);
    makeNonreactive2(hotRefWrapper);

    window.PGridClientDebugMode >= 2 && console.debug('PGridVueStore.state.hotRef.getData().length #1', hotRefWrapper.getData().length);

    /*
        pgridOptions.data = [
            ["", "Ford", "Tesla", "Toyota", "Honda", "Ford", "Tesla", "Toyota", "Honda", "Ford", "Tesla", "Toyota", "Honda", "Ford", "Tesla", "Toyota", "Honda", "Ford", "Tesla", "Toyota", "Honda"],
            ["2017", 10, 11, 12, 13, 10, 11, 12, 13, 10, 11, 12, 13, 10, 11, 12, 13, 10, 11, 12, 13],
            ["2018", 20, 11, 14, 13, 20, 11, 14, 13, 20, 11, 14, 13, 20, 11, 14, 13, 20, 11, 14, 13],
            ["2019", 30, 15, 12, 13, 30, 15, 12, 13, 30, 15, 12, 13, 30, 15, 12, 13, 30, 15, 12, 13]
        ];
      console.debug(pgridOptions);
        var hotRefWrapper = new Handsontable(container,
            pgridOptions);
    */



    if (window.PGridPerformanceMode) {

        if (!Handsontable.hooks.has("beforeChange")) {

            Handsontable.hooks.add("beforeChange", function (changes, source) {

                if (!changes) {
                    window.PGridClientDebugMode >= 2 && console.debug(`PerformanceModeDEBUG: Handsontable.hooks.add("beforeChange") changes.length: ${changes.length}`);
                    return;
                }

                if (PGridVueStore.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                    for (let i = 0; i < changes.length; i++) {
                        if (`overlay_postcalc:${changes[i][0]}:${changes[i][1]}` in PGridVueStore.state.pgridSettings.cellsThatAreReferencingLinkedRangeOverlays) {
                            if (changes[i][2] !== changes[i][3]) {
                                //Force all cells to render if a overlay_postcalc cell has changee (FUB). Should insted keep track of cells to render...
                                // delete this.lastChanges;
                                // this.lastChanges = null;
                                window.lastChanges = null;
                                vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'lastChangesLocked', val: true, source: `pgridHT.Init()` });
                                return;
                            }
                        }
                    }
                }


                if (true || PGridVueStore.state.pgridSettings.lastChangesLocked) { //this prevents an aftercalc event to owervrite an overlay_postcalc "delete this.lastChanges", which should force a total rendering
                    //Don´t alter
                    // window.lastChanges = null;
                }
                else {
                    // this.lastChanges = changes;
                    window.lastChanges = changes;
                }
                // console.log("afterChanges: " + JSON.stringify(changes));
            });
        }
    }



    vuexStore.commit('Mutation_UpdateRoot', { prop: 'hotRef', val: hotRefWrapper, source: `${source} -> pgridHT.Init()` });

    //This will avoid afterChange event handling after init, but not after init
    // vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 1, source: `pgridHT.Init()` });


    /*
    Called by events afterScrollHorizontally, afterScrollHorizontally and "cellAction == 'link'"
    */

    function handleScrollChange() {
        // if (window.PGridPerformanceMode) {
        let foo = "424324 23432";
        // delete this.lastChanges;
        // this.lastChanges = null;
        // }
        PGridUtils.SaveScrollAndCellPositionForCurrentPage(vuexStore);
    }

    if (!Handsontable.hooks.has("afterScrollHorizontally")) {
        Handsontable.hooks.add("afterScrollVertically", handleScrollChange);
        Handsontable.hooks.add("afterScrollHorizontally", handleScrollChange);
    }

    (function () {
        var beforePrint = function () {

            window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js beforePrint()");

            let appBody = document.getElementsByClassName('app-body')[0];

            PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
            appBody.classList.add("scrollmode-boundary");
            appBody.classList.add("printingmode");

            if (vuexStore.state.hotRef != null) {
                window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js beforePrint() hotRef.render()");

                vuexStore.state.hotRef.render();
            }
        };
        var afterPrint = function () {
            window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js afterPrint()");

            let appBody = document.getElementsByClassName('app-body')[0];

            PGridUtils.removeClassesByPrefix(appBody, "scrollmode-");
            PGridUtils.removeClassesByPrefix(appBody, "printingmode");
            if (PGridUtils.mobileCheck()) {
                appBody.classList.add("scrollmode-boundary");
            } else {
                document.body.classList.add("scrollmode-content");
            }


            if (vuexStore.state.hotRef != null) {
                window.PGridClientDebugMode >= 2 && console.debug("pgridHT.js afterPrint() hotRef.render()");

                vuexStore.state.hotRef.render();
            }

        };

        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    }());


    function GetScollbackMemory({ url, source = null }) {

        window.PGridClientDebugMode >= 2 && console.debug(`Vuex Action: Action_GetScollbackMemory: : ${url} got called from: ${source} `);

        let scollbackMemory = vuexStore.state.pgridSettings.scollbackMemory;
        let searchUrlQSAll = url.split("&");

        for (let scollbackMemory_itemKey of Object.keys(scollbackMemory)) {

            let foundAllIncommingInMemoryItem = true;

            let scollbackMemory_itemUrlQS = scollbackMemory_itemKey.split("&");

            for (let searchUrlQS of searchUrlQSAll) {
                if (scollbackMemory_itemUrlQS.indexOf(searchUrlQS) != -1) {
                    //Found, still OK
                } else {
                    //Not found, not OK
                    foundAllIncommingInMemoryItem = false;
                }
            }

            if (foundAllIncommingInMemoryItem) {
                return scollbackMemory[scollbackMemory_itemKey];
            }
        }

        return null;
    }

    let foundSavesScroll = GetScollbackMemory({ url: `${location.search}`, source: `${source} -> Init()` });

    if (foundSavesScroll) {
        window.PGridClientDebugMode >= 3 && console.debug(`Found existing currentSCROLLBACKTOpath in sessionStorage`);


        window.PGridClientDebugMode >= 1 && console.debug(`PGridVueStore.state.hotRef.scrollViewportTo(${foundSavesScroll.row}, ${foundSavesScroll.column}, false, false)`);

        // vuexStore.state.hotRef.scrollViewportTo(foundSavesScroll.row, foundSavesScroll.column, false, false);

        setTimeout(async () => {
            await PGridUtils.sleep(1);
            if (window.PGridVueStore.state.hotRef != null) {

                vuexStore.state.hotRef.scrollViewportTo(foundSavesScroll.row, foundSavesScroll.column, false, false);
                setTimeout(async () => {
                    await PGridUtils.sleep(1);
                    // vuexStore.state.hotRef.render();

                    // window.commingFromscrollViewportTo = true;

                    if (foundSavesScroll.clickedCell) {
                        setTimeout(async function () { //Delay needed to not5 interfere with scroll restore
                            await PGridUtils.sleep(1);
                            vuexStore.state.hotRef.selectCell(foundSavesScroll.clickedCell.y, foundSavesScroll.clickedCell.x);
                        }, 0);
                    }

                }, 0);

            }
        }, 0);


    }



    let exTimeMS = (new Date().getTime() - exStart.getTime());

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.js Init(): took ${exTimeMS} ms`);

    let currentLocationUrl = new URL(window.location.href);

    PGridUtils.updateTabLinks((currentLocationUrl.pathname + currentLocationUrl.search), `${source} -> Init()`);

    if (Array.isArray(vuexStore.state.hotSettings.LRHotSettings) && vuexStore.state.hotSettings.LRHotSettings.find(x => "fixedRowsTop" in x && x.fixedRowsTop)) {
        vuexStore.state.hotRef.render(); //Force an extra render, to fix top grid in fixedRowsTop to rendering correctly //FUB
    }
    return;
}


export function Destroy({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Destroy() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.destroy();
        // Handsontable.hooks.destroy();
        state.hotRef = null;
    }
}

export function Clear({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Clear() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.clear();
    }
}


export function Deselect({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Deselect() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        state.hotRef.deselectCell();
    }
}

export function Update({ source }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.Update() source: ${source}`);

    let state = vuexStore.state;

    if (state.hotRef != null) {
        console.debug(`pgridHT.Update() data source: ${source}`);

        vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 1, source: `pgridHT.Init()` });

        Destroy({ source: `${source} > pgridHT.js Update()` });
        Init({ source: `${source} > pgridHT.js Update()` });

        //TEST vuexStore.commit('Mutation_UpdatePGridSettings', { prop: 'DisableEventsSetDataAtCellNTimes', val: 0, source: `pgridHT.Init()` });
    }
    else {
        console.warn(`pgridHT.Update() hotRef is null? source: ${source}`);
    }
}


export function ReConfig(context, { configUpdate = null, source = "unknown source for ReConfig()" }) {

    window.PGridClientDebugMode >= 3 && console.debug(`pgridHT.ReConfig() source: ${source}`);

    let state = context.state;

    if (state.hotRef != null) {
        state.hotRef.updateSettings(configUpdate);
    }
    else {
        console.warn(`pgridHT.ReConfig() hotRef is null? source: ${source}`);
    }
}


export function Redraw(context, opt = { source, force: false }) {
    window.PGridClientDebugMode >= 1 && console.debug(`pgridHT.Redraw() source: ${opt.source} -> pgridHT.Redraw()`);
    // if (context.state.hotRef == null) {
    //     // Init({ source: `${source} -> pgridHT.Redraw()` });
    // } else {

    if (!("force" in opt)) {
        opt.force = false;
    }


    setTimeout(async () => {
        await PGridUtils.sleep(25);
        if (window.PGridVueStore.state.hotRef != null) {

            if (window.PGridVueStore.state.secondRenderRequest == 0) {
                window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "secondRenderRequest", val: 1, source: `${opt.source} -> pgridHT.Redraw()` });
            }

            // window.PGridVueStore.state.hotRef.render();

            if (opt.force) {
                // window.PGridClientDebugMode >= 1 && console.debug(`pgridHT.Redraw() force hotRef.render() source: ${opt.source}`);
                // setTimeout(() => {
                window.PGridVueStore.state.hotRef.render();

                // window.PGridVueStore.state.hotRef.render();
                // }, 100);
                window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "secondRenderRequest", val: 0, source: `${opt.source} -> pgridHT.Redraw()` });

            }
            else {
                if (true || window.PGridVueStore.state.secondRenderRequest == 2 /*|| window.PGridVueStore.state.secondRenderRequest == 1*/) {

                    /* Re-render only needed if last cell was above a fixedRowsTop */
                    let lastSelRow = null;

                    if (window.PGridVueStore.state.hotRef && window.PGridVueStore.state.lastSelected && window.PGridVueStore.state.lastSelected != "") {
                        let { y, x } = JSON.parse(window.PGridVueStore.state.lastSelected);
                        lastSelRow = y;
                    }
                    if (lastSelRow != null) {

                        if (lastSelRow < lodash_get(window.PGridVueStore.state.hotSettings, "LRHotSettings[0].fixedRowsTop", 0)) {

                            window.PGridClientDebugMode >= 1 && console.debug(`pgridHT.Redraw() secondRenderRequest triggered 2nd hot.render() source: ${opt.source} -> pgridHT.Redraw()`);
                            window.PGridVueStore.state.hotRef.render();

                        }
                    }

                    if (window.PGridVueStore.state.secondRenderRequest == 2) {
                        window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "secondRenderRequest", val: 0, source: `${opt.source} -> pgridHT.Redraw()` });
                    }
                }

            }


            if ((window.PGridVueStore.state.pgridSettings.filterList_RowsMode != 'filterrows0' /*&& window.PGridVueStore.state.pgridSettings.filterList_RowsMode != 'filterrows1'*/) && window.PGridVueStore.state.secondRenderRequest == 1) { //FUB: Is a redraw acrually required when filter row number (or size) changes between grids (or required when having span columns)?
                window.PGridClientDebugMode >= 2 && console.debug(`pgridHT.Redraw(): Forcing extra redraw as filter bar has multiple rows (and might hide the scrollbar)`);
                window.PGridVueStore.state.hotRef.render();
            }


            // if (context.state.pgridSettings.isLoadingUntilRedraw) {
            if (window.PGridVueStore.state.secondRenderRequest == 1) {
                if (window.PGridVueStore.state.pgridSettings.isLoading) {
                    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `${opt.source} -> pgridHT.Redraw()` });
                }
            }
            // }
        }
    }, 25);

}




const Observer = (new Vue()).$data.__ob__.constructor;

export function makeNonreactive2(obj) {
    if (typeof (obj) === "object") {
        if (obj !== null) { //Not applicable on null object
            obj.__ob__ = new Observer({});
        }
    }
}


export default {
    Init,
    Destroy,
    Clear,
    Update,
    Redraw,
    ReConfig,
    makeNonreactive2,
    HOTEventHandlers
}