import PGridMatrices from './pgridMatrices.js'
import PGridUtils from './pgridUtils.js'
import PGridBackendAccess from './pgridBackendAccess.js'
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash'
import { translateFromKey } from './pgridLanguage.js'

// import PGridCell, { Insert_Calculated_Cell, Get_Val_For_FormulaParser, Get_PGridCell_Value, expandIfNeeded, MaybieCopyObj } from './pgridCell.js'

export async function Handle_FilterMounted(context) {


  try {
    false && console.debug("DEBUG_STEP FILTERS mounted - viewMode: " + context.state.pgridSettings.viewMode);

    /*
     * Apply back button
     */
    if (context.state.HideBackButton == false) {
      setTimeout(() => {
        let backButtonTab = document.querySelectorAll("button.pgBackButton");

        if (backButtonTab.length == 0) {


          let backButton = document.createElement("button");
          backButton.className = "pgBackButton ms-Button stack-inline ms-Button--primary noprint";
          backButton.title = "Backa ett steg";

          backButton.innerHTML =
            '<svg version="1.1" fill="#FFFFFF" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="24" width="24" viewBox="0 0 120.64 122.88" style="enable-background:new 0 0 120.64 122.88" xml:space="preserve"><g><path d="M66.6,108.91c1.55,1.63,2.31,3.74,2.28,5.85c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12c-1.58,1.5-3.6,2.23-5.61,2.2 c-2.01-0.03-4.02-0.82-5.55-2.37C37.5,102.85,20.03,84.9,2.48,67.11c-0.07-0.05-0.13-0.1-0.19-0.16C0.73,65.32-0.03,63.19,0,61.08 c0.03-2.11,0.85-4.21,2.45-5.8l0.27-0.26C20.21,37.47,37.65,19.87,55.17,2.36C56.71,0.82,58.7,0.03,60.71,0 c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76c0.03,2.1-0.73,4.22-2.28,5.85L19.38,61.23L66.6,108.91 L66.6,108.91z M118.37,106.91c1.54,1.62,2.29,3.73,2.26,5.83c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12 c-1.57,1.5-3.6,2.23-5.61,2.21c-2.01-0.03-4.02-0.82-5.55-2.37C89.63,101.2,71.76,84.2,54.24,67.12c-0.07-0.05-0.14-0.11-0.21-0.17 c-1.55-1.63-2.31-3.76-2.28-5.87c0.03-2.11,0.85-4.21,2.45-5.8C71.7,38.33,89.27,21.44,106.8,4.51l0.12-0.13 c1.53-1.54,3.53-2.32,5.54-2.35c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76 c0.03,2.1-0.73,4.22-2.28,5.85L71.17,61.23L118.37,106.91L118.37,106.91z"/></g></svg>';

          backButton.onclick = function (e) {
            // onclick stuff

            let warnUnsavedFacts_BeforeContinue = false;
            if (context.state.pgridSettings.hasUnsavedFactChangesForUrl != null) {
              warnUnsavedFacts_BeforeContinue = confirm("Vill backa utan att spara ändrade värden?");

              if (warnUnsavedFacts_BeforeContinue) {
                context.commit("Mutation_UpdatePGridSettings", {
                  prop: "hasUnsavedFactChangesForUrl",
                  val: null,
                  source: `ConfirmBackNavigation()`,
                });

                window.history.back();
              }
            } else {
              window.history.back();
            }
          };

          let appendHere = document.querySelector("div.pgrid-filter-list");
          if (appendHere) {
            appendHere.insertBefore(backButton, appendHere.firstChild);
          }
        }
      }, 100);
    }

    let initTimestamp = null;
    initTimestamp = Date.now(); //mili seconds
    let initUrl = null;
    let currentUrl = null;
    initUrl = window.location.href;
    let stageFilterRet = null;
    try {
      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Timestamp",
        val: initTimestamp,
        source: `Handle_FilterMounted()`,
      });
      context.commit("Mutation_UpdatePGridSettings", {
        prop: "initOfLastQuery_Url",
        val: initUrl,
        source: `Handle_FilterMounted()`,
      });


      stageFilterRet = await context.dispatch("Action_Stage_Filter", { initTimestamp: initTimestamp, initUrl: initUrl, dontLoadGrid: false, source: `Handle_FilterMounted() 2` });

      //FUB
      currentUrl = window.location.href;

      let foo2432 = "bar";
    } catch (err) {
      console.debug(`Filter fetch problem`, err);

      for (let i = 0; i < context.state.pgridFilter.Filters.length; i++) {
        context.state.pgridFilter.Filters[i].filterIsLoading = false;
      }
      context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_FilterMounted()` });
      context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `Handle_FilterMounted()` });

      return;
    }

    context.commit('Mutation_UpdatePGridSettings', { prop: "IsMissingFilterSelectionCount", val: stageFilterRet.IsMissingFilterSelectionCount, source: `Handle_FilterMounted()` });


    if (context.state.pgridSettings.IsMissingFilterSelectionCount == 0) {

      try {
        await context.dispatch("Action_Stage_Grid", {
          initTimestamp: initTimestamp,
          initUrl: currentUrl, //initUrl,
          render: true,
          source: `Handle_FilterMounted() 3`,
        });
      } catch (err) {
        let prettifyMsg = PGridUtils.beautifyErrorMessage(`${err.stack || err.messge || err}`);

        let errMsg = `Error when loading GRID: \n\n${prettifyMsg}`;
        if (context.state.pgridSettings.initOfLastQuery_Timestamp == initTimestamp) {
          (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `Handle_FilterMounted()` }) : alert(errMsg);
        }
        return;
      }
    }
    else {
      window.PGridClientDebugMode >= 1 && console.warn(`Filter valus is missing!!!!`);

      context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_FilterMounted()` });
    }
  } catch (err) {
    let errMsg = `> Handle_FilterMounted() got exception: ${err.message || err}`;

    // alert(errMsg);
    (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `Handle_FilterMounted()` }) : alert(errMsg);

    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_FilterMounted()` });
    context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `Handle_FilterMounted()` });

  }


}


export async function Handle_Action_Load_FactsFromDBToGrid(context, { initTimestamp, initUrl, source = `<Handle_Action_Load_FactsFromDBToGrid got no source>` }) {

  try {
    window.PGridClientDebugMode >= 3 && console.debug(`Handle_Action_Load_FactsFromDBToGrid()`);
    if (PGridUtils.Is_DynamicMode(context.state)) {

      let pgridFilter = context.state.pgridFilter;
      let pgridVars = context.state.pgridVars;

      //Get current pgriddata
      let pgridData = await PGridUtils.Get_CurrentPGridData_ASYNC({
        context, source: `${source} -> Handle_Action_Load_FactsFromDBToGrid`
      });

      let pgridDataBuffer = lodash_clone(pgridData);
      // const foundLRs = Get_LRsFromTable(pgridData)

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid Get_CurrentPGridData, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: true, source: `Handle_Action_Load_FactsFromDBToGrid() -> ${source}` });

      let pgridFilterSelection = PGridUtils.Filter_ExtractPGridFiltersSelection(pgridFilter, true);

      let varsAndFilters_UniqueAggrByAssocLR = [];

      const foundLRs = PGridMatrices.Get_LRsFromTable(pgridDataBuffer, { parseLR: true, source: `${source} -> Handle_Action_Load_FactsFromDBToGrid()` }); // Get all linked ranges

      //Check if all LR are here

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid foundLRs, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      // Aprox 300 ms

      // FUB: Called two times; in Handle_Action_Load_FactsFromDBToGrid, for calculation of column sized -- then in Action_Stage_Grid, for hiding rows. This may generate dublicate CSS rules and is sub optimal
      await context.dispatch("Action_ApplyLRPgridCss", { source: `${source} -> Handle_Action_Load_FactsFromDBToGrid()` });


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid Action_ApplyLRPgridCss, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }

      let foundLRs_Keys = Object.keys(foundLRs);

      let skippFetchLRs = []
      let specializedQueries = {};

      for (let i = 0; i < lodash_get(foundLRs_Keys, "length", 0); i++) {
        let lr = foundLRs[foundLRs_Keys[i]];

        let doFetchData = lr.Phase5_DoFetchData();
        if (doFetchData) {

          let pgridVarsCpy = lodash_clone(pgridVars);
          delete pgridVarsCpy.GridDefinition;

          let pgridFilterSelectionCpy = lodash_clone(pgridFilterSelection);
          let pgridOptionalCellFilters = null;

          let overrideGridKey = lr.overrideGridKey;
          let overrideTabKey = lr.overrideTabKey;
          let overrideRemoveOrTranslateCellKeyDims = lodash_get(lr, "overrideRemoveOrTranslateCellKeyDims", []);
          if (overrideRemoveOrTranslateCellKeyDims == null) {
            overrideRemoveOrTranslateCellKeyDims = [];
          }

          if (overrideGridKey != null) {
            pgridVarsCpy.GridKey = overrideGridKey;
          }
          if (overrideTabKey != null) {
            pgridVarsCpy.TabKey = overrideTabKey;
          }

          // pgridVarsCpy.AssociatedLR = lr.name; //must match with an LR name when recived
          let associatedLR = lr.name;
          // if (overrideRemoveOrTranslateCellKeyDims != null) {
          [pgridFilterSelectionCpy, pgridOptionalCellFilters] = PGridUtils.HandleDimensionOverrides(context, lr, pgridFilterSelectionCpy, overrideRemoveOrTranslateCellKeyDims);
          // }


          let specializedQuerieUsedInThis = null;

          let StartTime_Phase4_1_GetSpecializedSQLQuery = Date.now();
          {
            let specQuery = lr.Phase4_1_GetSpecializedSQLQuery(lr);
            if (specQuery) {
              specializedQueries[lr.name] = specQuery;
              specializedQuerieUsedInThis = specQuery;
            }
          }


          let strVarsAndFilts = JSON.stringify({ vars: pgridVarsCpy, filters: pgridFilterSelectionCpy, postFilterCell: pgridOptionalCellFilters, specializedQuerieUsedInThis: specializedQuerieUsedInThis });



          if (window.PGridClientDebugMode >= 2) {
            console.debug(`[Phase4_1_GetSpecializedSQLQuery] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase4_1_GetSpecializedSQLQuery) / (100)))} 10th: s`); // in seconds
          }



          let strVarsAndFilts_existing = varsAndFilters_UniqueAggrByAssocLR.find(o => o.strVarsAndFilts == strVarsAndFilts)

          if (strVarsAndFilts_existing == null) {
            //No existing found, create nuew
            varsAndFilters_UniqueAggrByAssocLR.push({ strVarsAndFilts: strVarsAndFilts, associatedLRs: [associatedLR] });
          } else {
            //Add AccociatedLR to this 
            window.PGridClientDebugMode >= 2 && console.debug(`Handle_Action_Load_FactsFromDBToGrid adding associatedLR ${associatedLR} to existing uniqueStrVarsAndFilts`);
            strVarsAndFilts_existing.associatedLRs.push(associatedLR);
          }
        }
        else {
          skippFetchLRs.push(lr.name);
        }
      }// end LR

      let varsAndFiltsFinal = [];
      //Add associatedLR to varsAndFilters_UniqueAggrByAssocLR
      varsAndFilters_UniqueAggrByAssocLR.forEach(o => {
        let varsAndFiltsFinal_Item = JSON.parse(o.strVarsAndFilts);
        varsAndFiltsFinal_Item.vars["AssociatedLR"] = o.associatedLRs.join(',');
        varsAndFiltsFinal.push(varsAndFiltsFinal_Item);
      });

      let recivedFacts = []

      //Query backend in parelelle
      //Returns: list of facts

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid strVarsAndFilts, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }





      //FUB use Promise.allSettled instead?
      await Promise.all(varsAndFiltsFinal.map(async varsAndFilters => {

        if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
          window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid varFilt map, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
          return;
        }

        let specialzedDataSet = null;

        varsAndFilters.vars.AssociatedLR.split(',').forEach(alr => {
          if (alr in specializedQueries) {
            if (specialzedDataSet != null) {
              if (specialzedDataSet != specializedQueries[alr]) {
                throw Error("specialzedDataSet should not be changed here");
              }
            } else {
              specialzedDataSet = specializedQueries[alr];
            }
            window.PGridClientDebugMode >= 1 && console.debug("specialzedDataSet: " + specialzedDataSet);
          }
        });

        let { query, variables } = PGridBackendAccess.Phase5_Get_FactsBackendQuery(varsAndFilters.vars, varsAndFilters.filters, initUrl, specialzedDataSet, varsAndFilters.postFilterCell);

        let factData = null;
        factData = await PGridBackendAccess.Generic_Fetch_Fact(query, variables);

        // factData.AssociatedLR = varsAndFilters.vars.AssociatedLR;

        recivedFacts.push(factData);
      }));


      let pgridDataHT = PGridUtils.Get_HOTUncalcedDataAndSettings(context, {
        source: `${source} -> Handle_Action_Load_FactsFromDBToGrid`,
        pgridDataToUse: pgridDataBuffer
      });
      let hotDataBuffer = pgridDataHT.data; //FUB -> the result 
      let hotDataBufferSpanCells = pgridDataHT.spanCells;  // FUB? Can this be calculated later, or in another order to also include overlay items?


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid pgridDataHT, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      // Insert facts into HOT and PGridTable 

      let getSortKeyFromRecivedFacts = function (rf) {
        let sortKey = -1;

        if (rf) {

          let assLRs = rf.AssociatedLR.split(',');

          let assLRsSorKey = assLRs.map(x => {
            let lrName = x;
            let lr = foundLRs[lrName];
            return 100000000 * (lr.y + 1) + (lr.x + 1);
          });

          sortKey = assLRsSorKey.sort()[0];  //Use lowest if multiple associatedLR

        }
        return sortKey;
      }

      //Add the LR:s that don't requires DB Fact calls lr.Phase5_DoFetchData() == false

      skippFetchLRs.forEach(x => { recivedFacts.push({ AssociatedLR: x }) });

      let recivedFactsSorted = recivedFacts.sort((a, b) => {
        let aSort = getSortKeyFromRecivedFacts(a);
        let bSort = getSortKeyFromRecivedFacts(b);
        return aSort - bSort;
      });


      for (let f = 0; f < recivedFactsSorted.length; f++) {
        let factData = recivedFactsSorted[f];

        if (factData == null) {
          console.warn(`Handle_Action_Load_FactsFromDBToGrid() factData == null ({f})`);
          continue
        }

        if (!"AssociatedLR" in factData) {
          console.warn(`Missing AssociatedLR in factData`);
          continue;
        }

        for (let iLR = 0; iLR < foundLRs_Keys.length; iLR++) {

          window.PGridClientDebugMode >= 4 && console.log("sumCellSizes cnt: " + iLR);

          let lr = foundLRs[foundLRs_Keys[iLR]];

          window.PGridClientDebugMode >= 4 && console.log("sumCellSizes lr.name: " + lr.name);
          if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
            window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid 1 lr.name: ${lr.name}, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
            return;
          }

          let shouldProcess = false;


          // if (shouldProcess) {
          // if ("AssociatedLR" in factData) {

          // if (factData.GridKey == (lr.overrideGridKey != null ? lr.overrideGridKey : pgridVars.GridKey)) {
          //   shouldProcess = true;
          // }


          if (factData.AssociatedLR.split(',').indexOf(lr.name) != -1) {
            shouldProcess = true;
          }


          if (shouldProcess) {


            let StartTime_Phase6_Generate_FactRange = Date.now();

            let factRangeData = lr.Phase6_Generate_FactRange(pgridDataBuffer, factData, context.state.pgridSettings.language);

            if (window.PGridClientDebugMode >= 2) {
              console.debug(`[Phase6_Generate_FactRange] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase6_Generate_FactRange) / (100)))} 10th: s`); // in seconds
            }

            let pgridTable_Recurse_Before = null;
            if (window.PGridClientDebugMode >= 4) {
              pgridTable_Recurse_Before = JSON.stringify(pgridDataBuffer);
            }


            let StartTime_Phase7_Insert_FactRange = Date.now();

            // let pgridDataInserted, hotTableInserted;

            if (factRangeData) {


              // let { pgridDataInserted, hotTableInserted /*Implement this later*/ } = lr.Phase7_Insert_FactRange(pgridDataBuffer, hotDataBuffer, factRangeData);
              let phase7_ret = /*Implement this later*/ lr.Phase7_Insert_FactRange(pgridDataBuffer, hotDataBuffer, factRangeData);
              // pgridDataInserted = phase7_ret.pgridDataInserted;
              // hotTableInserted = phase7_ret.hotTableInserted;

              if (true && !lr.lrIsHidden && context.state.LowestRightNonHiddenLR !== null && phase7_ret.insertedRows > 0) {
                let newLowestRightNonHiddenLR = lodash_clone(context.state.LowestRightNonHiddenLR);
                newLowestRightNonHiddenLR.y = newLowestRightNonHiddenLR.y + phase7_ret.insertedRows
                context.commit('Mutation_UpdateRoot', { prop: "LowestRightNonHiddenLR", val: newLowestRightNonHiddenLR, op: "set", source: source + `-> Handle_Action_Load_FactsFromDBToGrid()` });
              }

              //Since OpenTable, this is required to allow inserting new fact rows
              // pgridDataBuffer = pgridDataInserted;
              // hotDataBuffer = pgridDataInserted;

              if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
                window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid 2 lr.name: ${lr.name}, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
                return;
              }
            } else {
              console.warn(`factRangeData is null, not inserting facts`);
            }



            if (window.PGridClientDebugMode >= 2) {
              console.debug(`[Phase7_Insert_FactRange] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase7_Insert_FactRange) / (100)))} 10th: s`); // in seconds
            }


            if (window.PGridClientDebugMode >= 4) {
              PGridUtils.DebugDetectTableChanges(`spinningTreeNet() Phase7_Insert_FactRange LR:${lr.name}`, pgridDataBuffer, pgridTable_Recurse_Before);
            }


            { ////  Compensate and increase fixedRowsTop if new rows are above fixedRowsTop ////
              let fixTopPre = JSON.parse(JSON.stringify(lodash_get(context.state.hotSettings, 'LRHotSettings', []))).find(x => ("fixedRowsTop" in x));

              let currentFixTop = 0;
              if (fixTopPre) {
                currentFixTop = fixTopPre.fixedRowsTop
              }

              let newFixTop = 0;

              if (currentFixTop) {
                if (!lr.linkedRange.Hidden && lr.linkedRange.Type == "OpenTable") {

                  let belowLastOpenTableHeaderComp = 1 /* -1 for not don't looking at the "last large tabel" one row below the header */;

                  if (lr.y + belowLastOpenTableHeaderComp < fixTopPre.fixedRowsTop) {


                    newFixTop = currentFixTop + lr.yLength - belowLastOpenTableHeaderComp

                    window.PGridClientDebugMode >= 4 && console.log("sumCellSizes lr.name: " + lr.name+ " new fixedRowsTop: " + newFixTop);

                  }
                }
              }


              if (newFixTop > currentFixTop) {
                let newLRHotSettings = JSON.parse(JSON.stringify(lodash_get(context.state.hotSettings, 'LRHotSettings', []))).map(x => {

                  if ("fixedRowsTop" in x) {
                    if (newFixTop - currentFixTop >= context.state.pgridSettings.fixedRowsTopMaxCompensation) {
                      console.log("Reach fixedRowsTopMaxCompensation, disable fixedRowsTop")
                      delete x.fixedRowsTop;
                    } else {
                      x.fixedRowsTop = newFixTop
                    }
                  }
                  return x;
                })

                window.PGridClientDebugMode >= 4 && console.debug(`sumCellSizes fooo ${lodash_get(context.state.pgridSettings, "fixedRowsTop", -1)}`);
                context.commit('Mutation_UpdateHOTSettings', { prop: "LRHotSettings", val: newLRHotSettings, source: `${source} -> Phase7_Insert_FactRange LR:${lr.name}` });
              }
            }

          }

        }
      }
      // });

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid pgridDataHT, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      //Insert overlay content
      for (let i = 0; i < foundLRs_Keys.length; i++) {
        let lr = foundLRs[foundLRs_Keys[i]];

        let StartTime_Insert_Overlay = Date.now();


        let pgridDataBufferBefore = null;
        if (window.PGridClientDebugMode >= 3) {
          pgridDataBufferBefore = JSON.parse(JSON.stringify(pgridDataBuffer));
        }

        let { pgridDataInserted, hotTableInserted } = PGridMatrices.InsertOverlay(
          lr
          , pgridDataBuffer
          , hotDataBuffer
          , context
          , "initEvent" /* "preInsertEvent" "initEvent"=hasdata, "selectionchangeEvent", "postcalcEvent" */);

        pgridDataBuffer = pgridDataInserted;
        hotDataBuffer = hotTableInserted

        if (window.PGridClientDebugMode >= 3) {
          PGridUtils.DebugDetectTableChanges(`InsertOverlay(initEvent)`, pgridDataBuffer, pgridDataBufferBefore);
        }


        if (window.PGridClientDebugMode >= 2) {
          console.debug(`[InsertOverlay] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Insert_Overlay) / (100)))} 10th: s`); // in seconds
        }
      }


      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid before pgridDataDynContent, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


      //FUB please only update factData in HOT, not update whole  ---- //This needs some work, disabling
      context.commit('Mutation_UpdateRoot', { prop: 'pgridDataDynContent', val: pgridDataBuffer, source: `${source} -> Get_CurrentPGridData` });

      //Updating spanCells
      context.commit('Mutation_UpdateHOTSettings', { prop: 'spanCells', val: hotDataBufferSpanCells, source: `${source} -> Action_UpdateHOT_DataAndSettings` });

      // await context.dispatch('Action_UpdateHOT_DataAndSettings', {
      //   source: `${source} -> Action_Load_FactsFromDBToGrid`,
      //   pgridDataHT: pgridDataHT
      // });

      if (context.state.pgridSettings.initOfLastQuery_Timestamp > initTimestamp) {
        window.PGridClientDebugMode >= 1 && console.warn(`!!!  Canceling Handle_Action_Load_FactsFromDBToGrid after pgridDataDynContent, as lastUpdate is more recent\n\nGLO: ${context.state.pgridSettings.initOfLastQuery_Url == null ? null : context.state.pgridSettings.initOfLastQuery_Url.split(`&`).join(`\n`)}\nMY : ${initUrl.split(`&`).join(`\n`)}\n`);
        return;
      }


    }
    else {
      throw new Error("Can only load in Default mode. source: " + source);
    }
  } catch (err) {
    console.error(`Handle_Action_Load_FactsFromDBToGrid() got exception: ${err.stack || err}`);
    let errMsg = `${translateFromKey('loadFactError', context.state.pgridSettings.language)}\n\n${err.stack}`;
    if (!context.state.hotRef) { //Only show error on first load
      // alert(errMsg);
      (window.PGridVueStore) ? window.PGridVueStore.commit('Mutation_UpdateRoot', { prop: "globalErrorMessage", op: "push", val: errMsg, source: `${source} -> Handle_Action_Load_FactsFromDBToGrid()` }) : alert(errMsg);
    }
    console.error(errMsg);
    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Action_Load_FactsFromDBToGrid()  got exception` });
  }

  if (context.state.pgridSettings.isLoadingUntilRedraw) {
    //Set isLoding = false in Redraw() insted
    //This setting is needed as Redraw is not called for PGridPivot (Why?)
  }
  else {
    context.commit('Mutation_UpdatePGridSettings', { prop: "isLoading", val: false, source: `Handle_Action_Load_FactsFromDBToGrid()` });
  }
}

export default {
  Handle_FilterMounted,
  Handle_Action_Load_FactsFromDBToGrid,
}