import { GraphQLClient } from 'graphql-request';
import PGridUtils, { sleep } from './pgridUtils'

const GENERIOC_FETCH_MAX_NUMBER_OF_TRIES = 4
const GENERIOC_FETCH_RETRY_DELAY = 5000

export async function QueryPGridGetGrid(variables) {

    try {
        const query = `
    query queryPGridGetGrid($CustomerKey: String, $TabKey: String, $GridKey: String)
    {
        PGridGetGrid(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey) 
        {
            UserKey
            UserFullName
            UserEmail
            RoleKey
            RoleName
            CustomerKey
            CustomerName
            ServiceKey
            ServiceName
            ApplicationKey
            ApplicationName
            TabKey
            TabName
            GridKey
            GridName
            GridDescription
            CustomerKey
            DatabaseKey
            GridDefinition
            ClientDebugMode
        }
    }
`
        var qlUrl = '/graphql';
        if (variables.hasOwnProperty("baseUrl")) {
            qlUrl = variables.baseUrl + qlUrl;
        }

        const client = new GraphQLClient(qlUrl, {
            headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() }
        });

        let requestQueryPGridGetGrid;
        try {
            requestQueryPGridGetGrid = await client.request(query, variables);
        } catch (err) {
            let errMsg = `> QueryPGridGetGrid() requestQueryPGridGetGrid got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestQueryPGridGetGrid;

    } catch (err) {
        let errMsg = `> QueryPGridGetGrid() got error ${err.message || err}`
        throw new Error(errMsg);
    }

}

export async function QueryPGridCustomStyle(variables) {

    try {
        const query = `
        query {
            PGridCustomStyle(CustomerKey: "${variables.CustomerKey}") 
            {
                Css,
                Error
            }
        }
`
        var qlUrl = '/graphql';
        if (variables.hasOwnProperty("baseUrl")) {
            qlUrl = variables.baseUrl + qlUrl;
        }

        const client = new GraphQLClient(qlUrl, {
            headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() }
        });

        let requestQueryPGridCustomStyle;
        try {
            requestQueryPGridCustomStyle = await client.request(query, variables);

        } catch (err) {
            let errMsg = `> QueryPGridCustomStyle() requestQueryPGridCustomStyle got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestQueryPGridCustomStyle;

    } catch (err) {
        let errMsg = `> QueryPGridCustomStyle() got error ${err.message || err}`
        throw new Error(errMsg);
    }

}


function getCookieValueOld(name) {
    var cookieString = RegExp(name + "=[^;]+").exec(document.cookie);
    return decodeURIComponent(!!cookieString ? cookieString.toString().replace(/^[^=]+./, "") : "");
}

function getCustomLogin() {
    let cl = localStorage.getItem("customLogin");
    window.PGridClientDebugMode >= 3 && console.debug(`localStorage.getItem("customLogin"): ${cl}`);
    return cl;
}

export async function QueryPGridData(variables) {
    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })
        const query = `
            query paramsPGridGetGridData($CustomerKey: String, $TabKey: String, $GridKey: String, $VirtualQueryString: String)
            {
                PGridGetGridDataAndDataSets(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, VirtualQueryString: $VirtualQueryString) 
                {
                    GridDataJSON
                    GridDataSetsJSON
                    VirtualQueryString
                    IsCustomerAdmin
                    IsMissingFilterSelectionCount
                    Error
                }
            }
    `
        let requestQueryPGridData;
        try {


            if (!("GridKey" in variables)) {
                throw "Missing GridKey";
            }

            requestQueryPGridData = await client.request(query, variables);

            // let respErr = lodash_get(requestQueryPGridData, "PGridGetGridDataAndDataSets[0].Error", "ErrorReadingError");
            // if (respErr) {
            //     throw new Error(`QueryPGridData() recived results with error: ${respErr}`);
            // }
        } catch (err) {
            let errMsg = `> QueryPGridData() requestQueryPGridData got exception ${err.message || err}`
            throw new Error(errMsg);
        }
        return requestQueryPGridData;

    } catch (err) {
        let errMsg = `> QueryPGridData() got exception ${err.message || err}`
        throw new Error(errMsg);
    }
}


export async function SavePGridData(variables, pgridDataRows) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.RowsDataJSON = JSON.stringify(pgridDataRows);

        const query = `
            query paramsPGridSetGridData($CustomerKey: String, $TabKey: String, $GridKey: String, $RowsDataJSON: String, $fieldNames: String)
            {
                PGridSetGridData(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, RowsDataJSON: $RowsDataJSON, fieldNames: $fieldNames) 
                {
                    GridDataJSON
                    Error
                }
            }
    `
        let requestPGridSetGridData;

        try {
            requestPGridSetGridData = await client.request(query, variables)

            if (requestPGridSetGridData.PGridSetGridData != null) {
                if (requestPGridSetGridData.PGridSetGridData[0].Error != null) {
                    throw "requestPGridSetGridData: " + requestPGridSetGridData.PGridSetGridData[0].Error;
                }
            }
        } catch (requestPGridSetGridData_error) {
            throw "requestPGridSetGridData_error: " + requestPGridSetGridData_error
        }

        return requestPGridSetGridData;

    } catch (SavePGridData_error) {
        throw "SavePGridData_error: " + SavePGridData_error
    }
}

export async function SavePGridFact(variables, pgridFilterSelection, pgridFactRows, batchId, timestampMS, saveType = 'normal') {

    try {

        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.FactRowsDataJSON = JSON.stringify(pgridFactRows);
        variables.FilterSelection = JSON.stringify(pgridFilterSelection);
        variables.BatchId = batchId;
        variables.TimestampMS = String(timestampMS);
        const query = `
            query paramsPGridSetFact($CustomerKey: String, $TabKey: String, $GridKey: String, $BatchId: String, $TimestampMS: String, $FilterSelection: String, $FactRowsDataJSON: String)
            {
                PGridSetFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, BatchId: $BatchId, TimestampMS: $TimestampMS, FilterSelection: $FilterSelection, FactRowsDataJSON: $FactRowsDataJSON) 
                {
                    FactDataJSON
                    Error
                }
            }
    `
        let requestPGridSetFact;

        try {
            requestPGridSetFact = await client.request(query, variables)

            if (requestPGridSetFact.PGridSetFact != null) {
                if (requestPGridSetFact.PGridSetFact[0].Error != null) {
                    throw "requestPGridSetFact: " + requestPGridSetFact.PGridSetFact[0].Error;
                }
            }
        } catch (requestPGridSetFact_error) {
            throw "requestPGridSetFact_error: " + requestPGridSetFact_error
        }

        return requestPGridSetFact;

    } catch (SavePGridData_error) {
        throw "SavePGridData_error: " + SavePGridData_error
    }
}

export async function HandlePGridEvent(variables, pgridFilterSelection, pgridEventInputData, batchId, timestampMS, pgridEventType) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })


        let variablesClone = JSON.parse(JSON.stringify(variables));

        variablesClone.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variablesClone.EventInputData = pgridEventInputData;
        variablesClone.FilterSelection = JSON.stringify(pgridFilterSelection);
        variablesClone.BatchId = batchId;
        variablesClone.TimestampMS = String(timestampMS);
        variablesClone.EventType = pgridEventType;
        variablesClone.UserInputUrl = window.location.href;
        delete variablesClone.FactRowsDataJSON;

        const query = `
            query paramsPGridEvent($CustomerKey: String, $TabKey: String, $GridKey: String, $BatchId: String, $TimestampMS: String, $FilterSelection: String, $EventType: String, $EventInputData: String, $UserInputUrl: String)
            {
                PGridEvent(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, BatchId: $BatchId, TimestampMS: $TimestampMS, FilterSelection: $FilterSelection, EventType: $EventType, EventInputData: $EventInputData, UserInputUrl: $UserInputUrl) 
                {
                    EventDataJSON
                    Error
                }
            }`;

        let requestPGridSetFact = null;

        let foo = "bar";

        try {
            requestPGridSetFact = await client.request(query, variablesClone)

            if (requestPGridSetFact.PGridSetFact != null) {
                if (requestPGridSetFact.PGridSetFact[0].Error != null) {
                    throw "requestPGridSetFact: " + requestPGridSetFact.PGridSetFact[0].Error;
                }
            }


        } catch (err) {
            let errMsg = `> HandlePGridEvent() requestPGridSetFact got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridSetFact;

    } catch (err) {
        let errMsg = `> HandlePGridEvent() got error ${err.message || err}`
        throw new Error(errMsg);
    }
}

export async function SavePGridSpecializedData(variables, pgridFilterSelection, specialzedDataSet, specialzedData, virtualQueryString, batchId, timestampMS, saveType = 'normal') {

    try {

        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

        // variables.FactRowsDataJSON = JSON.stringify(pgridFactRows);
        variables.FilterSelection = JSON.stringify(pgridFilterSelection);
        variables.SpecializedDataSet = specialzedDataSet;
        variables.SpecializedData = JSON.stringify(specialzedData);
        variables.VirtualQueryString = virtualQueryString;
        variables.BatchId = batchId;
        variables.TimestampMS = String(timestampMS);

        window.PGridClientDebugMode >= 4 && console.debug(`SavePGridSpecializedData() SpecializedData '${specialzedDataSet}':`, specialzedData)

        const query = `
            query paramsPGridSetSpecializedData($CustomerKey: String, $TabKey: String, $GridKey: String, $BatchId: String, $TimestampMS: String, $FilterSelection: String, $SpecializedDataSet: String, $SpecializedData: String, $VirtualQueryString: String)
            {
                PGridSetSpecializedData(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, BatchId: $BatchId, TimestampMS: $TimestampMS, FilterSelection: $FilterSelection, SpecializedDataSet: $SpecializedDataSet, SpecializedData: $SpecializedData, VirtualQueryString: $VirtualQueryString) 
                {
                    Error
                }
            }
    `
        let requestPGridSetSpecializedData;

        // try {
        requestPGridSetSpecializedData = await client.request(query, variables)

        if (requestPGridSetSpecializedData.PGridSetSpecializedData != null) {
            if (requestPGridSetSpecializedData.PGridSetSpecializedData[0].Error != null) {

                throw new Error("SavePGridSpecializedData() Error != null: " + requestPGridSetSpecializedData.PGridSetSpecializedData[0].Error);
            }
        }

        return requestPGridSetSpecializedData;

    } catch (SavePGridSpecializedData_error) {
        throw new Error("SavePGridSpecializedData_error: " + SavePGridSpecializedData_error)
    }
}


export async function SavePGridCss(variables) {
    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

        const query = `
            mutation paramsPGridSetCss($CustomerKey: String, $Css: String) {
                PGridSetCss(CustomerKey: $CustomerKey, Css: $Css)
                {
                    Error
                }
            }
            `;
        let requestPGridSetCss;

        try {
            requestPGridSetCss = await client.request(query, variables)

            if (requestPGridSetCss.Error != null && requestPGridSetCss.Error != "") {
                throw "requestPGridSetCss: " + requestPGridSetCss.Error;
            }

        } catch (err) {
            let errMsg = `> SavePGridCss() requestPGridSetCss got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridSetCss;

    } catch (err) {
        let errMsg = `> SavePGridCss() got error ${err.message || err}`
        throw new Error(errMsg);
    }


}

export async function Generic_Fetch_Fact(query, variables) {

    let factData = null;
    for (let tries = 0; tries < GENERIOC_FETCH_MAX_NUMBER_OF_TRIES; tries++) { //Avoid intermittend 500 errors to crash fetch

        try {

            // if (tries < 3) {
            //     let errStr = `GraphQL Error (Code: 500) ${tries} ex`;
            //     // let errStr = `GraphQL Error (Code: 401): {"response":{"error":"<!DOCTYPE html> <html lang=\"en\"><head><meta charset=\"utf-8\"><title>Error</title></head><body><pre>Error: app.use /graphql JwtToken could not be verified. Returning 401 <br>azureJwt.js keys() got exception: Error ${tries} ex`;
            //     console.warn(`Simulate error nr ${tries} ${tries}`);
            //     throw new Error(errStr);
            // }

            const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

            let result = null;

            result = await client.request(query, variables);

            if (result.PGridGetFact != null) {
                if ("Error" in result.PGridGetFact[0]
                    && result.PGridGetFact[0].Error == null) {
                    try {
                        factData = JSON.parse(result.PGridGetFact[0].FactDataJSON);
                        false && console.debug("Generic_Fetch_Fact factData:", factData);
                    } catch {
                        throw new Error(`Generic_Fetch_Fact: Could not parse fact data json`);
                    }
                } else {
                    let errMsg = `> Generic_Fetch_Fact: got error from graphql: ${result.PGridGetFact[0].Error}`;
                    console.error(errMsg);
                    throw new Error(errMsg);
                }
            }
            tries = GENERIOC_FETCH_MAX_NUMBER_OF_TRIES;
        } catch (ex) {
            let errMsg = `Generic_Fetch_Fact_error try: ${tries} - ${ex.message}, stack trace - ${ex.stack}`;
            console.warn(errMsg);

            if (
                ex.message
                &&
                (
                    String(ex.message).indexOf("GraphQL Error (Code: 500)") != -1 //An "expected" 500 error, then  try agin
                    ||
                    String(ex.message).indexOf("JwtToken could not be verified") != -1 //An "expected" 401 error, then  try agin
                )
                && tries < GENERIOC_FETCH_MAX_NUMBER_OF_TRIES
            ) {
                let sleepMS = GENERIOC_FETCH_RETRY_DELAY * tries;
                console.warn(`Generic_Fetch_Fact() sleeping: ${sleepMS} ms`)
                await sleep(sleepMS)
            } else {
                console.error(errMsg);
                throw new Error(errMsg);
            }
        }
    }

    return factData;
}
/*
export async function Phase5_Get_FactsForSQLTableBackendQuery(variables, pgridFilterSelection, linkedRange) {

    try {
        const client = new GraphQLClient('/graphql', { credentials: 'include', mode: 'cors', headers: { Authorization: "Bearer " + await PGridUtils.getIdTokenForGrid(), LoginType: getCustomLogin() } })

        variables.fieldNames = null; //"Val:ValStr:Format:Formula:RefType";
        variables.FilterSelection = JSON.stringify(pgridFilterSelection);
        variables.LinkedRangeJSON = JSON.stringify(linkedRange);

        const query = `
            query paramsPGridGetTableFact($CustomerKey: String, $TabKey: String, $GridKey: String, $FilterSelection: String, $LinkedRangeJSON: String)
            {
                PGridGetTableFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, FilterSelection: $FilterSelection, LinkedRangeJSON: $LinkedRangeJSON) 
                {
                    FactDataJSON
                    Error
                }
            }
    `
        let requestPGridGetTableFact;

        try {
            requestPGridGetTableFact = await client.request(query, variables)

            let respError = lodash_get(requestPGridGetTableFact, "PGridGetFact[0].Error", null);
            if (respError) {
                throw new Error(respError);
            }

        } catch (err) {
            let errMsg = `> Phase5_Get_FactsForSQLTableBackendQuery() requestPGridGetTableFact got error ${err.message || err}`
            throw new Error(errMsg);
        }

        return requestPGridGetTableFact;

    } catch (err) {
        let errMsg = `> Phase5_Get_FactsForSQLTableBackendQuery() got error ${err.message || err}`
        throw new Error(errMsg);
    }
}
*/
export function Phase5_Get_FactsBackendQuery(pgridVars, FilterSelection, VirtualQueryString, SpecializedDataSet = null, OptionalCellFilters = null) {

    let pgridVarsCopy = JSON.parse(JSON.stringify(pgridVars));

    //Is here now
    let variables = {
        CustomerKey: pgridVarsCopy.CustomerKey,
        TabKey: pgridVarsCopy.TabKey,
        GridKey: pgridVarsCopy.GridKey,
        FilterSelection: JSON.stringify(FilterSelection),
        OptionalCellFilters: OptionalCellFilters == null ? null : JSON.stringify(OptionalCellFilters),
        AssociatedLR: pgridVars.AssociatedLR,
        SpecializedDataSet: SpecializedDataSet,
        VirtualQueryString: VirtualQueryString
    }

    const query = `
            query paramsPGridGetFact($CustomerKey: String, $TabKey: String, $GridKey: String, $FilterSelection: String, $SpecializedDataSet: String, $VirtualQueryString: String, $OptionalCellFilters: String, $AssociatedLR: String)
            {
                            PGridGetFact(CustomerKey: $CustomerKey, TabKey: $TabKey, GridKey: $GridKey, FilterSelection: $FilterSelection, SpecializedDataSet: $SpecializedDataSet, VirtualQueryString: $VirtualQueryString, OptionalCellFilters: $OptionalCellFilters, AssociatedLR: $AssociatedLR) {
                                FactDataJSON
                                Error
                            }
                        }`

    return { query, variables };
}


export default {
    QueryPGridGetGrid
    , QueryPGridData
    , QueryPGridCustomStyle
    , SavePGridData
    , SavePGridFact
    , SavePGridSpecializedData
    , HandlePGridEvent
    , Generic_Fetch_Fact
    , SavePGridCss
    , Phase5_Get_FactsBackendQuery
}