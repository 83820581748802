import { PGridLR_MetaCell } from './pgridLR_MetaCell.js';

import PGridLR_FilterCell_Schema from './PGridLR_FilterCell-schema.json';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';

export class PGridLR_FilterCell extends PGridLR_MetaCell {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}

    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        this.schema = PGridLR_FilterCell_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        // this.AxisDefMap = {
        //     // COLUMNS: { type: 'COLUMNS', mapsTo: null },  //'Column' is a property in the linked range definition
        //     FACTS: { type: 'FACTS', mapsTo: null }
        // }

        this.xLength = 1;
        this.yLength = 1;
        // this.xStart = null;
        // this.yStart = null;

        this.FilterName = lodash_get(this.linkedRange, "FilterName", null);
        this.TextAsValue = lodash_get(this.linkedRange, "TextAsValue", false);
        this.RegExMatch = lodash_get(this.linkedRange, "Match", null);
        this.Hidden = lodash_get(this.linkedRange, "Hidden", false);
        this.ReadOnly = lodash_get(this.linkedRange, "ReadOnly", true);
        // this.offsetY = lodash_get(this.linkedRange, "Overlay", null);

        if (!this.MetaCellMap) {
            this.MetaCellMap = [];
        }
    }

    whoAmI() {
        return 'I am a PGridLR_FilterCell'
    }

    // // Nothing to return
    // Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {
    //     let ret = Array();
    //     return ret;
    // }

    // // Nothing to to here
    // Phase2_Load_DimDataInAxisData(pgridDim) {
    //     return null;
    // }

    // // Nothing to to here
    // Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {
    //     let ret = { pgridTableDyn: pgridTableDyn, addedRowsOffset: 0, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }
    //     return ret;
    // }

    // Phase5_DoFetchData() {
    //     return false;
    // }

    // // Nothing to to here
    // Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {
    //     return pgridTable;
    // }

    // // Nothing to to here

    Phase6_Generate_FactRange(pgridDataDyn, factData, lang = null) {

        // factRangeData[insertY][insertX] = cellValue;
        let filters = PGridVueStore.state.pgridFilter.Filters;

        let foundFilterValue = null;
        let foundFilterLabel = null;

        let filtersKeys = Object.keys(filters);
        for (let i = 0; i < filtersKeys.length; i++) {
            let f = filters[filtersKeys[i]];

            if (f.title == this.FilterName) {
                foundFilterValue = f.filterValue.value;
                foundFilterLabel = f.filterOptions.find(x => f.filterValue.value == x.value).text
            }
        }


        let finalVal = this.TextAsValue ? foundFilterLabel : foundFilterValue

        if (this.RegExMatch) {
            const m = new RegExp(this.RegExMatch);
            let found = (String(finalVal)).match(m);
            if (found == null) {
                finalVal = null;
            } else {
                if (found.length > 1) {
                    finalVal = found[1];
                } else {
                    finalVal = found[0];
                }
            }
        }

        if (finalVal == null) {
            finalVal = '';
        } else {
            finalVal = String(finalVal);
        }

        let factRangeData = [[{ ValStr: finalVal, Meta: { Validator: { Type: "text" } } }]];


        if (this.Hidden) {
            factRangeData[0][0].Format = "pcell-hidden"
        }

        if (this.ReadOnly) {
            let merged_cell = lodash_merge(factRangeData[0][0], { Meta: { Save: { ReadOnly: true } } });
            factRangeData[0][0] = merged_cell;
        }



        return factRangeData;
    }



}