<template>
  <div class="pgrid-guidance noprint" style="display: table" v-bind:style="{ width: myAvailableWidth + 'px' }">
    <div class="pgrid-guidance-frame" style="height: 100%; display: table-cell; vertical-align: middle">
      <p class="pgrid-guidance-inner pgrid-overflow-ellipsis" v-html="myContentHtml" v-bind:title="myContentHtml" />
    </div>
  </div>
</template>
<script>
import Vue from "vue/dist/vue.js";

export default {
  name: "pgrid-guidance",

  data: function () {
    return {
      pgridSettings: this.$store.state.pgridSettings,
    };
  },

  props: {
    myAvailableWidth: Number,
    myContentHtml: String,
  },

  mounted: async function () {
    /******************************************* */
    //Check this out: https://jsfiddle.net/8wg7o6c6/
    /******************************************* */

    let context = this.$store;

    // await PGridHandlers.Handle_FilterMounted(context);
  },
  methods: {},
  computed: {},
  components: {},
};
</script>
<style>
.pgrid-guidance {
  padding: 0;
  margin: 0;
  margin-right: 6px;
  margin-left: 8px;
  display: inline-block;
  overflow: hidden;

  /* height: 84px; */
}

.pgrid-guidance-frame {
  margin: 4px;
  margin-bottom: -19px;
  border: solid rgb(250, 84, 84) 1px;
  color: rgb(255, 41, 41);
  border-radius: 8px;
}

.pgrid-guidance-inner {
  /* margin-right: 50%;
  margin-left: 50%;
  margin-top: 50%;
  margin-bottom: 50%; */
  /*    margin: 4px 4px 4px 4px;*/
  margin: 0 auto;
  /* padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px; */
  /*margin: 0px;
    max-height: calc(100% - 15px);

    max-width: calc(200% - 35px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
*/
  max-width: calc(100% - 35px);

  line-height: 1.5;

  text-align: center;
}

p.pgrid-overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
