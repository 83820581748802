import { SavePGridFact, HandlePGridEvent, SavePGridSpecializedData } from './pgridBackendAccess.js';

import { PGridLR_EPivot } from './pgridLR_EPivot.js';
import { PGridLR_FreeCell } from './pgridLR_FreeCell.js';
import { PGridLR_OpenTable } from './pgridLR_OpenTable.js';
import { PGridLR_MetaCell } from './pgridLR_MetaCell.js';
import { PGridLR_FilterCell } from './pgridLR_FilterCell.js';
import { PGridLR_ApexCharts } from './pgridLR_ApexCharts';
import { PGridLR_MasterDataGrid } from './pgridLR_MasterDataGrid.js';

import { expandIfNeeded } from './pgridCell.js';
import PGridCell from './pgridCell.js';

import PGridUtils from './pgridUtils.js';

import PGridDynExtract from './pgridDynExtract.js'

import { toLabel, columnIndexToLabel, rowIndexToLabel, rowLabelToIndex } from '../formula-parser/helper/cell.js';

// import { extractLabel, toLabel, columnLabelToIndex, rowLabelToIndex, columnIndexToLabel } from '../formula-parser/helper/cell.js';

const { conv_pgrid2pgdataset } = require('./pgridConvert.js');

import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import uuidv1 from 'uuid/v1.js';

const DimCountY_MAX = 10; //Maximum dimensions in Y axis supported
const DimCountX_MAX = 10; //Maximum dimensions in X axis supported

function isEmpty(str) {
    return (!str || 0 === str.length);
}

/*
Function: Takes a grid definition and datasets and inserts dynamic data from RefType cells (LinkedRange)
Returns: a new grid
Notes: Does not manipulate parameters
*/
export async function Load_Phase2to4(pgridTableStaticOrDynamic, pgridDim, context, source = `<Load_Phase2to4() has no source>`) {

    // let state = context.state;

    window.PGridClientDebugMode >= 3 && console.debug(`pgridMatrices.js Load_Phase2to4() - ${source}`);
    let pgridTableDyn = null;

    try {

        pgridTableDyn = lodash_clone(pgridTableStaticOrDynamic);
        const foundLRs = Get_LRsFromTable(pgridTableStaticOrDynamic, { source: `${source} -> Load_Phase2to4()` }); // Get all linked ranges

        let LowestRight_Static = { y: -1, x: -1 };
        for (let y = 0; y < pgridTableDyn.length; y++) {
            for (let x = 0; x < pgridTableDyn[0].length; x++) {
                if (pgridTableDyn[y][x]) {

                    let tmpCell = lodash_clone(pgridTableDyn[y][x])

                    LowestRight_Static.y = Math.max(LowestRight_Static.y, y);
                    LowestRight_Static.x = Math.max(LowestRight_Static.x, x);

                    //Keep track of static data for saving PGridTable (not facts)
                    lodash_set(pgridTableDyn[y][x], "Meta.StaticSrc", { y, x });
                    lodash_set(pgridTableDyn[y][x], "Meta.StaticSrc.Data", tmpCell);
                }
            }
        }


        // ** Then insert Data to them
        let foundLRs_Keys = Object.keys(foundLRs);
        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];


            try {
                false && console.debug(`Load_Phase2to4() ${lr.name}:${lr.type} Phase2_Load_DimDataInAxisData axisData = `, lr.axisData);
                lr.Phase2_Load_DimDataInAxisData(pgridDim);
            } catch (err) {
                let errMsg = `> Load_Phase2to4() lr.Phase2_Load_DimDataInAxisData(pgridDim) got exception: ${err.message || err}`;
                throw new Error(errMsg);
            }

        }


        // ** Lastly update the grid with the data collected in the linked ranges

        // let addedRowsOffsetLR = 0;

        let LowestRightNonHiddenLR = { y: -1, x: -1 }


        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];
            let res = lr.Phase3_0_Pre_Insert_Overlay(
                pgridTableDyn
                , context
            )

            pgridTableDyn = res.pgridDataInserted;
        }




        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];


            if (!("Type" in lr.linkedRange)) {
                nonHaltErrorMessage += `LinkedRange on row ${y} col ${xSearch} is missing its Type property`
            }


            // Process columns &
            // Process rows, will also add rows to grid

            // lr.RowsOffset = addedRowsOffsetLR; //Offset from last lr processing  // Was alwatys 0

            if (lr.AxisDefMap) {

                let StartTime_Phase3_Insert_DynDimData = Date.now();

                let lrAxisDefMapaObject_Keys = Object.keys(lr.AxisDefMap);
                for (let i = 0; i < lrAxisDefMapaObject_Keys.length; i++) {
                    let pgridAx = lr.AxisDefMap[lrAxisDefMapaObject_Keys[i]];

                    if (pgridAx.IsTableAxis) {
                        let res = await lr.Phase3_Insert_DynDimData(pgridTableDyn, pgridAx, 0 /*addedRowsOffsetLR Was alwatys 0 */, context.state);
                        pgridTableDyn = res.pgridTableDyn;
                        //  addedRowsOffsetLR += res.addedRowsOffset; Was alwatys 0

                        //Adjust the lowest static cell if added rows above
                        if (res.addedRowsOffset > 0 && lr.y < LowestRight_Static.y) {
                            // LowestRight_Static.y += res.addedRowsOffset;
                        }

                        // if (pgridAx.type == "ROWS") {

                        //     //Adjust the lowest static cell if added rows above (no likely)
                        //     if (res.addedRowsOffsetLR_0 > 0 && lr.y < LowestRight_Static.y) {
                        //         LowestRight_Static.y += res.addedRowsOffsetLR_0;
                        //     }

                        //     //Adjust the  static cell if added rows above (no likely)
                        //     if (res.addedRowsOffsetLR_0 > 0) {

                        //         let updatedLRCoordinatesHints = state.pgridLRCoordinatesHints;
                        //         let hints_Keys = Object.keys(updatedLRCoordinatesHints);
                        //         let updatedHints = false;

                        //         for (let i = 0; i < hints_Keys.length; i++) {
                        //             let hint = updatedLRCoordinatesHints[hints_Keys[i]];

                        //             if (lr.y < hint.y) {
                        //                 hint.y += res.addedRowsOffsetLR_0;
                        //                 updatedHints = true;
                        //             }
                        //         }

                        //         if (updatedHints) {
                        //             commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: updatedLRCoordinatesHints, source: `${source} -> Load_Phase2to4()` });
                        //         }

                        //     }
                        // }


                        if (!res.lrIsHidden) {
                            LowestRightNonHiddenLR.x = Math.max(LowestRightNonHiddenLR.x, res.lowerRightCoord.x);
                            LowestRightNonHiddenLR.y = Math.max(LowestRightNonHiddenLR.y, res.lowerRightCoord.y);
                        }
                    }
                }

                if (window.PGridClientDebugMode >= 2) {
                    console.debug(`[Phase3_Insert_DynDimData] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase3_Insert_DynDimData) / (100)))} 10th: s`); // in seconds
                }
            }


            let StartTime_Phase3_1_Update_HOTSettings = Date.now();
            { // Process facts cells here (but will not insert values)
                // console.error(`Load_Phase2to4() This (Phase3_1_Update_HOTSettings) gets called to late, should be called before Action_ApplyLRPgridCss  ${source} -> Load_Phase2to4()`)
                lr.Phase3_1_Update_HOTSettings(context.commit, `${source} -> Load_Phase2to4()`);
            }
            if (window.PGridClientDebugMode >= 2) {
                console.debug(`[Phase3_1_Update_HOTSettings] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase3_1_Update_HOTSettings) / (100)))} 10th: s`); // in seconds
            }

            let StartTime_Phase4_Insert_Metadata = Date.now();
            { // Process facts cells here (but will not insert values)
                pgridTableDyn = lr.Phase4_Insert_Metadata(pgridTableDyn, lr.AxisDefMap.FACTS, lr);
            }
            if (window.PGridClientDebugMode >= 2) {
                console.debug(`[Phase4_Insert_Metadata] LR: (${lr.name}) elapsed ${(Math.floor((Date.now() - StartTime_Phase4_Insert_Metadata) / (100)))} 10th: s`); // in seconds
            }
        }

        // console.warn(`LowestRightNonHiddenLR: ${JSON.stringify(LowestRightNonHiddenLR)}`);
        // commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: foundLR, op: 'push', source: `${source} -> FindLRCoordinates()` });
        // commit('Mutation_UpdatePGridSettings', { prop: 'LowestRightNonHiddenLR', val: LowestRightNonHiddenLR, source: `${source} -> Load_Phase2to4()` });
        if (LowestRightNonHiddenLR.y != -1) {
            context.commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenLR', val: LowestRightNonHiddenLR, source: `${source} -> Load_Phase2to4()` });
        }

        let LowestRightNonHiddenCell = {
            y: Math.max(LowestRightNonHiddenLR.y, LowestRight_Static.y),
            x: Math.max(LowestRightNonHiddenLR.x, LowestRight_Static.x)
        }

        context.commit('Mutation_UpdateRoot', { prop: 'LowestRightNonHiddenCell', val: LowestRightNonHiddenCell, source: `${source} -> Load_Phase2to4()` });


        // PGridVueStore.state.LowestRightNonHiddenLR = LowestRightNonHiddenLR;
        // * Collect any error messages

        let allErrors = Array();

        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let foundLR = foundLRs[foundLRs_Keys[i]];

            if (foundLR.errors.length > 0) {
                //Fond errors

                allErrors.push(JSON.parse(JSON.stringify({
                    linkedRange: foundLR.name,
                    x: foundLR.x,
                    y: foundLR.y,
                    errors: foundLR.errors
                })));
            }
        }


        if (allErrors.length) {
            console.warn(`Load_Phase2to4_error: Found errors in: ${JSON.stringify(allErrors, null, 2)}`);
        }

        window.PGridClientDebugMode >= 3 && console.debug(`pgridMatrices.js Load_Phase2to4() exits`);

    } catch (err) {
        let errMsg = `> Load_Phase2to4 got exception: ${err.message || err}`;
        throw new Error(errMsg);
    }

    return pgridTableDyn;

}

export function Get_LRFromTableCoordinates(y, x, pgridTableStaticOrDynamic) {

    let newLRObj = null;

    let pcell = pgridTableStaticOrDynamic[y][x];

    if (pcell && pcell.hasOwnProperty("RefType") && pcell.RefType.indexOf("{") >= 0) {

        let linkedRangeDef = null;

        try {
            linkedRangeDef = JSON.parse(pcell.RefType)
        } catch (error) {
            console.error(`Could not JSON.parse(pcell.RefType) #1:\n\npcell.RefType: "${pcell.RefType}"\n\nError: ${error.toString()}`);
        }

        if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("Type"))) {
        } else {
            console.error("Linked range definitino is missing a \"Type\"");
        }


        if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("LinkedRangeName") /*|| pCellJSON.hasOwnProperty("output") */)) {

            try {
                linkedRangeDef = JSON.parse(pcell.RefType)
            } catch (error) {
                console.error(`Could not JSON.parse(pcell.RefType) #2:\n\npcell.RefType: "${pcell.RefType}"\n\nError: ${error.toString()}`);
            }

            if (linkedRangeDef != null && ("Type" in linkedRangeDef)) {

                newLRObj = NewPGridLRTypeObj(linkedRangeDef.Type, y, x, pcell, pgridTableStaticOrDynamic)

            } else {
                console.error(`Linked range "Type" is missing in pcell.RefType: ${pcell.RefType}`)
            }
        }
    }

    return newLRObj;
}



/* Returns the LR contianing the the cell (y,x) */
export function Search_LRFromTableCoordinate(y, x, pgridTableStaticOrDynamic, context) {

    let foundLRObj = null;

    let foundLRs = null;
    foundLRs = Get_LRsFromTable(pgridTableStaticOrDynamic, { parseLR: true, context: context, source: `Search_LRFromTableCoordinate()` }); // Get all linked ranges

    if (foundLRs) {

        for (let i = 0; i < Object.keys(foundLRs).length && foundLRObj == null; i++) {

            let lrName = Object.keys(foundLRs)[i]

            let lr = foundLRs[lrName];

            if ((y >= lr.y && y < (lr.y + lr.yLength)) && (x >= lr.x && x < (lr.x + lr.xLength))) {
                foundLRObj = lr;
            }

        }

    }

    return foundLRObj;
}



/*
Function: Search for linked ranges in a static (non rendered grid)
Returns:  returs a object of linked range plus some pre render meta data (PGType_LinkedRangePreRenderMeta)
*/
//Context: server, client
export function Get_LRsFromTable(pgridData, opt = { onlyThisLR: null, parse: true, validateCache: true, source: `<Get_LRsFromTable no source specified>`, context: null }) {

    try {

        let ret = null;

        if (opt.onlyThisLR === undefined) {
            opt.onlyThisLR = null;
        }
        if (opt.parse === undefined) {
            opt.parse = true;
        }
        if (opt.validateCache === undefined) {
            opt.validateCache = true;
        }
        if (opt.source === undefined) {
            opt.source = `<Get_LRsFromTableCached() no source specified>`;
        }
        if (opt.context === undefined) {
            opt.context = null;

            if ("PGridVueStore" in window) {
                opt.context = PGridVueStore;
            }
        }

        (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX:Z Get_LRsFromTable() opt: ${JSON.stringify({ "onlyThisLR": opt.onlyThisLR, "validateCache": opt.validateCache })} source: ${opt.source}`);


        let pcellCpy = null;


        let iterations = 0;
        //Almost same as resolver.js:extract_LinkedDataSources() merge in future?


        if (pgridData == null) {
            throw new Error(`Get_LRsFromTable() pgridData == null`);
        }

        if (opt.onlyThisLR) {

            /*
            *
            * ******** SINGLE RESULT MODE ********
            *
            */

            let ret_single = null;

            // 1:st look in hints

            let foundInHints = null;;

            if (opt.context) {

                if (opt.context.state.pgridLRCoordinatesHints && opt.onlyThisLR in opt.context.state.pgridLRCoordinatesHints) {

                    foundInHints = opt.context.state.pgridLRCoordinatesHints[opt.onlyThisLR];

                    // for (let i = 0; i < opt.context.state.pgridLRCoordinatesHints.length; i++) {

                    //     let hint = opt.context.state.pgridLRCoordinatesHints[i];
                    //     if (hint.name == linkedRangeName) {
                    //         foundInHints = hint;
                    //     }
                    // }
                }
            }

            //2:nd Check if hint valud is correct
            if (opt.validateCache && !!foundInHints) {
                let pcell = lodash_get(pgridData, `[${foundInHints.y}][${foundInHints.x}]`, null);

                let cacheValid = false;
                if (!!pcell && "RefType" in pcell) {
                    let pcell_refType = JSON.parse(pcell.RefType);
                    if (opt.onlyThisLR == pcell_refType.LinkedRangeName) {
                        //Cache was valid
                        cacheValid = true;
                    }

                }

                if (cacheValid) {
                    ret_single = foundInHints;
                } else {
                    //Cache was not valid
                    (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX: Z Get_LRsFromTable() Cache is invalid`);
                    foundInHints = null;

                    if (opt.context) {
                        opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: [], source: `${opt.source} -> Get_LRsFromTable()` });
                    }
                }
            }

            if (ret_single == null) {
                //3:rd No luck with cache, Look it up using iterations
                SEACHROWS: for (let y = 0, yOffset = 0; y < pgridData.length; y++) {

                    if (pgridData[y] != null) {
                        for (let xSearch = 0; xSearch < pgridData[y].length; xSearch++) {

                            let pcell = pgridData[y][xSearch];

                            iterations++;

                            if (pcell != undefined && ("RefType" in pcell) && pcell.RefType.indexOf("{") >= 0) {
                                if (opt.parse) {

                                    if (pcell.RefType === '{}') {
                                        //Skipp processing of empty LR definition
                                        continue SEACHROWS;
                                    }

                                    let [lrName, lrObj] = ParseLR(pgridData, pcell, y, xSearch);

                                    if (!lrName) {
                                        throw new Error(`lrName was not defined`);
                                    }

                                    if (lrName == opt.onlyThisLR) {
                                        ret_single = lrObj
                                        continue SEACHROWS;
                                    }

                                } else {
                                    //Only get y,x and name
                                    let pcell_RefType = JSON.parse(pcell.RefType);
                                    let lrName = pcell_RefType.LinkedRangeName;
                                    if (lrName == opt.onlyThisLR) {
                                        // retFoundLRs[lrName] = { y: y, x: xSearch, name: lrName };
                                        ret_single = { y: y, x: xSearch, name: lrName };
                                        continue SEACHROWS;
                                    }
                                }
                            }
                        }
                    }
                }

                //Update cache
                if (!!ret_single && opt.context) {
                    let pgridLRCoordinatesHintsForCache = opt.context.state.pgridLRCoordinatesHints;
                    pgridLRCoordinatesHintsForCache[ret_single.name] = ret_single;
                    opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: pgridLRCoordinatesHintsForCache, source: `${opt.source} -> Get_LRsFromTable()` });
                }
            }


            ret = ret_single;
        } else {

            /*
            *
            * ******** MULTI RESULT MODE ********
            *
            */

            let ret_foundLRs = null;

            // 1:st look in hints

            let foundInHints = {};

            if (opt.context) {

                if (opt.context.state.pgridLRCoordinatesHints && opt.onlyThisLR in opt.context.state.pgridLRCoordinatesHints) {

                    foundInHints = opt.context.state.pgridLRCoordinatesHints;

                    // for (let i = 0; i < opt.context.state.pgridLRCoordinatesHints.length; i++) {

                    //     let hint = opt.context.state.pgridLRCoordinatesHints[i];
                    //     if (hint.name == linkedRangeName) {
                    //         foundInHints = hint;
                    //     }
                    // }
                }
            }

            //2:nd Check if hint valud is correct
            if (opt.validateCache) {

                let cacheValid = false;
                let foundInHints_Keys = Object.keys(foundInHints);
                if (foundInHints_Keys.length > 0) {
                    cacheValid = true; //True until proven false by any item
                    SEACHCACHE: for (let i = 0; i < foundInHints_Keys.length; i++) {

                        let foundInHints_item_key = foundInHints_Keys[i];
                        foundInHints_Item = foundInHints[foundInHints_item_key];

                        let pcell = lodash_get(pgridData, `[${foundInHints_Item.y}][${foundInHints_Item.x}]`, null);

                        if ("RefType" in pcell) {
                            let pcell_refType = JSON.parse(pcell.RefType);
                            if (foundInHints_Item.name == pcell_refType.LinkedRangeName) {
                                //Cache is still valid
                            } else {
                                //Cache is in-valid
                                cacheValid = false;
                                continue SEACHCACHE;
                            }
                        }
                    }
                }
                if (cacheValid) {
                    ret_foundLRs = foundInHints;
                } else {
                    //Cache was not valid
                    (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX: Z Get_LRsFromTable() Cache is invalid`);
                    foundInHints = null;

                    if (opt.context) {
                        opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: [], source: `${opt.source} -> Get_LRsFromTable()` });
                    }

                }
            }

            if (ret_foundLRs == null) {
                //3:rd No luck with cache, Look it up using iterations
                SEACHROWS: for (let y = 0, yOffset = 0; y < pgridData.length; y++) {

                    if (pgridData[y] != null) {
                        for (let xSearch = 0; xSearch < pgridData[y].length; xSearch++) {

                            let pcell = pgridData[y][xSearch];

                            iterations++;


                            if (pcell != undefined && ("RefType" in pcell) && pcell.RefType.indexOf("{") >= 0) {
                                if (opt.parse) {

                                    if (pcell.RefType === '{}') {
                                        //Skipp processing of empty LR definition
                                        continue SEACHROWS;
                                    }


                                    let [lrName, lrObj] = ParseLR(pgridData, pcell, y, xSearch);

                                    if (!lrName) {
                                        throw new Error(`lrName was not defined`);
                                    }

                                    if (ret_foundLRs == null) {
                                        ret_foundLRs = {};
                                    }
                                    ret_foundLRs[lrName] = lrObj;
                                    // ret_single = lrObj
                                } else {
                                    //Only get y,x and name
                                    let pcell_RefType = JSON.parse(pcell.RefType);
                                    let lrName = pcell_RefType.LinkedRangeName;
                                    ret_foundLRs[lrName] = { y: y, x: xSearch, name: lrName };
                                    // ret_single = { y: y, x: xSearch, name: lrName };
                                }
                            }
                        }
                    }
                }

                if (opt.context) {
                    opt.context.commit('Mutation_UpdateRoot', { prop: 'pgridLRCoordinatesHints', val: ret_foundLRs, source: `${opt.source} -> Get_LRsFromTable()` });
                }


            }


            ret = ret_foundLRs;
        }

        (window.PGridClientDebugMode >= 3 || (global && global.serverDebugMode >= 2)) && console.debug(`MUX:Z Get_LRsFromTable iterations: ${iterations}`);


        if (ret == null) {
            ret = [];
        }

        return ret;

    } catch (err) {
        let errMsg = `> Get_LRsFromTable got exception: ${err.message || err}`;
        // console.error(errMsg)
        throw new Error(errMsg);
    }
}



function ParseLR(pgridData, pcell, y, x) {

    let ret = [null, null];

    let linkedRangeDef = null;

    try {
        linkedRangeDef = JSON.parse(pcell.RefType)
    } catch (error) {
        console.error(`Could not JSON.parse(pcell.RefType)  #1: \n\npcell.RefType: "${PGridUtils.truncString(pcell.RefType, 100)}"\n\nError: ${error.toString()}`);
    }

    if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("Type"))) {
    } else {
        console.error("Linked range definition is missing a \"Type\"");
    }

    if (linkedRangeDef != null && (linkedRangeDef.hasOwnProperty("LinkedRangeName") /*|| pCellJSON.hasOwnProperty("output") */)) {

        // try {
        //     linkedRangeDef = JSON.parse(pcell.RefType)
        // } catch (error) {
        //     console.error(`Could not JSON.parse(pcell.RefType) #2: \n\npcell.RefType: "${pcell.RefType}"\n\nError: ${ error.toString() } `);
        // }

        let newLRObj = null;

        if (linkedRangeDef != null && ("Type" in linkedRangeDef)) {

            newLRObj = NewPGridLRTypeObj(linkedRangeDef.Type, y, x, pcell, pgridData)

        } else {
            console.error(`Linked range "Type" is missing in pcell.RefType: ${pcell.RefType}`)
        }

        // console.log("newLRObj.ToString(): " + newLRObj.whoAmI());

        // retFoundLRs[linkedRangeDef.LinkedRangeName] = newLRObj;
        ret = [linkedRangeDef.LinkedRangeName, newLRObj];
    }

    return ret;
}


export function Convert_pgridTableDynToPGridFactRows(pgridDataIn, pgridDim, source = `< Convert_pgridTableDynToPGridFactRows got no source > `) {

    let linkedRangesFactsCollections = {}; // = new Array();

    linkedRangesFactsCollections.Default = {
        overrideGridKey: null,
        overrideTabKey: null,
        overrideRemoveOrTranslateCellKeyDims: null,
        linkedRangesFacts: null,
    }

    linkedRangesFactsCollections["Default"].linkedRangesFacts = {}



    try {

        const foundLRs = Get_LRsFromTable(pgridDataIn, { source: `${source} -> Convert_pgridTableDynToPGridFactRows()` })

        console.debug("###################### collect facts #####################");

        let foundLRs_Keys = Object.keys(foundLRs);
        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];

            if (lr.lrIsReadOnly) {
                if (window.PGridClientDebugMode >= 1) {
                    console.debug(`Convert_pgridTableDynToPGridFactRows(): skipping write for readonly LR`);
                }
                continue;
            }

            let factsCollectName = "Default";

            if (lr.overrideGridKey != null || lr.overrideTabKey != null || lr.overrideRemoveOrTranslateCellKeyDims != null) {

                factsCollectName = "OverrideVars" + i;
                linkedRangesFactsCollections[factsCollectName] = {
                    overrideTabKey: lr.overrideTabKey,
                    overrideGridKey: lr.overrideGridKey,
                    overrideRemoveOrTranslateCellKeyDims: lr.overrideRemoveOrTranslateCellKeyDims,
                    linkedRangesFacts: {},
                    linkedRangeName: lr.name
                }
            }

            let lrFacts = lr.Phase11_Collect_FactRange(pgridDataIn, pgridDim);


            if (lrFacts !== null) {

                linkedRangesFactsCollections[factsCollectName].linkedRangesFacts[lr.name] = lrFacts;
            }

        }


        false && console.log(`linkedRangesFactsCollections: ${JSON.stringify(linkedRangesFactsCollections, null, 2)}`);
    }
    catch (err) {
        let errMsg = `> Convert_pgridTableDynToPGridFactRows() got exception: ${err.message || err}`;
        throw new Error(errMsg);
    }
    return linkedRangesFactsCollections;
}


/*
Function: Save pgrid dynamn data  to backend
*/
export async function SavePgridFactToDatabase(context, pgridDataDynCont, pgridFilter, pgridVars, pgridDim, eventArgs = {}) {

    try {
        const batchId = uuidv1().toUpperCase();
        const timestampMS = Math.round(Date.now()); //ms seconds
        const timestamp = Math.round(timestampMS / 1000); //seconds

        // This is set in uiButtonSaveFact()
        // context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: true, source: `SavePgridFactToDatabase()` });

        window.PGridClientDebugMode >= 2 && console.log(`\nNew BatchId: ${batchId}, Timestamp: ${timestamp} \n`);


        const linkedRangesFactsCollections = Convert_pgridTableDynToPGridFactRows(pgridDataDynCont, pgridDim, `SavePgridFactToDatabase()`);

        let pgridFilterSelection = PGridUtils.Filter_ExtractPGridFiltersSelection(pgridFilter, false);


        window.PGridClientDebugMode >= 2 && console.debug(`SavePgridFactToDatabase() collected facts: `, linkedRangesFactsCollections);


        let SendEvent = async function (eventType /*'AfterSaveFact' or 'BeforeSaveFact'*/, responseSavePGridFact = null) {
            try {
                let pGridEventTypeProps = { EventType: `${eventType}` }
                lodash_merge(pGridEventTypeProps, eventArgs);
                // let customProperties = lodash_get(context, `state.pgridVars.GridDefinition.Events.${ eventType }.CustomProperties`, null);
                let customProperties = lodash_get(context, `state.pgridVars.GridDefinition.Events.CustomEventProperties`, null);
                if (customProperties != null) {
                    //Make a copy for reusability
                    customProperties = JSON.parse(JSON.stringify(customProperties));
                }
                PGridDynExtract.UpdateDynProps_ExtraContext(context, { Facts: linkedRangesFactsCollections, Event: pGridEventTypeProps }, customProperties, true);
                pGridEventTypeProps.CustomProperties = customProperties;

                let responseHandlePGridFact = await HandlePGridEvent(pgridVars, pgridFilterSelection, JSON.stringify({ responseSavePGridFact }), batchId, timestampMS, JSON.stringify(pGridEventTypeProps));

                let isError = lodash_get(responseHandlePGridFact, "PGridEvent[0].Error", "");
                if (!isEmpty(isError)) {
                    console.error("SendEvent() recived error: responseHandlePGridFact: " + isError)
                    throw new Error(isError);

                }
            } catch (err) {
                let errMsg = `SendEvent() (${eventType}) recived error: ${err.message}`
                console.error(errMsg)
                throw new Error(errMsg);
            }
        }

        if (Object.keys(linkedRangesFactsCollections.Default.linkedRangesFacts).length > 0) {
            if (lodash_get(eventArgs, "SkipEventBeforeSaveFact", false) == true) {
                window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() skipping 'BeforeSaveFact'")
            } else {
                await SendEvent("BeforeSaveFact");
            }


            if (Object.keys(linkedRangesFactsCollections.Default.linkedRangesFacts).length > 0) {

                window.PGridClientDebugMode >= 2 && console.debug(`SavePGridFactToDatabase() sleeping (delay after BeforeSaveFact) ${context.state.pgridSettings.delayedAfter_BeforeSaveFact_Miliseconds} ms`);
                await PGridUtils.sleep(context.state.pgridSettings.delayedAfter_BeforeSaveFact_Miliseconds);

                window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after 'BeforeSaveFact'")

                let linkedRangesFactsCollections_keys = Object.keys(linkedRangesFactsCollections);

                for (let i = 0; i < linkedRangesFactsCollections_keys.length; i++) {
                    const pgridFactObj = linkedRangesFactsCollections[linkedRangesFactsCollections_keys[i]];
                    const pgridFactRows = pgridFactObj.linkedRangesFacts;

                    let pgridVarsCpy = JSON.parse(JSON.stringify(pgridVars)); //This works!
                    // let pgridVarsCpy = pgridVars; //This does not work 100%

                    if (pgridFactObj.overrideGridKey != null) {
                        pgridVarsCpy.GridKey = pgridFactObj.overrideGridKey;
                    }
                    if (pgridFactObj.overrideTabKey != null) {
                        pgridVarsCpy.TabKey = pgridFactObj.overrideTabKey;
                    }


                    window.PGridClientDebugMode >= 3 && console.debug("Will send the following rows to server", pgridFactRows);

                    if (pgridFactObj.overrideRemoveOrTranslateCellKeyDims != null) {
                        [pgridFilterSelection, _] = PGridUtils.HandleDimensionOverrides(context, null, pgridFilterSelection, pgridFactObj.overrideRemoveOrTranslateCellKeyDims);
                    }

                    let responseSavePGridFact;

                    try {
                        responseSavePGridFact = await SavePGridFact(pgridVarsCpy, pgridFilterSelection, pgridFactRows, batchId, timestampMS);
                        window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after SavePGridFact()")

                        let isError = lodash_get(responseSavePGridFact, "PGridSetFact[0].Error", "");
                        if (!isEmpty(isError)) {
                            console.error("SavePGridFactToDatabase() recived error: " + isError)
                            throw new Error(isError);
                        }

                    } catch (err) {
                        let errMsg = `SavePgridFactToDatabase() got exception: ${err.message || err}`
                        console.error(errMsg)
                        throw new Error(errMsg);
                    }

                }
            }




            // window.PGridClientDebugMode >= 2 && console.debug(`SavePGridFactToDatabase() sleeping (delay after save) ${context.state.pgridSettings.delayedAfter_Save_Miliseconds} ms`);
            // await PGridUtils.sleep(context.state.pgridSettings.delayedAfter_Save_Miliseconds);


            if (lodash_get(eventArgs, "SkipEventAfterSaveFact", false) == true) {
                window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() skipping 'AfterSaveFact'")
            } else {
                await SendEvent("AfterSaveFact");
            }
        }


        // let foundLRs = PGridMatrices.Get_LRsFromTableCached(pgridDataBuffer); // Get all linked ranges
        let foundLRs = Get_LRsFromTable(pgridDataDynCont, { source: `SavePgridFactToDatabase()` });

        let foundLRs_Keys = Object.keys(foundLRs);
        for (let i = 0; i < foundLRs_Keys.length; i++) {
            let lr = foundLRs[foundLRs_Keys[i]];

            // if(lr.Speci)
            if (lodash_has(lr, "linkedRange.LoadSave.OpenTableDataSet")) {


                let specialzedDataSet = lr.linkedRange.LoadSave.OpenTableDataSet;
                let specialzedData = [];

                let virtualQueryString = pgridVars.VirtualQueryString;


                lr.yLength = pgridDataDynCont[lr.y][lr.x].Meta.yLRLength;
                lr.xLength = pgridDataDynCont[lr.y][lr.x].Meta.xLRLength;

                let colDef = [];

                try {
                    /* + 2 as above header and header otherwise gets included*/
                    for (let r = lr.y; r < (lr.y + lr.yLength); r++) {

                        if (r == lr.y) {

                            let colNameRow = []
                            for (let c1 = lr.x; c1 < (lr.x + lr.xLength); c1++) {
                                colNameRow.push(pgridDataDynCont[r - 1][c1]);
                                colDef.push(pgridDataDynCont[r - 1][c1].Meta.OT);
                            }
                            specialzedData.push(colNameRow);
                        }

                        let newRow = []

                        for (let c2 = lr.x; c2 < (lr.x + lr.xLength); c2++) {


                            let pcell = pgridDataDynCont[r][c2];
                            let htCellMeta = context.state.hotRef.getCellMeta(r, c2);



                            let val = conv_pgrid2pgdataset(colDef[c2 - lr.x].colSystemSqlType, pcell, htCellMeta);


                            newRow.push(val);
                        }
                        specialzedData.push(newRow);
                    }


                    let responseSavePGridSpecializedData = await SavePGridSpecializedData(pgridVars, pgridFilterSelection, specialzedDataSet, specialzedData, virtualQueryString, batchId, timestampMS);
                } catch (error) {
                    let errMsg = `SavePGridSpecializedData() LR: ${lr.name} got exception when saving data: ${(error.stack || error)} - `;
                    // console.warn(errMsg);
                    // alert(errMsg);
                    throw new Error(errMsg);
                }

            }
        }


        //await context.dispatch("Action_Stage_Filter", { initTimestamp: context.state.pgridSettings.initOfLastQuery_Timestamp, initUrl: context.state.pgridSettings.initOfLastQuery_Url, dontLoadGrid: true, source: `SavePgridFactToDatabase()` });

        context.commit('Mutation_UpdatePGridSettings', { prop: "isSaving", val: false, source: `SavePgridFactToDatabase()` });

        window.PGridClientDebugMode >= 3 && console.debug("SavePGridFactToDatabase() after 'AfterSaveFact'")


    } catch (err) {
        let errMsg = `SavePgridFactToDatabase_error: ${err.message || err} `;
        throw new Error(errMsg);
    }

}



export function NewPGridLRTypeObj(pgridLRtype, y, x, pcell, pgridTableStatic) {
    let newLRObj = null;
    switch (pgridLRtype) {
        case "Table":
            newLRObj = new PGridLR_Table(x, y, pcell, pgridTableStatic);
            break;
        case "EPivot":
            newLRObj = new PGridLR_EPivot(x, y, pcell, pgridTableStatic);
            break;
        case "FreeCell":
            newLRObj = new PGridLR_FreeCell(x, y, pcell, pgridTableStatic);
            break;
        case "OpenTable":
            newLRObj = new PGridLR_OpenTable(x, y, pcell, pgridTableStatic);
            break;
        case "MetaCell":
            newLRObj = new PGridLR_MetaCell(x, y, pcell, pgridTableStatic);
            break;
        case "FilterCell":
            newLRObj = new PGridLR_FilterCell(x, y, pcell, pgridTableStatic);
            break;
        case "ApexCharts":
            newLRObj = new PGridLR_ApexCharts(x, y, pcell, pgridTableStatic);
            break;
        case "MasterDataGrid":
            newLRObj = new PGridLR_MasterDataGrid(x, y, pcell, pgridTableStatic);
            break;
        default:
            throw `GetSchemaForType_error: invalid PGridLRType: ${pgridLRtype} `
    }
    return newLRObj;
}

export function ApplyCssStyles(updateStyleClasses, pgridDataDyn, yStart, xStart, yLen, xLen, add = true) {

    expandIfNeeded(pgridDataDyn, yStart + yLen, xStart + xLen);

    let finalStyles = [];

    for (let yRep = 0; yRep < yLen; yRep++) {
        let y = yRep + yStart;
        if (y >= 0) {
            for (let xRep = 0; xRep < xLen; xRep++) {
                let x = xRep + xStart;

                if (x >= 0 && y >= 0) {

                    if (pgridDataDyn[y][x] == null) {
                        pgridDataDyn[y][x] = {};
                    }

                    let currentStyleClasse = [];

                    if ("Format" in pgridDataDyn[y][x]) {
                        currentStyleClasse = pgridDataDyn[y][x].Format.split(",");
                    }

                    finalStyles = lodash_clone(currentStyleClasse);

                    updateStyleClasses.forEach(sc => {

                        if (add) {
                            if (finalStyles.indexOf(sc) == -1) {
                                finalStyles.push(sc)
                            }
                        } else {
                            //Remove

                            finalStyles = finalStyles.filter(o => {
                                let ret = false;
                                if (
                                    o != sc
                                ) {
                                    ret = true;
                                } else {
                                    ret = false;
                                }
                                return ret;
                            });
                        }
                    });

                    if (finalStyles.length == 0 && !("Format" in pgridDataDyn[y][x])) {
                        //Don't set
                    }
                    else if (finalStyles.length == 0 && "Format" in pgridDataDyn[y][x]) {
                        delete pgridDataDyn[y][x].Format;
                    } else {
                        pgridDataDyn[y][x].Format = finalStyles.join(",");
                    }
                }
            }
        }


    }
}

export function FillDimPropsDown(pgridDataDyn, yStart, xStart, axisLen, axisLengthHeaders, axis = "Rows", paths) {

    if (axis == "Rows") {
        for (let y = yStart; y < yStart + axisLen; y++) {

            let latestFoundDimProp = {};

            for (let x = xStart; x < xStart + axisLengthHeaders; x++) {


                for (let p = 0; p < paths.length; p++) {
                    let path = paths[p];
                    let dimVal = lodash_get(pgridDataDyn[y][x], path, null);

                    if (String(dimVal) != "null") {
                        latestFoundDimProp[path] = dimVal;
                    } else {
                        if (path in latestFoundDimProp) { //Has a path value from an dim cell on the left side of this, will insert this

                            if (!pgridDataDyn[y][x]) {
                                pgridDataDyn[y][x] = {};
                            }
                            lodash_set(pgridDataDyn[y][x], path, latestFoundDimProp[path]);
                        }
                    }
                }
            }
        }
    }
    else {
        throw 'not implemented';
    }
}

function UpdateCellReferences(changeCellAddr, refsKeys_Old, refsKeys_New, cellsThatAreReferenced, cellsThatAreReferenced_Inv) {

    //Update cellsThatAreReferenced

    // 1st remve all references from cellsThatAreReferenced
    let cellsWithRefs = lodash_get(cellsThatAreReferenced_Inv, changeCellAddr, []); //Look in inverted obj

    if (JSON.stringify(lodash_clone(cellsWithRefs).sort()) !== JSON.stringify(lodash_clone(refsKeys_Old).sort())) {
        window.PGridClientDebugMode >= 3 && console.debug(`UpdateCellReferences() updating (but no remove yet) cell references for cell ${changeCellAddr}. (OLD) ${JSON.stringify(lodash_clone(cellsWithRefs).sort())} -> (NEW) ${JSON.stringify(lodash_clone(refsKeys_Old).sort())}`);
    }

    for (let j = 0; j < cellsWithRefs.length; j++) {
        let cellWRef = cellsWithRefs[j];
        cellsThatAreReferenced[cellWRef].splice(cellsThatAreReferenced[cellWRef].indexOf(changeCellAddr), 1); //Remove the cell reference
    }
    // 2nd remve  references from cellsThatAreReferenced_Inv
    delete cellsThatAreReferenced_Inv[changeCellAddr];

    //3rd add the reference to cellsThatAreReferenced_Inv
    cellsThatAreReferenced_Inv[changeCellAddr] = refsKeys_New;

    //4rd add the references to cellsThatAreReferenced
    for (let j = 0; j < refsKeys_New.length; j++) {
        let refsKeys_New_item = refsKeys_New[j]

        if (!cellsThatAreReferenced[refsKeys_New_item]) {
            cellsThatAreReferenced[refsKeys_New_item] = [];
        }
        cellsThatAreReferenced[refsKeys_New_item].push(changeCellAddr);
    }

}




export function InsertOverlay(lr, pgridData, hotTable, context, currentEventType = "initEvent") {
    let ret = { pgridDataInserted: null, hotTableInserted: hotTable /* not used */, hotCellsToUpdate: [], formulaWasUpdated: false };


    const DEFAULT_MERGE_STRATIGIES = ["KeepValOnEmptyValStr"];

    let lrCoord = Get_LRsFromTable(pgridData, { onlyThisLR: lr.name, source: `pgridLR_Base.js InsertOverlay() name: ${lr.name}` });

    lr.y = lrCoord.y;
    lr.x = lrCoord.x;


    let extractDynRowMeta = (y, path) => {
        let xSearch = lr.x - 1;
        let ySearch = y;
        let dimMetaVal = lodash_get(pgridData[ySearch][xSearch], path, null);
        return dimMetaVal;
    }





    let items = [];
    if (currentEventType == "preInsertEvent") {
        let overlayMatrix = lodash_get(lr.linkedRange, "OverlayMatrix", null);
        if (overlayMatrix) {

            let overlayMatrixes = [];

            if (Array.isArray(overlayMatrix) && (typeof overlayMatrix[0] != "object" || !("Matrix" in overlayMatrix[0]))) { //Is normal OverlayMatrix

                let startY = lr.y;
                let startX = lr.x;

                if ("OverlayMatrixY" in lr.linkedRange) {
                    startY = lr.linkedRange.OverlayMatrixY;
                }
                if ("OverlayMatrixX" in lr.linkedRange) {
                    startX = lr.linkedRange.OverlayMatrixX;
                }
                if ("OverlayMatrixRelY" in lr.linkedRange) {
                    startY = lr.y + lr.linkedRange.OverlayMatrixRelY;
                }
                if ("OverlayMatrixRelX" in lr.linkedRange) {
                    startX = lr.x + lr.linkedRange.OverlayMatrixRelX;
                }

                overlayMatrixes.push({ Matrix: overlayMatrix, startY: startY, startX: startX });
            }
            else { //Is array of matrix objects;
                overlayMatrix.forEach(m => {

                    let startY = 0;
                    let startX = 0;

                    if (!("Matrix" in m)) {
                        throw new Error(`missing Matrix`);
                    }
                    if ("y" in m) {
                        startY = m.y;
                    }
                    if ("x" in m) {
                        startX = m.x;
                    }
                    if ("relY" in m) {
                        startY = lr.y + m.relY;
                    }
                    if ("relX" in m) {
                        startX = lr.x + m.relX;
                    }

                    overlayMatrixes.push({ Matrix: m.Matrix, CellTmpl: m.CellTmpl, startY: startY, startX: startX })
                });
            }

            window.PGridClientDebugMode >= 3 && console.debug(`Insert overlay ${currentEventType} parsing matrix`);


            for (let om = 0; om < overlayMatrixes.length; om++) {
                let m = overlayMatrixes[om];
                let matrix = m.Matrix;
                let cellTmpl = m.CellTmpl;


                for (let mr = 0; mr < matrix.length; mr++) {
                    for (let mc = 0; mc < matrix[mr].length; mc++) {
                        let matrix_cell = matrix[mr][mc];

                        if (typeof matrix_cell == "string") {
                            if (!cellTmpl || !(matrix_cell in cellTmpl)) {
                                throw new Error(`Missing CellTmpl by name ${matrix_cell}`);
                            }
                            matrix_cell = lodash_cloneDeep(cellTmpl[matrix_cell]);
                        }

                        if (matrix_cell != null) {
                            let addr = toLabel({ index: m.startY + mr }, { index: m.startX + mc });
                            items.push({ Address: addr, CellTemplate: matrix_cell });
                        }

                    }
                }
            }
        }

        if (items.length == 0) {
            ret.pgridDataInserted = pgridData;
            return ret;
        }
    } else {
        items = lodash_get(lr.linkedRange, "Overlay.Items", []); //Find my code change place
    }


    if (currentEventType.indexOf("initEvent") != -1) {

        let preProcessFormulas = lodash_get(lr.linkedRange, "PreProcessFormulas", true);  /* WARNING! WARNING! WARNING!WARNING! WARNING! WARNING! */

        if (preProcessFormulas) {


            let StartTime_preProcessFormulas = Date.now();

            let foundLRs = null;


            let regEx = /<(REPEAT):([^\:]*):([^\:]*):([^\:]*):([^>]*)>/i;
            let regEx_if = /<(REPEATIF):([^\:]*):([^\:]*):([^\:]*):([^\:]*):([^>]*)>/i;
            let regEx_z = /\{[RC]z[+-](\d+)\}/i;
            let regEx_zsrc = /\{[RC]zs[+-](\d+)\}/i;


            for (let ins_y = 0; ins_y < pgridData.length; ins_y++) {
                if (pgridData.length > 0) {
                    for (let ins_x = 0; ins_x < pgridData[0].length; ins_x++) {

                        if (ins_y == 2 && ins_x == 25) {
                            let foo = "bar lkjflkjdsaflkjdsaflkjdsalkjf nlkdsaflkdsa";
                        }
                        let c = pgridData[ins_y][ins_x];

                        if (c != null && "Formula" in c && typeof c.Formula === 'string' && c.Formula.length > 0 && c.Formula[0] === '=') {


                            let formula = c.Formula;

                            if (foundLRs == null) {
                                foundLRs = Get_LRsFromTable(pgridData, { parseLR: true, context: context, source: `InsertOverlay()` }); // Get all linked ranges

                            }

                            //=<REPEAT:ROW:OpenTable_POC_SUMMARY:+:1+3+2+5>
                            // let regEx = /<(REPEAT):([^\:]*):([^\:]*):([^\:]*):([^>]*)>/i;
                            // let regEx_z = /\{[RC]z[+-](\d+)\}/i;
                            // let regEx_zsrc = /\{[RC]zs[+-](\d+)\}/i;

                            let result = null;
                            let result_if = null;

                            let r = null;

                            if (formula.indexOf('<REPEAT:') != -1) {
                                result = regEx.exec(formula);

                                if (result) {
                                    r =
                                    {
                                        MATCH: result[0],
                                        action: result[1],
                                        dim: String(result[2]).toLowerCase(),
                                        lrname: result[3],
                                        joinby: result[4],
                                        inner: result[5]
                                    }
                                }
                            }
                            else if (formula.indexOf('<REPEATIF:') != -1) {
                                result_if = regEx_if.exec(formula);
                                if (result_if) {
                                    r =
                                    {
                                        MATCH: result_if[0],
                                        action: result_if[1],
                                        dim: String(result_if[2]).toLowerCase(),
                                        lrname: result_if[3],
                                        joinby: result_if[4],
                                        inner_if: result_if[5],
                                        inner: result_if[6]
                                    }
                                }
                            }

                            if (r) {

                                let sourceLR = Search_LRFromTableCoordinate(ins_y, ins_x, pgridData, context);

                                let lrf = foundLRs[r.lrname];

                                if (lrf) {

                                    let lrf_y = lrf.y;
                                    let lrf_x = lrf.x;

                                    let repi_max = null;

                                    let repiOpposit_max = null;



                                    if (r.dim === "row") {
                                        repi_max = lrf.yLength;
                                        // repiOpposit_max = lrf.xLength;
                                    } else if (r.dim === "col") {
                                        repi_max = lrf.xLength;
                                        // repiOpposit_max = lrf.yLength;
                                    } else {
                                        throw new Error(`${r.dim} is not supported`);
                                    }
                                    // repiOpposit_max = 3;
                                    // repi_max = 3;

                                    repiOpposit_max = 0;
                                    {
                                        //=<REPEAT:ROW:OpenTable_POC_SUMMARY:+:1+3+2+5>
                                        let result_z = null;
                                        let newInner_iter = r.inner;

                                        if (newInner_iter.indexOf('{Cz') != -1 || newInner_iter.indexOf('{Rz') != -1) {

                                            do {
                                                result_z = regEx_z.exec(newInner_iter);
                                                if (result_z) {
                                                    // console.debug("RESULT_Z", result_z);
                                                    repiOpposit_max = Math.max(repiOpposit_max, Number(result_z[1]) + 1);
                                                    newInner_iter = newInner_iter.substring(result_z.index + result_z[0].length);
                                                }
                                            } while (result_z != null)
                                        }
                                    }



                                    let repExprs = [];

                                    for (let repi = 0; repi < repi_max; repi++) {  // Loop by rows or by columns


                                        let newInnerStd = String(r.inner);
                                        let newInner_if = String(r.inner_if);


                                        if (sourceLR) {
                                            let lrSrc_y = sourceLR.y;
                                            let lrSrc_x = sourceLR.x;

                                            let repSrc_max = null;


                                            if (r.dim === "row") {
                                                repSrc_max = sourceLR.xLength; //If row repet for every column
                                            } else if (r.dim === "col") {
                                                repSrc_max = sourceLR.yLength;
                                            } else {
                                                throw new Error(`${r.dim} is not supported`);
                                            }



                                            repSrc_max = 0;
                                            {
                                                //=<REPEAT:ROW:OpenTable_POC_SUMMARY:+:1+3+2+5>
                                                let result_zsrc = null;
                                                let newInner_iter = r.inner;
                                                if (newInner_iter.indexOf('{Czs') != -1 || newInner_iter.indexOf('{Rzs') != -1) {

                                                    do {
                                                        result_zsrc = regEx_zsrc.exec(newInner_iter);
                                                        if (result_zsrc) {
                                                            // console.debug("RESULT_ZSRC", result_zsrc);
                                                            repSrc_max = Math.max(repSrc_max, Number(result_zsrc[1]) + 1);
                                                            newInner_iter = newInner_iter.substring(result_zsrc.index + result_zsrc[0].length);
                                                        }
                                                    } while (result_zsrc != null)
                                                }
                                            }

                                            // if (result_src) {
                                            // console.debug("RESULT_SRC", result_src);
                                            /*
                                                                            let r =
                                                                            {
                                                                                MATCH: result[0],
                                                                                action: result[1],
                                                                                dim: String(result[2]).toLowerCase(),
                                                                                lrname: result[3],
                                                                                joinby: result[4],
                                                                                inner: result[5]
                                                                            }
                                            */
                                            // }

                                            for (let repSrci = 0; repSrci < repSrc_max; repSrci++) {  // Loop by rows or by columns
                                                //repi_y the repeat row, repi_x the repeat column 
                                                let repSrci_y = lrSrc_y + r.dim === "row" ? repi : 0;
                                                let repSrci_x = lrSrc_x + r.dim === "col" ? repi : 0;

                                                //repi_y the repeat row delta from ins_y to repi_y (and same for x) 
                                                let srcirel_y = (r.dim === "row" ? lrSrc_y - ins_y + repi : 0);
                                                let srcirel_x = (r.dim === "col" ? lrSrc_x - ins_x + repi : 0);


                                                // //Non relative target, but in "A1" form 
                                                // newInner = newInner.replaceAll("{ROWNR}", rowIndexToLabel(repi_y));
                                                // newInner = newInner.replaceAll("{COLLABEL}", columnIndexToLabel(repi_x));

                                                for (let j = 0; j < repSrc_max; j++) {
                                                    newInnerStd = newInnerStd.replaceAll(`{Rzs+${j}}`, `${srcirel_y - lrSrc_y + j}`);
                                                    newInner_if = newInner_if.replaceAll(`{Rzs+${j}}`, `${srcirel_y - lrSrc_y + j}`);
                                                }
                                                for (let j = 0; j < repSrc_max; j++) {
                                                    newInnerStd = newInnerStd.replaceAll(`{Rzs-${j}}`, `${srcirel_y - lrSrc_y - j}`);
                                                    newInner_if = newInner_if.replaceAll(`{Rzs-${j}}`, `${srcirel_y - lrSrc_y - j}`);
                                                }

                                                for (let j = 0; j < repSrc_max; j++) {
                                                    newInnerStd = newInnerStd.replaceAll(`{Czs+${j}}`, `${lrSrc_x - ins_x + j}`);
                                                    newInner_if = newInner_if.replaceAll(`{Czs+${j}}`, `${lrSrc_x - ins_x + j}`);
                                                }
                                                for (let j = 0; j < repSrc_max; j++) {
                                                    newInnerStd = newInnerStd.replaceAll(`{Czs-${j}}`, `${lrSrc_x - ins_x - j}`);
                                                    newInner_if = newInner_if.replaceAll(`{Czs-${j}}`, `${lrSrc_x - ins_x - j}`);
                                                }
                                            }

                                        }




                                        //repi_y the repeat row, repi_x the repeat column 
                                        let repi_y = lrf_y + (r.dim === "row" ? repi : 0);
                                        let repi_x = lrf_x + (r.dim === "col" ? repi : 0);

                                        //repi_y the repeat row delta from ins_y to repi_y (and same for x) 
                                        let irel_y = (r.dim === "row" ? lrf_y - ins_y + repi : 0);
                                        let irel_x = (r.dim === "col" ? lrf_x - ins_x + repi : 0);



                                        //Non relative target, but in "A1" form 
                                        newInnerStd = newInnerStd.replaceAll("{COLLABELi}", columnIndexToLabel(repi_x));
                                        newInnerStd = newInnerStd.replaceAll("{ROWLABELi}", rowIndexToLabel(repi_y));
                                        newInner_if = newInner_if.replaceAll("{COLLABELi}", columnIndexToLabel(repi_x));
                                        newInner_if = newInner_if.replaceAll("{ROWLABELi}", rowIndexToLabel(repi_y));

                                        //Relative to target cell "i"
                                        newInnerStd = newInnerStd.replaceAll("{Ri}", irel_y);
                                        newInnerStd = newInnerStd.replaceAll("{Ci}", irel_x);
                                        newInner_if = newInner_if.replaceAll("{Ri}", irel_y);
                                        newInner_if = newInner_if.replaceAll("{Ci}", irel_x);


                                        //Relative from repi_(y|x) i to target lr's upper left corner
                                        for (let j = 0; j < repiOpposit_max; j++) {
                                            newInnerStd = newInnerStd.replaceAll(`{Rz+${j}}`, `${irel_y - lrf_y + j}`);
                                            newInner_if = newInner_if.replaceAll(`{Rz+${j}}`, `${irel_y - lrf_y + j}`);
                                        }
                                        for (let j = 0; j < repiOpposit_max; j++) {
                                            newInnerStd = newInnerStd.replaceAll(`{Cz+${j}}`, `${lrf_x - ins_x + j}`);
                                            newInner_if = newInner_if.replaceAll(`{Cz+${j}}`, `${lrf_x - ins_x + j}`);
                                        }
                                        for (let j = 0; j < repiOpposit_max; j++) {
                                            newInnerStd = newInnerStd.replaceAll(`{Rz-${j}}`, `${irel_y - lrf_y - j}`);
                                            newInner_if = newInner_if.replaceAll(`{Rz-${j}}`, `${irel_y - lrf_y - j}`);
                                        }
                                        for (let j = 0; j < repiOpposit_max; j++) {
                                            newInnerStd = newInnerStd.replaceAll(`{Cz-${j}}`, `${lrf_x - ins_x - j}`);
                                            newInner_if = newInner_if.replaceAll(`{Cz-${j}}`, `${lrf_x - ins_x - j}`);
                                        }

                                        // for (let J = 0; J < lrf.yLength + 1; J++) {
                                        //     newInner = newInner.replaceAll(`{R+${J}}`, lrfY + (J));
                                        // }
                                        // for (let J = 0; J < lrf.xLength + 1; J++) {
                                        //     newInner = newInner.replaceAll(`{C+${J}}`, lrfX + (J));
                                        // }

                                        // newInner = newInner.replaceAll("{r0}", lrfY);
                                        // newInner = newInner.replaceAll("{c0}", lrfX);


                                        // newInner = newInner.replaceAll("{r+1}", lrfY + 1);
                                        // newInner = newInner.replaceAll("{c+1}", lrfX + 1);
                                        // newInner = newInner.replaceAll("{r+2}", lrfY + 2);
                                        // newInner = newInner.replaceAll("{c+2}", lrfX + 2);
                                        // newInner = newInner.replaceAll("{r+3}", lrfY + 3);
                                        // newInner = newInner.replaceAll("{c+3}", lrfX + 3);
                                        // newInner = newInner.replaceAll("{r-1}", lrfY - 1);
                                        // newInner = newInner.replaceAll("{c-1}", lrfX - 1);
                                        // newInner = newInner.replaceAll("{r-2}", lrfY - 2);
                                        // newInner = newInner.replaceAll("{c-2}", lrfX - 2);
                                        // newInner = newInner.replaceAll("{r-3}", lrfY - 3);
                                        // newInner = newInner.replaceAll("{c-3}", lrfX - 3);

                                        let addExpr = true;


                                        if (result_if) {
                                            addExpr = false;
                                            let inner_if_result = PGridUtils.Calculate_Cell(ins_y + ':' + ins_x, pgridData, newInner_if);
                                            //Only add if result is not 0 or empty string;
                                            if (inner_if_result.value !== 0 && inner_if_result.value !== '') {
                                                addExpr = true
                                                // repExprs.push(newInnerStd);
                                            }
                                        }

                                        /*
                                        FUB: "inner_if" can be optimized to first only calculate the "if" statement, now both newInnerStd and newInner_if is generated
                                        */

                                        if (addExpr) {
                                            repExprs.push(newInnerStd);
                                        }
                                    }

                                    let repetformula = repExprs.join(r.joinby);

                                    formula = formula.replace(r.MATCH, repetformula);

                                    // console.debug(`¤¤¤¤¤¤¤¤ final formula: ${formula}`)


                                    /*
                                    0: "<REPEAT:ROW:OpenTable_POC_SUMMARY:+:1+3+2+5>"
                                    1: "REPEAT"
                                    2: "ROW"
                                    3: "OpenTable_POC_SUMMARY"
                                    4: "+"
                                    5: "1+3+2+5"
                                    6: ">"
                                    */
                                }
                                else {
                                    let errmsg = `#REF! Could not find <REPEAT LR reference ${r.lrname}`;
                                    console.warn(errmsg);
                                    formula = errmsg
                                }
                            }

                            pgridData[ins_y][ins_x].Formula = formula;
                            // =<<REP:OpenTable_POC_SUMPROJ:ROWS::REP1>>
                        }

                    }

                }
            }

            let elapsed = Math.floor((Date.now() - StartTime_preProcessFormulas));
            if (elapsed > 500) {
                console.warn(`InsertOverlay() elapsed ${elapsed} ms`); // in ms
            }
            else if (window.PGridClientDebugMode >= 2) {
                console.debug(`InsertOverlay() elapsed ${elapsed} ms`); // in ms
            }

        }
    }


    for (let oIdx = 0; oIdx < items.length; oIdx++) {

        let itm = items[oIdx];

        let Description = null; //The overlay item description

        try {


            let Name = lodash_get(itm, "Name", null);
            Description = lodash_get(itm, "Description", null);
            let MergeStrategies = lodash_get(itm, "MergeStrategies", DEFAULT_MERGE_STRATIGIES);


            let Disabled = lodash_get(itm, "Disabled", false);


            let CustomRepeatReplace = lodash_clone(lodash_get(itm, "RepeatReplace", [{}]));

            for (let crr_idx = 0; crr_idx < CustomRepeatReplace.length; crr_idx++) {

                let rr = CustomRepeatReplace[crr_idx];

                let CellTemplate = lodash_clone(lodash_get(itm, "CellTemplate", null));
                let PGridRowProps = lodash_clone(lodash_get(itm, "PGridRowProps", null));
                let FormatClassesRemove = lodash_clone(lodash_get(itm, "FormatClassesRemove", null));
                let FormatClassesAdd = lodash_clone(lodash_get(itm, "FormatClassesAdd", null));
                let ReportAttest = lodash_clone(lodash_get(itm, "ReportAttest", null));
                let HotCellBehavior = lodash_clone(lodash_get(itm, "HotCellBehavior", null));

                if (CellTemplate || ReportAttest || HotCellBehavior || FormatClassesRemove || FormatClassesAdd) {

                    let customReapeatForEach = null;


                    if (customReapeatForEach == null) {
                        // FUU let ForEachCount = lodash_clone(lodash_get(rr, "ForEachCount", null));
                        let ForEachCount = lodash_get(rr, "ForEachCount", null);

                        if (ForEachCount) {

                            let ForEachCountStr = JSON.stringify(ForEachCount);
                            if (ForEachCountStr.indexOf('[<<') != -1) {
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<GRID_TABLE_EDGE_Y>>]', pgridData.length);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<GRID_TABLE_EDGE_X>>]', pgridData[0].length);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_Y>>]', lr.y);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_X>>]', lr.x);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_DATA_Y>>]', lr.y + lr.yLengthHeaders);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_DATA_X>>]', lr.x + lr.xLengthHeaders);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_LENGTH_Y>>]', lr.yLength);
                                ForEachCountStr = ForEachCountStr.replaceAll('[<<LR_LENGTH_X>>]', lr.xLength);
                                ForEachCount = JSON.parse(ForEachCountStr);
                            }

                            customReapeatForEach = [];

                            let start = ForEachCount.Start;
                            let count = ForEachCount.Count;
                            let max = ForEachCount.Max;

                            for (let nIdx = 0; max ? Number(start) + nIdx <= Number(max) : nIdx < Number(count); nIdx++) {

                                customReapeatForEach.push(Number(start) + nIdx);
                            }
                        }
                    }

                    if (customReapeatForEach == null) {
                        customReapeatForEach = lodash_clone(lodash_get(rr, "ForEach", [null]));
                    }

                    // console.log(`Got this fine result for targetCellCoord (${itm.Address}) = ${JSON.stringify(targetCellCoord)}`);

                    let CellTemplate_tmpl = null;
                    let PGridRowProps_tmpl = null;


                    //CellTemplate_tmpl and PGridRowProps_tmplis used insiede forEach loop
                    if (CellTemplate) {
                        CellTemplate_tmpl = JSON.stringify(CellTemplate);
                    }
                    if (PGridRowProps) {
                        PGridRowProps_tmpl = JSON.stringify(PGridRowProps);
                    }

                    for (let crfe_idx = 0; crfe_idx < customReapeatForEach.length; crfe_idx++) {


                        let crfe = customReapeatForEach[crfe_idx];


                        let ruleApprove_ALL_RESULTS = [];
                        let ruleApprove_ALL_OUTCOME = null;
                        // let ruleApprove = false;

                        if (Disabled) {
                            ruleApprove_ALL_OUTCOME = false;
                        }
                        else {


                            //Collect rules. One (zero) or Many

                            //Many
                            let rules = lodash_get(itm, "Rules", []);

                            // One (or zero)
                            if (rules.length == 0) {
                                let rule = lodash_get(itm, "Rule", null); //If single rule

                                rules = [rule]; //Also add "null" rules
                            }

                            for (let ru = 0; rules.length > ru; ru++) {

                                let rule = rules[ru];

                                let ruleApprove = false;


                                if (currentEventType.indexOf("initEvent") != -1) {
                                    ruleApprove = true; //If currentEventType is initEvent approve "null" rules, but only then
                                }
                                if (currentEventType.indexOf("preInsertEvent") != -1) {
                                    //Register cellsThatAreReferencingLinkedRangeOverlays on init

                                    ruleApprove = true; //Default apply on preInsertEvent
                                }

                                if (rule) {

                                    rule = JSON.parse(JSON.stringify(rule).replaceAll(rr.Replace, crfe));


                                    let Rule__If_RowMeta = lodash_clone(lodash_get(rule, "If_RowMeta", undefined));
                                    let Rule__If_RowMeta__Path = lodash_clone(lodash_get(rule, "If_RowMeta.Path", undefined));
                                    let Rule__If_RowMeta__Equals = lodash_clone(lodash_get(rule, "If_RowMeta.Equals", undefined));
                                    let Rule__If_RowMeta__LargerThan = lodash_clone(lodash_get(rule, "If_RowMeta.LargerThan", undefined));


                                    let If_Cell_Value_Is_True = lodash_get(rule, "If_Cell_Value_Is_True", null);
                                    let If_Cell_Value_Is_True_Or_Anything = lodash_get(rule, "If_Cell_Value_Is_True_Or_Anything", false);

                                    let If_Cell_Value_Is_True_Inverse = lodash_get(rule, "If_Cell_Value_Is_True_Inverse", false);

                                    let If_Cell_Is_Selected = lodash_get(rule, "If_Cell_Is_Selected", null);
                                    let If_Cell_Is_Selected_Inverse = lodash_get(rule, "If_Cell_Is_Selected_Inverse", false);

                                    let If_Grid_Have_Unsaved_Facts = lodash_get(rule, "If_Grid_Have_Unsaved_Facts", null);
                                    let If_Grid_Have_Unsaved_Facts_Inverse = lodash_get(rule, "If_Grid_Have_Unsaved_Facts_Inverse", null);


                                    let If_Cell_Value_Is_True__CellToExamine = null;
                                    let If_Cell_Is_Selected__CellToExamine = null;
                                    if (currentEventType.indexOf("initEvent") != -1) {
                                        //Register cellsThatAreReferencingLinkedRangeOverlays on init

                                        ruleApprove = true; //Default apply on init

                                        //If_Cell_Calue_Is_True
                                        if (If_Cell_Value_Is_True) {
                                            If_Cell_Value_Is_True__CellToExamine = PGridUtils.extractDynAddr(lr, pgridData, If_Cell_Value_Is_True);
                                            context.commit('Mutation_UpdatePGridSettings', {
                                                prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                , op: 'addkey'
                                                , key: `overlay_postcalc:${If_Cell_Value_Is_True__CellToExamine.y}:${If_Cell_Value_Is_True__CellToExamine.x}`
                                                , mergeValue: true
                                                , val: [`${lr.name}:${lr.y}:${lr.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                            });
                                        }

                                        //If_Cell_Is_Selected
                                        if (If_Cell_Is_Selected) {
                                            If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(lr, pgridData, If_Cell_Is_Selected);
                                            context.commit('Mutation_UpdatePGridSettings', {
                                                prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                , op: 'addkey'
                                                , key: `overlay_selectionchange:${If_Cell_Is_Selected__CellToExamine.y}:${If_Cell_Is_Selected__CellToExamine.x}`
                                                , mergeValue: true
                                                , val: [`${lr.name}:${lr.y}:${lr.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                            });
                                        }

                                        if (If_Grid_Have_Unsaved_Facts) {
                                            context.commit('Mutation_UpdatePGridSettings', {
                                                prop: 'cellsThatAreReferencingLinkedRangeOverlays'
                                                , op: 'addkey'
                                                , key: `overlay_postcalc:-1:-1`
                                                , mergeValue: true
                                                , val: [`${lr.name}:${lr.y}:${lr.x}`], source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                            });

                                            context.commit('Mutation_UpdatePGridSettings', {
                                                prop: 'attestRequired'
                                                , val: true
                                                , source: `currentEventType ${currentEventType} > PGridLR_EPivot Phase8_InsertOverlay()`
                                            });
                                        }

                                    } else {
                                        ruleApprove = false;
                                    }



                                    if (If_Cell_Value_Is_True) {

                                        ruleApprove = false;


                                        if (currentEventType.indexOf("postcalcEvent") != -1) {

                                            if (If_Cell_Value_Is_True__CellToExamine == null) {
                                                If_Cell_Value_Is_True__CellToExamine = PGridUtils.extractDynAddr(lr, pgridData, If_Cell_Value_Is_True);
                                            }


                                            let cellValue = hotTable[If_Cell_Value_Is_True__CellToExamine.y][If_Cell_Value_Is_True__CellToExamine.x];

                                            if (PGridUtils.IsNumeric(cellValue) && Number(cellValue) > 0) {
                                                ruleApprove = true;
                                            } else {
                                                if (cellValue == "Ja" || cellValue == "Yes" || cellValue == "True"
                                                ) {
                                                    ruleApprove = true;
                                                }
                                            }

                                            if (If_Cell_Value_Is_True_Or_Anything) {
                                                ruleApprove = !!cellValue;
                                            }
                                            if (If_Cell_Value_Is_True_Inverse) {
                                                ruleApprove = !ruleApprove;
                                            }
                                        }



                                    }


                                    if (currentEventType.indexOf("selectionchangeEvent") != -1 && If_Cell_Is_Selected) {

                                        ruleApprove = false;


                                        let lastSel = null;
                                        if (context.state.lastSelected) {
                                            lastSel = JSON.parse(context.state.lastSelected)

                                            if (If_Cell_Is_Selected__CellToExamine == null) {
                                                If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(lr, pgridData, If_Cell_Is_Selected);
                                            }

                                            if (lastSel.y == If_Cell_Is_Selected__CellToExamine.y && lastSel.x == If_Cell_Is_Selected__CellToExamine.x) {
                                                ruleApprove = true;
                                            }

                                            if (If_Cell_Is_Selected_Inverse) {
                                                ruleApprove = !ruleApprove;
                                            }
                                        }

                                    }

                                    if (currentEventType.indexOf("unsavedfactsEvent") != -1 && If_Grid_Have_Unsaved_Facts) {

                                        ruleApprove = false;


                                        if (context.state.pgridSettings.attestStatusOK == false) {
                                            ruleApprove = true;
                                        }

                                        if (If_Grid_Have_Unsaved_Facts_Inverse) {
                                            ruleApprove = !ruleApprove;
                                        }
                                    }



                                    //Not sure if currentEventType.indexOf("selectionchangeEvent") != -1 && ....
                                    if (Rule__If_RowMeta) {

                                        ruleApprove = false;


                                        if (Rule__If_RowMeta__Path != undefined) {
                                            let metaPathVal = extractDynRowMeta(crfe - 1/*acume crfe is the row*/, Rule__If_RowMeta__Path);

                                            if (Rule__If_RowMeta__Equals != undefined) {

                                                if (String(Rule__If_RowMeta__Equals) == String(metaPathVal)) {
                                                    ruleApprove = true;
                                                }
                                            } else if (Rule__If_RowMeta__LargerThan != undefined) {
                                                if (PGridUtils.IsNumeric(metaPathVal) && (Number(metaPathVal) > Number(Rule__If_RowMeta__LargerThan))) {
                                                    ruleApprove = true;
                                                }
                                            }
                                        }

                                        // if (!ruleApprove) {
                                        //     //Did not pass Rule__If_RowMeta resolved ruleApprove == false
                                        //     return;
                                        // }
                                    }

                                } //end if(rule)


                                ruleApprove_ALL_RESULTS.push(ruleApprove);

                            } //end of for rules


                            ruleApprove_ALL_OUTCOME = ruleApprove_ALL_RESULTS.every(x => x === true);
                        }

                        window.PGridClientDebugMode >= 3 && console.debug(`Insert overlay ${crfe_idx} ${currentEventType} item: ${Name}, ${Description}  noRuleFaild: ${ruleApprove_ALL_OUTCOME} `);


                        if (true) {
                            if (ReportAttest && currentEventType.indexOf("postcalcEvent") != -1 /* assumes If_Cell_Value_Is_True */) {
                                //Handle attest data

                                context.commit('Mutation_UpdatePGridSettings', {
                                    prop: 'attestStatusOK'
                                    , op: 'set'
                                    , val: ruleApprove_ALL_OUTCOME
                                });
                            }

                            if (!ruleApprove_ALL_OUTCOME) {
                                //Did not pass the above tests;
                                continue;
                            }

                            // If_Cell_Is_Selected__CellToExamine = PGridUtils.extractDynAddr(lr, pgridData, If_Cell_Is_Selected);

                            if (HotCellBehavior && currentEventType.indexOf("selectionchangeEvent") != -1 /* assumes If_Cell_Value_Is_True */) {
                                //Handle attest data

                                // If_Cell_Is_Selected
                                // if (row == row2 && column == column2) {
                                //     if (context.state.hotRef.getActiveEditor != null) {
                                // actEditor = context.state.hotRef.getActiveEditor();
                                if (lodash_get(HotCellBehavior, "EnableFullEditor", false)) {
                                    context.state.hotRef.getActiveEditor().enableFullEditMode();
                                    context.state.hotRef.getActiveEditor().beginEditing();
                                }
                                //     }
                                // }
                            }


                            if (CellTemplate || PGridRowProps) {

                                try {

                                    //CellTemplate

                                    // let CellTemplate_tmpl = JSON.stringify(CellTemplate);

                                    let targetAddresses = itm.Address.split(",");

                                    let targetAddressOffsetYOverrride = lodash_get(itm, "AddressOffsetYOverrride", null);
                                    let targetAddressOffsetXOverrride = lodash_get(itm, "AddressOffsetXOverrride", null);

                                    //FUB Put into general translate function?
                                    if (targetAddressOffsetYOverrride != null) {
                                        if (!PGridUtils.IsNumeric(targetAddressOffsetYOverrride)) {
                                            targetAddressOffsetYOverrride = JSON.parse(JSON.stringify(targetAddressOffsetYOverrride).replaceAll('[<<SAFE_OFFSET_DISTANCE_Y>>]', lr.ySafeOffsetDistance));

                                            if (!PGridUtils.IsNumeric(targetAddressOffsetYOverrride)) {
                                                throw new Error(`InsertOverlay() got exception: Could not parse targetAddressOffsetYOverrride number from: ${targetAddressOffsetYOverrride} `);
                                            }
                                            targetAddressOffsetYOverrride = Number(targetAddressOffsetYOverrride);
                                        }
                                    }

                                    //FUB Put into general translate function?
                                    if (targetAddressOffsetXOverrride != null) {
                                        if (!PGridUtils.IsNumeric(targetAddressOffsetXOverrride)) {
                                            targetAddressOffsetXOverrride = JSON.parse(JSON.stringify(targetAddressOffsetXOverrride).replaceAll('[<<SAFE_OFFSET_DISTANCE_X>>]', lr.xSafeOffsetDistance));

                                            if (!PGridUtils.IsNumeric(targetAddressOffsetXOverrride)) {
                                                throw new Error(`InsertOverlay() got exception: Could not parse targetAddressOffsetXOverrride number from: ${targetAddressOffsetXOverrride} `);
                                            }
                                            targetAddressOffsetXOverrride = Number(targetAddressOffsetXOverrride);
                                        }
                                    }


                                    for (let ta_idx = 0; ta_idx < targetAddresses.length; ta_idx++) {

                                        let targetAddress = targetAddresses[ta_idx];

                                        let CellTemplate_acc = CellTemplate_tmpl;
                                        let PGridRowProps_acc = PGridRowProps_tmpl;

                                        if (crfe != null) {

                                            //Address
                                            targetAddress = targetAddress.replaceAll(rr.Replace, crfe);

                                            let InsertInFormulaByTheseRows = [null];  //Always one "empty" row at least

                                            {
                                                let InnerRepeatRows = lodash_clone(lodash_get(itm, "InnerRepeatRows", null));

                                                //Fill up InserByRows if InnerRepeatRows if definded

                                                if (InnerRepeatRows) {

                                                    let Rule__If_RowMeta = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rule.If_RowMeta", undefined));
                                                    let Rule__If_RowMeta__Path = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rule.If_RowMeta.Path", undefined));
                                                    let Rule__If_RowMeta__Equals = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rule.If_RowMeta.Equals", undefined));
                                                    let Rule__If_RowMeta__Not_Equals = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rule.If_RowMeta.Not_Equals", undefined));
                                                    let Rows__Start_After_Row_Equals = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rows.Start_After_Row_Equals", undefined));
                                                    let Rows__Start_After_Row_Match = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rows.Start_After_Row_Match", undefined));
                                                    let Rows__Stop_Before_Row_Equals = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rows.Stop_Before_Row_Equals", undefined));
                                                    let Rows__Stop_Before_Row_Match = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rows.Stop_Before_Row_Match", undefined));
                                                    let Rows__Stop_RepeatRows_On_NoMatch = lodash_clone(lodash_get(itm, "InnerRepeatRows.Rows.Stop_RepeatRows_On_NoMatch", undefined));

                                                    let First_NonValid_Found = false;

                                                    InsertInFormulaByTheseRows = [];

                                                    let innerRepeatIsActive = true;

                                                    if (Rows__Start_After_Row_Equals != undefined || Rows__Start_After_Row_Match != undefined) {
                                                        innerRepeatIsActive = false
                                                    }

                                                    for (let inRepIdx = 0; inRepIdx < lr.yLength && First_NonValid_Found == false; inRepIdx++) { //For all rows (i)

                                                        let iterY = lr.y + inRepIdx;

                                                        if (innerRepeatIsActive) {

                                                            let metaPathVal = extractDynRowMeta(iterY, "Meta.DimVal", null);
                                                            if (metaPathVal != null) {
                                                                if (Rows__Stop_Before_Row_Match != undefined
                                                                    ? (new RegExp(Rows__Stop_Before_Row_Match)).test(`[${String(metaPathVal)}]`)
                                                                    : String(Rows__Stop_Before_Row_Equals) == `[${String(metaPathVal)}]`) {
                                                                    innerRepeatIsActive = false;
                                                                    continue; //The "Before" row equals
                                                                }
                                                            }

                                                            let ruleGo = true;
                                                            let ruleForceNoGo = false;
                                                            let ruleStopOnFirstNoGo = false;

                                                            if (Rule__If_RowMeta) {

                                                                if (Rule__If_RowMeta__Path != undefined) {
                                                                    let metaPathVal = extractDynRowMeta(iterY, Rule__If_RowMeta__Path);

                                                                    ruleGo = true;
                                                                    if (Rule__If_RowMeta__Equals != undefined) {

                                                                        if (String(Rule__If_RowMeta__Equals) == String(metaPathVal)) {
                                                                            ruleGo = true;
                                                                        } else {
                                                                            ruleGo = false;
                                                                        }
                                                                    }

                                                                    if (Rule__If_RowMeta__Not_Equals != undefined) {

                                                                        if (String(Rule__If_RowMeta__Not_Equals) != String(metaPathVal)) {
                                                                            ruleGo = true;
                                                                        } else {
                                                                            ruleGo = false;
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            if (ruleGo/* && ruleForceNoGo == false*/) {
                                                                InsertInFormulaByTheseRows.push(lr.y + inRepIdx); //Rows to process for formula
                                                            } else {
                                                                if (Rows__Stop_RepeatRows_On_NoMatch) {
                                                                    First_NonValid_Found = true;
                                                                }
                                                            }
                                                        }
                                                        else {

                                                            let metaPathVal = extractDynRowMeta(iterY, "Meta.DimVal", null);
                                                            if (metaPathVal != null) {
                                                                if (Rows__Stop_Before_Row_Match != undefined
                                                                    ? (new RegExp(Rows__Start_After_Row_Match)).test(`[${String(metaPathVal)}]`)
                                                                    : String(Rows__Start_After_Row_Equals) == `[${String(metaPathVal)}]`) {
                                                                    innerRepeatIsActive = true;
                                                                }

                                                            }

                                                        }
                                                    }

                                                }
                                            }

                                            //Formula

                                            let RepeatRowsReplaceWithRow = lodash_clone(lodash_get(itm, "InnerRepeatRows.ReplaceWithRow", "<<ROW>>"));
                                            let RepeatRowsInsertBefore = lodash_clone(lodash_get(itm, "InnerRepeatRows.InsertBefore", "<<INSERT>>"));
                                            let RepeatRowsInsertTemplate = lodash_clone(lodash_get(itm, "InnerRepeatRows.InsertTemplate", "+<<COLUMN>><<ROW>>"));


                                            for (let iifbtr_idx = 0; iifbtr_idx < InsertInFormulaByTheseRows.length; iifbtr_idx++) {


                                                let rowForFormula = InsertInFormulaByTheseRows[iifbtr_idx];


                                                if (rowForFormula != null) {

                                                    let indexOfInsert = CellTemplate_acc.indexOf(RepeatRowsInsertBefore);

                                                    if (indexOfInsert != -1) {

                                                        let insertStr = RepeatRowsInsertTemplate.replaceAll(RepeatRowsReplaceWithRow, rowForFormula + 1) //Replace <<ROW>> to Excel style row number

                                                        CellTemplate_acc = PGridUtils.spliceSplit(CellTemplate_acc, indexOfInsert, 0, insertStr);
                                                    }
                                                }
                                            }


                                            if (RepeatRowsInsertBefore) {
                                                if (CellTemplate) {
                                                    CellTemplate_acc = CellTemplate_acc.replaceAll(RepeatRowsInsertBefore, '');
                                                }
                                                if (PGridRowProps) {
                                                    PGridRowProps_acc = PGridRowProps_acc.replaceAll(RepeatRowsInsertBefore, '');
                                                }
                                            }

                                            //The "normal custom repeat stuff"
                                            if (CellTemplate) {
                                                CellTemplate_acc = CellTemplate_acc.replaceAll(rr.Replace, crfe); //Custom reapeat replaces, usually columns
                                            }
                                            if (PGridRowProps) {
                                                PGridRowProps_acc = PGridRowProps_acc.replaceAll(rr.Replace, crfe); //Custom reapeat replaces, usually rows
                                            }
                                        }

                                        //Parse back the string modified cell object
                                        if (CellTemplate) {
                                            CellTemplate = JSON.parse(CellTemplate_acc);
                                        }
                                        if (PGridRowProps) {
                                            PGridRowProps = JSON.parse(PGridRowProps_acc);
                                        }


                                        if (CellTemplate) {


                                            //Extract dynamic target cell address and dynamic references in CellTemplate
                                            //Allow ranges in this form "A1:B2"
                                            let startCell = null;
                                            let endCell = null;

                                            if (targetAddress.indexOf(":") != -1) {

                                                try {
                                                    startCell = PGridUtils.extractDynAddr(lr, pgridData, targetAddress.split(":")[0]);
                                                    endCell = PGridUtils.extractDynAddr(lr, pgridData, targetAddress.split(":")[1], startCell);
                                                } catch (err) {
                                                    throw new Error(`InsertOverlay() got exception: Could not find targetAddres: ${targetAddress} `);
                                                }

                                                if (startCell.y > endCell.y) { throw new Error("End cell y is smaller than start cells") };
                                                if (startCell.x > endCell.x) { throw new Error("End cell x is smaller than start cells") };

                                            }
                                            else {
                                                //Single cell range in this form "A1"
                                                startCell = endCell = PGridUtils.extractDynAddr(lr, pgridData, targetAddress);
                                            }

                                            for (let yIter = startCell.y; yIter < endCell.y + 1; yIter++) {

                                                for (let xIter = startCell.x; xIter < endCell.x + 1; xIter++) {


                                                    let targetCell = { y: yIter, yOffset: 0, x: xIter, xOffset: 0 };
                                                    let targetCell_FormatOfSaveHasChanged = false;

                                                    if (targetAddressOffsetYOverrride != null) {
                                                        targetCell.yOffset = targetAddressOffsetYOverrride;
                                                    }
                                                    if (targetAddressOffsetXOverrride != null) {
                                                        targetCell.xOffset = targetAddressOffsetXOverrride;
                                                    }

                                                    let finalCell_y = targetCell.y + targetCell.yOffset
                                                    let finalCell_x = targetCell.x + targetCell.xOffset

                                                    let originalTemplateFormula = null;
                                                    if ("Formula" in CellTemplate) {

                                                        originalTemplateFormula = CellTemplate.Formula;

                                                        if (originalTemplateFormula != null && (originalTemplateFormula.indexOf("[") != -1 || originalTemplateFormula.indexOf("{") != -1)) {
                                                            CellTemplate.Formula = PGridUtils.replaceAllDynAddr(lr, pgridData, originalTemplateFormula);
                                                        }

                                                        false && console.log(`Got this fine result for targetCellCoord(${targetAddress}) = ${JSON.stringify(targetCell)}  ${originalTemplateFormula} => ${CellTemplate.Formula} `);
                                                    }



                                                    PGridCell.expandIfNeeded(pgridData, targetCell.y, targetCell.x, undefined);
                                                    let old_cell = lodash_clone(pgridData[targetCell.y][targetCell.x]);
                                                    // let hotCellVal_old = PGridCell.getHotValue2(old_cell);
                                                    let merged_cell = PGridCell.mergeCells(pgridData[targetCell.y][targetCell.x], CellTemplate, MergeStrategies);
                                                    // let hotCellVal_examineNew = PGridCell.getHotValue2(merged_cell);





                                                    if (false) {//Never used
                                                        if (UpdateHotData) {
                                                            true && console.debug(`Phase8_InsertOverlay() updating hot data(${targetCell.y}: ${targetCell.x})`)

                                                            let hotCellVal = PGridCell.getHotValue2(merged_cell);
                                                            ret.hotCellsToUpdate.push([targetCell.y + targetCell.yOffset, targetCell.x + targetCell.xOffset, hotCellVal]);

                                                            PGridCell.expandHOTIfNeeded(hotTable, targetCell.y + targetCell.yOffset, targetCell.x + targetCell.xOffset, undefined);
                                                            hotTable[targetCell.y + targetCell.yOffset][targetCell.x + targetCell.xOffset] = hotCellVal

                                                            ret.hotTableInserted = hotTable; //This incicates to caller that data was inserted to HOT
                                                        }
                                                    }

                                                    let formulaReferenseText = null


                                                    if (window.PGridClientDebugMode >= 3) {
                                                        formulaReferenseText = `Overlay(${window.PGridDTC.CallCount}): `;
                                                    } else {
                                                        formulaReferenseText = `Overlay: `;
                                                    }

                                                    if (Description || originalTemplateFormula) {
                                                        if (Description) {
                                                            formulaReferenseText += `${Description} `;
                                                        }

                                                        if (originalTemplateFormula) {
                                                            if (Description) {
                                                                formulaReferenseText += `: \n`;
                                                            }
                                                            formulaReferenseText += `${originalTemplateFormula} `;
                                                        }
                                                    }

                                                    if (formulaReferenseText) {
                                                        let currentReferences = lodash_get(pgridData[targetCell.y][targetCell.x], "Meta.References", []);

                                                        function RemoveRefCountingFast(inRefText) {
                                                            let ret = inRefText;
                                                            if (ret.indexOf("):") != -1) {
                                                                ret.indexOf("(") != -1 && ret.indexOf("(") < ret.indexOf("):")

                                                                ret = [ret.split("(")[0], ret.split(")")[1]]
                                                            }
                                                            return ret;
                                                        }

                                                        let formulaReferenseText_Match = RemoveRefCountingFast(formulaReferenseText)

                                                        let foundExistingSimilarRefText = -1;

                                                        for (let ri = 0; ri < currentReferences.length && foundExistingSimilarRefText == -1; ri++) {
                                                            let ri_ref = currentReferences[ri];
                                                            let matchLeft = ri_ref.indexOf(formulaReferenseText_Match[0]);
                                                            let matchRight = ri_ref.indexOf(formulaReferenseText_Match[1]);
                                                            if (matchLeft != -1 && matchRight != -1 && matchLeft < matchRight) {
                                                                foundExistingSimilarRefText = ri;
                                                            }
                                                        }

                                                        // if (currentReferences.indexOf(formulaReferenseText_Match) == -1) {
                                                        if (foundExistingSimilarRefText > -1) {
                                                            currentReferences = currentReferences.splice(foundExistingSimilarRefText, 1)
                                                        }
                                                        if (foundExistingSimilarRefText == -1) {
                                                            currentReferences.push(formulaReferenseText);
                                                        }

                                                        lodash_set(merged_cell, "Meta.References", currentReferences);
                                                    }

                                                    // if(finalCell_y == 22 && finalCell_x == 18){
                                                    //     let foo = "stop here"
                                                    // }

                                                    if (finalCell_y > 15) {
                                                        let foo = "bar";
                                                    }

                                                    PGridCell.expandIfNeeded(pgridData, finalCell_y, finalCell_x, undefined);
                                                    pgridData[finalCell_y][finalCell_x] = merged_cell; //lodash_merge(pgridDataDyn[targetCell.y][targetCell.x], CellTemplate);
                                                    /*
     
                                                                                                            let isOutsideLowestRightNonHiddenCell = function (LowestRightNonHiddenCell, y, x) {
                                                                                                                let ret = false;
                                                                                                                if (context.state.LowestRightNonHiddenCell && context.state.LowestRightOptimization
                                                                                                                    &&
                                                                                                                    (
                                                                                                                        targetCell.y > context.state.LowestRightNonHiddenCell.y
                                                                                                                        ||
                                                                                                                        targetCell.x > context.state.LowestRightNonHiddenCell.x
                                                                                                                    )
                                                                                                                ) {
                                                                                                                    ret = true;
                                                                                                                }
     
                                                                                                                return ret;
                                                                                                            }
                                                    */

                                                    if (
                                                        /*isOutsideLowestRightNonHiddenCell(context.state.LowestRightNonHiddenCell, targetCell.y, targetCell.x)*/
                                                        IsOutsideLowestRight(targetCell.y, targetCell.x)
                                                    ) {
                                                        //Skipp out of counds HOT cell
                                                    }
                                                    else {

                                                        //After Handle add and remove classes on merged_cell object (linked above to pgridData)*/

                                                        if (FormatClassesAdd) {

                                                            for (let d = 0; d < FormatClassesAdd.length; d++) {

                                                                let formatClass = FormatClassesAdd[d];


                                                                if (!("Format" in merged_cell)) {
                                                                    merged_cell.Format = formatClass; //Just add
                                                                } else {
                                                                    let CellTemplate_acc_Format = merged_cell.Format.split(",");

                                                                    if (CellTemplate_acc_Format.indexOf(formatClass) == -1) {
                                                                        window.PGridClientDebugMode > 3 && console.debug(`InsertOverlay() adding class:  ${formatClass} `)
                                                                        CellTemplate_acc_Format.push(formatClass);
                                                                    }

                                                                    merged_cell.Format = CellTemplate_acc_Format.join(",");
                                                                }
                                                            }
                                                        }


                                                        if (FormatClassesRemove) {

                                                            for (let d = 0; d < FormatClassesRemove.length; d++) {

                                                                let formatClass = FormatClassesRemove[d];

                                                                if ("Format" in merged_cell) {
                                                                    let CellTemplate_Format = merged_cell.Format.split(",");

                                                                    let q = CellTemplate_Format.length
                                                                    while (q--) {
                                                                        let CellTemplate_Format_class = CellTemplate_Format[q];
                                                                        if (CellTemplate_Format_class === formatClass) {
                                                                            window.PGridClientDebugMode > 3 && console.debug(`InsertOverlay() removeing class:  ${formatClass} `)
                                                                            CellTemplate_Format.splice(q, 1);
                                                                        }
                                                                    }

                                                                    merged_cell.Format = CellTemplate_Format.join(",");
                                                                }
                                                            }
                                                        }




                                                        function getNested(obj, ...args) {
                                                            return args.reduce((obj, level) => obj && obj[level], obj)
                                                        }

                                                        // let addOrUpdateToHotCellsToUpdate = function (hotCellsToUpdate, newY, newX, newVal) {

                                                        //     let foundCurrent = hotCellsToUpdate.find(o => {
                                                        //         // let [oy, ox, oval] = o; // .split(":");
                                                        //         return o[0] == newY && o[1] == newX;
                                                        //     });
                                                        //     if (foundCurrent) {
                                                        //         let [fy, fx, fval] = foundCurrent;

                                                        //         if (fval != newVal) {
                                                        //             console.warn(`fval != refValue`);
                                                        //             hotCellsToUpdate = hotCellsToUpdate.filter(q => q[0] == newY && q[1] == newX);
                                                        //         }
                                                        //     }

                                                        //     hotCellsToUpdate.push([newY, newX, newVal]);

                                                        // }

                                                        function RecurseAddRefCells(cellsThatAreReferenced, hotCellsToUpdate, y, x, val, depth = 0) {


                                                            PGridCell.addOrUpdateToHotCellsToUpdate(hotCellsToUpdate, y, x, val);


                                                            let cellRefKey = y + ":" + x;
                                                            // let cellsThatAreReferencedKeyList = Object.keys(context.state.pgridSettings.cellsThatAreReferenced);
                                                            let cellsThatAreReferencedKeyList_parents = cellsThatAreReferenced[cellRefKey];
                                                            if (cellsThatAreReferencedKeyList_parents) {
                                                                for (let k = 0; k < cellsThatAreReferencedKeyList_parents.length; k++) {
                                                                    let searchRefKey = cellsThatAreReferencedKeyList_parents[k];

                                                                    // let searchRefs = context.state.pgridSettings.cellsThatAreReferenced[searchRefKey];

                                                                    // for (let i = 0; i < searchRefs.length; i++) {
                                                                    //     if (searchRefs[i] == cellRefKey) {
                                                                    let [refYStr, refXStr] = searchRefKey.split(":");
                                                                    let [refY, refX] = [Number(refYStr), Number(refXStr)];
                                                                    let refValue = PGridCell.getHotValue2(pgridData[refY][refX]);

                                                                    //Recursive call
                                                                    RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, hotCellsToUpdate, refY, refX, refValue, depth + 1);

                                                                    // }
                                                                    // }
                                                                }
                                                            }
                                                        }



                                                        let old_cell_format = "Format" in (old_cell || {}) ? old_cell.Format : null;
                                                        let merged_cell_format = "Format" in (merged_cell || {}) ? merged_cell.Format : null;




                                                        //Looking for changes that required rendering of ht cell
                                                        if (old_cell_format != merged_cell_format) {
                                                            targetCell_FormatOfSaveHasChanged = true;
                                                        } else {

                                                            let old_cell_readonly = getNested(old_cell, "Meta", "Save", "ReadOnly") || false;
                                                            let merged_cell_readonly = getNested(merged_cell, "Meta", "Save", "ReadOnly") || false;

                                                            if (old_cell_readonly != merged_cell_readonly) {

                                                                targetCell_FormatOfSaveHasChanged = true;
                                                            } else {

                                                                let old_cell_writable = getNested(old_cell, "Meta", "Save", "Writable");
                                                                let merged_cell_writable = getNested(merged_cell, "Meta", "Save", "Writable");

                                                                if (old_cell_writable != merged_cell_writable) {
                                                                    targetCell_FormatOfSaveHasChanged = true;
                                                                }
                                                            }
                                                        }


                                                        //Looking for changes in Meta.Format
                                                        if (JSON.stringify(lodash_get(old_cell, "Meta.Format", null)) != JSON.stringify(lodash_get(merged_cell_format, "Meta.Format", null))) {
                                                            targetCell_FormatOfSaveHasChanged = true;
                                                        }


                                                        let oldValFormula = PGridCell.getFormula(old_cell);
                                                        let newValFormula = PGridCell.getFormula(merged_cell);
                                                        // let oldAndNewDiffsInHasFormula = oldValHasFormula != newValHasFormula;
                                                        let oldValVal = PGridCell.getHotValue2(old_cell);
                                                        let newValVal = PGridCell.getHotValue2(merged_cell);


                                                        if (PGridCell.isFormula(oldValFormula) || PGridCell.isFormula(newValFormula)) {


                                                            if (oldValFormula != newValFormula /* hotCellVal_old !== hotCellVal_examineNew */) {

                                                                ret.formulaWasUpdated = true;

                                                                delete merged_cell.Val;
                                                                delete merged_cell.ValStr;

                                                                // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValFormula]);
                                                                RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                            }
                                                        } else {
                                                            if (targetCell_FormatOfSaveHasChanged) {

                                                                // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValVal]);
                                                                RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                            } else if (oldValVal != newValVal) {

                                                                // ret.hotCellsToUpdate.push([targetCell.y, targetCell.x, newValVal]);
                                                                RecurseAddRefCells(context.state.pgridSettings.cellsThatAreReferenced, ret.hotCellsToUpdate, targetCell.y, targetCell.x, newValVal);
                                                            }
                                                        }


                                                    }

                                                }
                                            }
                                        }

                                        if (PGridRowProps) {

                                            if (!lr.lrHotSettings) {
                                                lr.lrHotSettings = {};
                                            }
                                            if (!("Rows" in lr.lrHotSettings)) {
                                                lr.lrHotSettings["Rows"] = {};
                                            }
                                            let tableRowCell = extractLabel(targetAddress); //Only supports single cell targets for now
                                            let tableRowIdx = tableRowCell[0].index + 1;


                                            let Row_PGridRowProps = {};
                                            Row_PGridRowProps[tableRowIdx] = PGridRowProps;

                                            lodash_merge(lr.lrHotSettings.Rows, Row_PGridRowProps);
                                        }
                                    }

                                    if (lr.lrHotSettings) {
                                        context.commit('Mutation_UpdatePGridSettings', { prop: "LRPGridSettingsCollection", val: lr.lrHotSettings, op: 'merge', source: `InsertOverlay()` });
                                    }

                                } catch (err2) {
                                    throw new Error(`InsertOverlay() CellTemplate apply got exception: ${err2.message} `);
                                }
                            }
                        }
                    }
                }
            } /*  End CustomRepeatReplace loop */
        } catch (err) {

            if (window.PGridClientDebugMode >= 3) {
                let errMsg = `InsertOverlay()(${lr.name}) got exception for Overlay item('${Description}' oIdx: ${oIdx}) ${PGridUtils.truncString(err.message, 10000)} `;
                console.warn(errMsg, itm);
            } else {
                if (err.message.indexOf("InsertOverlay() got exception: Could not find targetAddres") != -1 || err.message.indexOf("was not found in any cells in LR:s") != -1) {
                    //Mute this error
                }
                else {
                    let errMsg = `InsertOverlay()(${lr.name}) got exception for Overlay item('${Description}' oIdx: ${oIdx}) ${PGridUtils.truncString(err.message, 150)} `;
                    console.warn(errMsg);
                }
            }
        }
    } /* End for items loop */

    ret.pgridDataInserted = pgridData;

    return ret;

}




export function AddEmptyRow(pgridData, rows, onRow = -1) {
    for (let i = 0; i < rows; i++) {
        let newRow = new Array()
        for (let c = 0; c < pgridData[0].length; c++) {
            newRow.push(undefined);
        }
        if (onRow == -1) {
            pgridData.push(newRow);
        } else {
            pgridData.splice(onRow, 0, newRow);
        }
    }
}

export function AddEmptyRow_OTPGrid(pgridData, rows, onRow = -1) {

    let pgridDataCols = pgridData[0].length
    let currentRowAboveFormats = null;
    let currentRowNotAboveFormats = null;

    EnsureDimension(pgridData, onRow + rows, pgridDataCols)

    for (let d = 0; d < pgridDataCols; d++) {

        let format = lodash_get(pgridData[onRow][d], "Format", null);
        let formatAbove = null;
        if (onRow > 0) {
            formatAbove = lodash_get(pgridData[onRow - 1][d], "Format", null);
        }

        if (format) {
            let formatClasses = format.split(',');
            let formatClassesAbove = null;

            if (formatAbove) {
                formatClassesAbove = formatAbove.split(',');
            }

            // let formatClassesRemaining = formatClasses;
            for (let i = 0; i < formatClasses.length; i++) {
                if (RegExp('lr-just-above', 'i').test(formatClasses[i])) {
                    if (currentRowAboveFormats == null) {
                        currentRowAboveFormats = new Array(pgridData[0].length);
                    }
                    currentRowAboveFormats[d] = [];
                    currentRowAboveFormats[d].push(formatClasses[i]);
                }

                if (formatClassesAbove) {
                    if (RegExp('lr-just-above', 'i').test(formatClassesAbove[i])) {
                        let countBefore = formatClassesAbove.length;
                        formatClassesAbove = formatClassesAbove.filter(x => x != formatClassesAbove[i]);
                        let countAfter = formatClassesAbove.length;

                        // if (countBefore != countAfter) {
                        //     console.log(`countBefore != countAfter ${countBefore} =! ${countAfter}`)
                        // }
                    }
                }

            }

            if (currentRowNotAboveFormats == null) {
                currentRowNotAboveFormats = new Array(pgridData[0].length);
            }
            currentRowNotAboveFormats[d] = formatClassesAbove;


            if (currentRowNotAboveFormats) {
                if (currentRowNotAboveFormats[d]) {
                    if (onRow > 0) {
                        let newFormat = currentRowNotAboveFormats[d].join(',');
                        if (newFormat.length > 0) {
                            pgridData[onRow - 1][d].Format = newFormat
                        } else {
                            delete pgridData[onRow - 1][d].Format;
                            if (JSON.stringify(pgridData[onRow - 1][d]) === '{}') {
                                pgridData[onRow - 1][d] = undefined;
                            }
                        }
                    }
                }
            }
        }
    }



    for (let r = 0; r < rows; r++) {

        // if (r == 0) {
        //     if (currentRowAboveFormats) {
        //         console.log('found currentRowAboveFormats', currentRowAboveFormats);
        //     }
        //     if (currentRowNotAboveFormats) {
        //         console.log('found currentRowNotAboveFormats', currentRowNotAboveFormats);
        //     }
        // }

        let newRow = new Array()
        for (let c = 0; c < pgridData[0].length; c++) {

            let newCell = undefined

            if (r == 0) {

                if (currentRowAboveFormats) {
                    if (currentRowAboveFormats[c]) {
                        newCell = { Format: currentRowAboveFormats[c].join(',') };
                    }
                }

                if (currentRowNotAboveFormats) {
                    if (currentRowNotAboveFormats[c]) {
                        pgridData[onRow].Format = currentRowNotAboveFormats[c].join(',');
                    }
                }
            }



            newRow.push(newCell);
        }
        if (onRow == -1) {
            pgridData.push(newRow);
        } else {
            pgridData.splice(onRow, 0, newRow);
        }


    }
}

export function AddEmptyRow_HT(pgridData, rows, onRow = -1) {

    let pgridDataCols = pgridData[0].length
    // EnsureDimension(pgridData, onRow + rows, pgridDataCols)

    for (let i = 0; i < rows; i++) {
        let newRow = new Array()
        for (let c = 0; c < pgridData[0].length; c++) {
            newRow.push(undefined);
        }
        if (onRow == -1) {
            pgridData.push(newRow);
        } else {
            pgridData.splice(onRow, 0, newRow);
        }
    }
}

export function AddEmptyCol(pgridData, cols = 1, onCol = -1 /* -1 == on last col */) {

    let pgridData_length = pgridData.length;
    for (let r = 0; r < pgridData_length; r++) {

        for (let n = 0; n < cols; n++) { //FUB: not fast, maybe use: spead and [].concat(...Array(num).fill(undefined)) insted?
            if (onCol == -1) {
                pgridData[r].push(undefined);
            }
            else {
                pgridData[r].splice(onCol, 0, undefined); //not tested
            }
        }
    }

}

export function EnsureDimension(pgridData, rows, cols) {

    let pgridData_row_length = pgridData.length;
    let pgridData_col_length = 0;
    if (pgridData_row_length > 0) {
        pgridData_col_length = pgridData[0].length;
    }

    if (pgridData_col_length < cols) {
        AddEmptyCol(pgridData, pgridData_col_length, pgridData_col_length)
    }
    if (pgridData_row_length < rows) {
        AddEmptyRow(pgridData, pgridData_row_length, pgridData_row_length)
    }
}


export function IsOutsideLowestRight(row, column) {

    let ret = false;

    if (PGridVueStore.state.LowestRightOptimization == false) {
        return false;
    }

    if (PGridVueStore.state.LowestRightNonHiddenLR !== null) {

        ret = (
            (
                PGridVueStore.state.LowestRightNonHiddenLR.y != -1 && row > PGridVueStore.state.LowestRightNonHiddenLR.y
                ||
                PGridVueStore.state.LowestRightNonHiddenLR.x != -1 && column > PGridVueStore.state.LowestRightNonHiddenLR.x
            )
            &&
            (
                PGridVueStore.state.LowestRightNonHiddenCell.y != -1 && row > PGridVueStore.state.LowestRightNonHiddenCell.y
                ||
                PGridVueStore.state.LowestRightNonHiddenCell.x != -1 && column > PGridVueStore.state.LowestRightNonHiddenCell.x
            )
        )
    }

    return ret
}



export default {
    Load_Phase2to4,
    Get_LRFromTableCoordinates,
    // Get_LRsFromTableCached,
    Get_LRsFromTable,
    Convert_pgridTableDynToPGridFactRows,
    SavePgridFactToDatabase,
    NewPGridLRTypeObj,
    ApplyCssStyles,
    // FindLRCoordinates,
    FillDimPropsDown,
    AddEmptyRow,
    AddEmptyRow_OTPGrid,
    AddEmptyRow_HT,
    AddEmptyCol,
    EnsureDimension,
    UpdateCellReferences,
    IsOutsideLowestRight,
    InsertOverlay
}