import { mergeCells, getHotValue } from './pgridCell.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy, forEach } from 'lodash';
import PGridUtils from './pgridUtils.js';
import PGridMatrices from './pgridMatrices.js'
import PGridCell from './pgridCell.js';
import { extractLabel } from '../formula-parser/helper/cell.js';


// Basic Interface to warn, whenever an not overridden method is used
export class PGridLR_Base {

    constructor(x, y, pcell, pgridTableStatic) {
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //when only set schema in child class
            return;
        }
        this.linkedRange = JSON.parse(pcell.RefType);
        this.name = this.linkedRange.LinkedRangeName;
        this.type = this.linkedRange.Type;
        this.x = x;
        this.y = y;
        this.yLength = lodash_get(pcell, "Meta.Load.DimSizeY", null);
        this.yLengthHeaders = 1;
        this.xLength = lodash_get(pcell, "Meta.Load.DimSizeX", null);
        this.ySafeOffsetDistance = lodash_get(pcell, "Meta.Load.SafeOffsetDistanceY", null);
        this.xSafeOffsetDistance = lodash_get(pcell, "Meta.Load.SafeOffsetDistanceX", null);


        // this.filterKeyConstrains = lodash_get(this.linkedRange, "FilterKeyConstrains", null);  //To allow multiple LR with same dimenstion to co-exist witout collitions 
        this.lrIsReadOnly = lodash_get(this.linkedRange, "ReadOnly", false);
        this.lrIsHidden = lodash_get(this.linkedRange, "Hidden", false);
        // this.requireAssociatedLRMatch = lodash_get(this.linkedRange, "RequireAssociatedLRMatch", false);
        this.overrideGridKey = PGridUtils.trimAndUpperCaseIfNotNull(lodash_get(this.linkedRange, "OverrideGridKey", null));
        this.overrideTabKey = PGridUtils.trimAndUpperCaseIfNotNull(lodash_get(this.linkedRange, "OverrideTabKey", null));
        this.overrideRemoveOrTranslateCellKeyDims = lodash_get(this.linkedRange, "OverrideRemoveOrTranslateCellKeyDims", null);
        this.lrHotSettings = lodash_get(this.linkedRange, "LRHotSettings", null);
        this.lrPGridSettings = lodash_get(this.linkedRange, "LRPGridSettings", null);

        if (this.lrPGridSettings && "ColumnsRelative" in this.lrPGridSettings) {

            let lrPGridSettingsCol = this.lrPGridSettings.ColumnsRelative;

            let lrPGridSettingsCol_Keys = Object.keys(lrPGridSettingsCol);

            for (let i = 0; i < lrPGridSettingsCol_Keys.length; i++) {

                let tableColIdx = -1;
                let colIdx_Key = lrPGridSettingsCol_Keys[i];

                if (PGridUtils.IsNumeric(colIdx_Key)) {
                    tableColIdx = Number(colIdx_Key) + this.x; // Add this.x to make it relative to LR base
                    if (tableColIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() Number(): Illegal column key: ${colIdx_Key}`)
                    }
                } else {
                    tableColIdx = columnLabelToIndex(colIdx_Key) + this.x; // Add this.x to make it relative to LR base
                    if (tableColIdx == -1) {
                        throw new Error(`FakeAction_ApplyLRPgrid_OnFirstCell() columnLabelToIndex(): Illegal column key: ${colIdx_Key}`)
                    }
                }


                if (!("Columns" in this.lrPGridSettings)) {
                    this.lrPGridSettings.Columns = {};
                }

                lodash_set(this.lrPGridSettings, `Columns[${tableColIdx}]`, lrPGridSettingsCol[lrPGridSettingsCol_Keys[i]]);

                // "LRPGridSettings": {
                //     "ColumnsRelative": {
                //         "1": {
                //             "MinWidth": 30
                //         }
                //     }
                // },
            }



        }



        this.replaceNodeProps = lodash_get(this.linkedRange, "ReplaceNodeProps", null);
        this.overlayCSSClass = lodash_get(this.linkedRange, "OverlayCSSClass", null);

        this.adjacentCellsRecalc = function () {
            let cellAbove = lodash_get(pgridTableStatic, `[${this.y - 1}][${this.x}]`, null);
            let cellLeft = lodash_get(pgridTableStatic, `[${this.y}][${x - 1}]`, null);

            this.adjacentCells = {
                selfCell: pgridTableStatic[this.y][this.x],
                aboveCell: (this.y > 0 && cellAbove != null) ? JSON.parse(JSON.stringify(cellAbove)) : null,
                leftCell: (this.x > 0 && cellLeft != null) ? JSON.parse(JSON.stringify(cellLeft)) : null
            }
        }
        this.adjacentCellsRecalc();

        this.axisData = {} //Set in Phase2_Load_DimDataInAxisData
        this.axisMeta = {} //Set in Phase2_Load_DimDataInAxisData
        // this.RowsOffset = 0; //Set in Phase3, used in Phase 3 and 4
        this.errors = Array()
    }

    whoAmI() {
        return 'I am a PGridLR_Base'
    }

    //contect: Server (resolver_common.js)
    //inputs: linked range definition (this.linkedRange<axis>)
    //returns: ["DataSetNameA","DataSetNameB",...]
    Phase1_Get_DataSetDefs({ }) {
        let fName = 'Phase1_Get_DataSetDefs'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    //contect: Server (resolver_common.js)
    //inputs: pgridDim 
    //sets: this.axisData ()
    Phase2_Load_DimDataInAxisData(pgridDim) {

        try {
            const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

            for (let j = 0; j < axisToLoad.length; j++) {

                let axisTypeInDef = axisToLoad[j].value;

                if (!(axisTypeInDef.mapsTo in this.linkedRange)) {
                    throw `Missing dimension: ${axisTypeInDef.mapsTo} in linked range: ${this.name}`;
                }

                this.axisData[axisToLoad[j].key] = Array();

                let lrDimDef = this.linkedRange[axisTypeInDef.mapsTo];

                for (let k = 0; k < lrDimDef.length; k++) {

                    let lrDs = lrDimDef[k];

                    let dsName = lrDs["DataSet"];

                    if (!dsName || !(dsName in pgridDim)) {
                        throw `No dataset "${dsName}" in LinkeReange`;
                    } else {
                        let pgridDimDSCopy = JSON.parse(JSON.stringify(pgridDim[dsName]));
                        if (pgridDimDSCopy === undefined) {
                            throw `Could not find dataset (${dsName}) in response from server`;
                        } else {

                            this.axisData[axisToLoad[j].key].push({
                                Name: dsName,
                                Header: pgridDimDSCopy.shift(),
                                DataSet: pgridDimDSCopy

                            });
                        }
                    }
                }

            }
        } catch (err) {

            let errMsg = `> Phase2_Load_DimDataInAxisData: ${this.name}  got exception: ${err.message || err}`;
            this.errors.push(errMsg);
        }
        return null;
    }

    Phase3_0_Pre_Insert_Overlay(pgridData, /*hotTable,*/ context, currentEventType = "preInsertEvent") {

        let ret = PGridMatrices.InsertOverlay(
            this
            , pgridData
            , null
            , context
            , currentEventType
        );

        return ret;
    }


    Phase3_Insert_DynDimData = async function (pgridDataDyn, pgridAxis, addedRowsOffset, state /* ROWS, COLS */) {
        let fName = 'Phase3_Insert_DynDimData'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    Phase3_1_Update_HOTSettings(commit, source = "unknown") {
        false && console.debug(`Phase3_1_Update_HOTSettings() source :${source}`);

        if (this.lrHotSettings) {
            if ('fixedRowsTopRelative' in this.lrHotSettings) {
                this.lrHotSettings.fixedRowsTop = this.y + this.lrHotSettings.fixedRowsTopRelative;
            }

            commit('Mutation_UpdateHOTSettings', { prop: 'LRHotSettings', val: this.lrHotSettings, op: 'push', source: `pgridLR_Base Phase3_1_Update_HOTSettings` });
        }
        if (this.lrPGridSettings) {
            commit('Mutation_UpdatePGridSettings', { prop: 'LRPGridSettingsCollection', val: this.lrPGridSettings, op: 'merge', source: `pgridLR_Base Phase3_1_Update_PGridSettings` });
            // for (const [k, v] of Object.entries(this.lrPGridSettings)) {
            // commit('Mutation_UpdatePGridSettings', { prop: k, val: v, op: 'merge', source: `pgridLR_Base Phase3_1_Update_PGridSettings` });
            // };
        }
    }


    Phase4_Insert_Metadata(pgridDataDyn, pgridAxis /* FACTS */, linkedRange = null) {
        let fName = 'Phase4_Insert_Metadata'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    Phase4_1_GetSpecializedSQLQuery(linkedRange = null) {
        let fName = 'GetSpecializedSQLQuery'
        window.PGridClientDebugMode >= 2 && console.debug('NORMAL! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }

    Phase5_DoFetchData() {
        return true;
    }

    Phase6_Generate_FactRange(pgridDataDyn, factData, lang = null) {
        let fName = 'Phase6_Generate_FactRange'
        console.warn('WARNING! Function "' + fName + '" is not overridden in ' + this.constructor.name);
    }


    Phase7_Insert_FactRange(pgridDataDyn, hotTable, factRangeData) {

        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_Base.js Phase7_Insert_FactRange() name: ${this.name}` }).y;

        let ret = { pgridDataInserted: null, hotTableInserted: null, insertedRows: 0 };

        let hotTableInserted = null;
        let pgridDataInserted = null;
        try {

            pgridDataInserted = lodash_clone(pgridDataDyn); //FUB is clone nessecary?
            hotTableInserted = hotTable //lodash_clone(hotTable);

            if (factRangeData.length > 0
                && this.yLength !== factRangeData.length
                && this.xLength !== factRangeData[0].length) {
                throw `Invalid factRangeData dimension`;
            }


            for (let y = 0; y < this.yLength/*pgridDataLRCell.Meta.Load.DimSizeY*/; y++) {

                for (let x = 0; x < this.xLength /* pgridDataLRCell.Meta.Load.DimSizeX */; x++) {

                    const insertY = this.y + y;
                    const insertX = this.x + x;

                    let insertFact = factRangeData[y][x];

                    if (insertFact != null) {

                        let currCell = pgridDataInserted[insertY][insertX];
                        let mergedCell = mergeCells(currCell, insertFact, ["KeepValStr", "KeepVal"]);

                        pgridDataInserted[insertY][insertX] = mergedCell;

                        if (PGridMatrices.IsOutsideLowestRight(insertY, insertX)) {
                            //Skipp this
                        } else {
                            let hotValue = getHotValue(mergedCell);
                            hotTableInserted[insertY][insertX] = hotValue;
                        }
                    }
                }
            }

        } catch (err) {
            let errMsg = `> Phase7_Insert_FactRange_error got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }

        ret.pgridDataInserted = pgridDataInserted;
        ret.hotTableInserted = hotTableInserted;

        return ret;
    }


    Phase8_Insert_Overlay(pgridData, hotTable, context, currentEventType = "initEvent") {
        let ret = PGridMatrices.InsertOverlay(this, pgridData, hotTable, context, currentEventType);
        return ret;
    }

    Phase11_Collect_FactRange(pgridDataDyn, pgridDim) {
        return null;
    }
}

