import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash'

let langIdx = {
    "en": 0,
    "sv": 1
}

let localeIdx = {
    "en-US": 0,
    "sv-SE": 1
}

let translationLibrary = {
    "cellTypeYesNo_Yes": ["Yes", "Ja"],
    "cellTypeYesNo_No": ["No", "Nej"],
    "cellTypeTrueFalse_True": ["True", "Sant"],
    "cellTypeTrueFalse_False": ["False", "Falskt"],
    "cellTypeCheckbox_Checked": ["Checked", "Ibockad"],
    "cellTypeCheckbox_UnChecked": ["Unchecked", "Obockad"],
    "saveFactError": ["Saving fact data to server faild", "Sparning av data mot servern misslyckades"],
    "loadFactError": ["Loading fact data from server faild", "Laddning av data från servern misslyckades"],
    "nonSavedChanges_Warning": [`There are non saved changes.\n\nAre you sure you whant to leave without saving`, `Det finns ej sparade ändringar i mallen.\n\nÄr du säker på att du vill lämmna?`],
    "validationError": ["Error found in table. Save has been canceled", "Fel upptäckta i tabellen. Sparning avbruten"],
    "saveButton": ["SAVE", "SPARA"],
    "reloadButton": ["RELOAD", "LADDA OM"],
    "publishButton": ["PUBLISH", "PUBLISERA"],
    "deleteButton": ["DELET", "TA BORT"]
}

let localeLibrary = {
    "decimalSeparator": [".", ","],
    "thousandSeparator": [",", " "],
}



export function localeFromKey(key, lang) {

    let ret = null;
    ret = lodash_get(localeLibrary, `[${key}][${localeIdx[lang]}]`, `<${key}>`);
    return ret;
}


export function translateFromKey(key, lang = null) {

    let ret = null;

    if (lang == null) {
        if (window.PGridVueStore) {
            lang = PGridVueStore.state.pgridSettings.language
        }
    }
    if (lang == null) {
        throw Error('language could not be resolved');
    }

    ret = lodash_get(translationLibrary, `[${key}][${langIdx[lang]}]`, `${key}`);
    return ret;
}

export default {
    translateFromKey,
    localeFromKey
}